/* ------------------------------------------------------------ *\
	Shopify Challenge
\* ------------------------------------------------------------ */

.shopify-challenge__container {
	max-width: 100% !important;
	padding: 190px 0 100px;
	background-color: $orange;
	color: $white;

	> * {
		max-width: 500px;
		margin: 0 auto;
	}

	.btn {
		border: 2px solid $white;
		background: $purple;
		min-width: 207px;
		padding: 12px 20px 6px;
		@include font-fix();
		font-weight: 900;
		font-size: 17px;
		letter-spacing: .08em;
		text-transform: uppercase;
		transition: background .4s;

		@media #{$mobile-small}{
			min-width: 0;
		}

		&:hover {
			border-color: $white;
			background-color: $purple2;
			color: $white;
		}
	}
}
