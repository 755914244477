/* ------------------------------------------------------------ *\
	Products
\* ------------------------------------------------------------ */

.products {
	.products__items {
		display: flex;
		flex-flow: row wrap;
		margin: 0 -10px -20px;

		@media #{$tablet-portrait} {
			display: block;
			margin: 0;
		}
	}

	.products__item {
		flex: 0 0 25%;
		max-width: 25%;
		padding: 0 10px;
		margin-bottom: 20px;

		@media #{$tablet-portrait} {
			max-width: 100%;
			padding: 0;
			margin-bottom: 0;
		}

		.product__item {
			height: 100%;
		}
	}

	.slick-list {
		margin: 0 -10px;
	}

	.slick-slide {
		padding: 0 10px;
	}
}
