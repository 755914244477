/* ------------------------------------------------------------ *\
  Animate CSS
\* ------------------------------------------------------------ */

.animate__animated {
  -webkit-animation-fill-mode: backwards !important;
  animation-fill-mode: backwards !important;
}

.js-animation-fade-in {
	opacity: 0;
	transition: opacity .4s;

	&.animated {
		opacity: 1;
	}
}
