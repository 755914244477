/* ------------------------------------------------------------ *\
	Stamped Container
\* ------------------------------------------------------------ */

#stamped-main-widget {
	color: $white;

	@media #{$mobile} {
		margin: 0;
	}

	.stamped-summary-actions {
		display: none !important;
	}

	.stamped-summary {
		display: flex;
		align-items: flex-end;
		margin: 0 -10px;

		@media #{$mobile} {
			display: block;
			margin: 0;
			padding: 0 18px;
		}

		@media #{$mobile-small} {
			padding: 0;
		}
	}

	.summary-overview {
		float: none !important;
		flex: 1 0;
		width: 100% !important;
		padding: 0 10px;
		margin: 0;

		@media #{$mobile} {
			padding: 0;
			margin: 0 0 35px !important;
			text-align: left;
		}
	}

	.stamped-summary-ratings {
		flex: 0 0 61.5%;
		max-width: 61.5%;
		width: 100% !important;
		padding: 0 10px;
		margin: 0 !important;
		border: 0;

		@media #{$mobile} {
			max-width: 100%;
			padding: 0;
		}
	}

	.stamped-tab-container {
		display: none !important;
	}

	.stamped-summary-caption {
		.stamped-summary-text-1 {
			font-family: $font-coco-gothic-fat;
			font-weight: 900 !important;
			font-size: 64px !important;
			color: $white;

			@media #{$mobile} {
				margin: 0 17px 0 7px !important;
			}
		}

		&.stamped-summary-caption-2 {
			@media #{$mobile} {
				display: block !important;
			}
		}
	}

	.stamped-summary-starrating i {
		color: $white !important;

		&:before {
			font-size: 35px !important;
		}
	}

	.stamped-summary-text {
		padding: 5px 5px 0;
		font-family: $font-gotham;
		font-weight: 300;
		font-size: 22px;
		color: $white;

		@media #{$mobile} {
			display: block;
			text-align: left;
			padding-top: 6px;
		}
	}

	.summary-rating {
		display: flex;
		align-items: center;
		margin: 0 -11px 9px;

		@media #{$mobile} {
			margin: 0 -7px 7px;
		}
	}

	.summary-rating-title {
		flex: 0 0 127px;
		max-width: 127px;
		width: 127px !important;
		padding: 0 11px;

		@media #{$mobile} {
			flex: 0 0 108px;
			max-width: 108px;
			width: 108px !important;
			padding: 0 7px;
			text-align: left;
		}

		&:before {
			font-size: 22px !important;
			color: $white;

			@media #{$mobile} {
				display: inline-block;
				margin: -4px 0 0 8px;
				font-size: 15px !important;
				letter-spacing: .1em;
			}
		}
	}

	.summary-rating-bar {
		flex: 1 0;
		margin: 0 11px;
		width: 100% !important;
		height: 21px !important;
		border: 1px solid $white;

		@media #{$mobile} {
			height: 15px !important;
			margin: 0 7px;
		}

		.summary-rating-bar-content {
			height: 100%;
			background-color: $orange2;
		}
	}

	.summary-rating-count {
		width: auto !important;
		min-width: 81px;
		padding: 0 11px;
		font-family: $font-gotham;
		font-weight: 300;
		font-size: 21px;
		color: $white !important;
		text-align: left !important;

		@media #{$mobile} {
			min-width: 62px;
			padding: 0 7px;
			font-size: 16px;
		}
	}

	.stamped-header {
		margin-bottom: 47px !important;

		@media #{$mobile} {
			margin-bottom: 40px !important;
		}
	}

	.stamped-reviews-filter {
		display: flex;
		align-items: center;
		flex-flow: row wrap;
		padding-bottom: 29px;
		border-bottom: 0;
	}

	.stamped-reviews-filter-label {
		display: none;
	}

	.stamped-reviews-search-text {
		flex: 0 0 67%;
		max-width: 67%;
		margin: 0;
		order: -5;

		@media #{$mobile} {
			flex: 0 0 59%;
			max-width: 59%;
		}
	}

	.stamped-reviews-search-clear {
		color: $white;
	}

	.stamped-summary-actions-clear {
		flex: 0 0 100%;
		max-width: 100%;
		order: 5;
		border: 0;
		color: $white;
	}

	.stamped-sort-select-wrapper {
		flex: 1 0;
	}

	.stamped-sort-select {
		width: 100%;
		height: 57px;
		padding: 6px 60px 1px 22px !important;
		margin: 0;
		border-radius: 0 !important;
		border: solid $white !important;
		border-width: 3px 4px 3px 2px !important;
		background-color: $orange;
		background-image: url(https://cdn.shopify.com/s/files/1/0111/7532/9850/t/26/assets/ico-arrow-down.svg?v=232818860571541522) !important;
		background-size: 29px 29px !important;
		background-position: right 19px center;
		@include font-fix();
		font-weight: 900;
		font-size: 20px;
		color: $white;
		text-transform: uppercase;
		appearance: none;

		@media #{$mobile} {
			height: 43px;
			padding: 6px 49px 1px 11px !important;
			font-size: 14px;
			background-size: 23px 23px !important;
		}
	}

	.stamped-reviews-search-input {
		width: 100%;
		height: 57px;
		padding: 5px 51px 0 !important;
		border-radius: 0 !important;
		border: solid $white !important;
		border-width: 3px 2px 3px 3px !important;
		background-color: transparent;
		@include font-fix();
		font-weight: 900;
		font-size: 20px;
		color: $white;
		text-transform: uppercase;

		@media #{$mobile} {
			height: 43px;
			padding: 5px 10px 0 47px !important;
			font-size: 15px;
		}

		@media #{$mobile-small} {
			padding: 5px 5px 0 30px !important;
		}
	}

	.stamped-reviews-search-icon {
		top: 50%;
		left: 13px;
		font-size: 36px;
		color: $white;
		transform: translateY(-50%);

		@media #{$mobile} {
			left: 24px;
			font-size: 27px;
		}

		@media #{$mobile-small} {
			left: 7px;
		}
	}

	.stamped-review {
		padding-top: 30px;
		border-top: 0;
	}

	.stamped-review-header {
		position: relative;
		padding: 0 10px;
		margin-bottom: 24px;

		@media #{$mobile} {
			margin-bottom: 14px;
		}

		@media #{$mobile-small} {
			margin-bottom: 30px;
		}
	}

	.stamped-review-header .created {
		display: inline-block;
		margin: 4px -9px 0 0;
		font-family: $font-gotham;
		font-weight: 500;
		font-size: 16px;
		color: $blue2;

		@media #{$mobile} {
			margin: 4px 3px 0 0;
			font-size: 10px;
		}
	}

	.stamped-review-header .review-location {
		margin-top: 9px;
		font-family: $font-gotham;
		font-weight: 500;
		font-size: 20px;
		color: $white;

		@media #{$mobile} {
			margin-top: 4px;
			font-size: 16px;
		}
	}

	.stamped-review .author {
		display: inline-block;
		margin: 5px 7px 0 0;
		font-family: $font-gotham;
		font-weight: 500;
		font-size: 20px;
		color: $white;

		@media #{$mobile} {
			margin: 1px 7px 0 0;
			font-size: 16px;
		}
	}

	.stamped-verified-badge {
		margin-left: 5px;
		font-family: $font-gotham;
		font-weight: 500;
		font-size: 20px;
		color: $blue2;

		@media #{$mobile} {
			margin-left: 2px;
			font-size: 16px;
		}

		@media #{$mobile-small} {
			display: block;
		}
	}

	.stamped-review-avatar {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 65px;
		height: 65px;
		border-radius: 50%;
		background-color: $blue2;

		@media #{$mobile} {
			width: 52px;
			height: 52px;
		}

		&:before {
			content: '';
			z-index: 5;
			display: inline-block;
			width: 27px;
			height: 21px;
			background-image: url(https://cdn.shopify.com/s/files/1/0111/7532/9850/files/ico-check_2x_1db2bbec-6f65-4dec-bb7e-a7d00d5eaa20.png?v=1595338370);
			background-size: cover;
			background-position: center;

			@media #{$mobile} {
				width: 21px;
				height: 16px;
			}
		}
	}

	.stamped-review-avatar img {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		max-width: 100%;
		height: auto;
		border-radius: 50%;
	}

	.stamped-review-avatar-content {
		display: inline-block;
		width: auto;
		height: auto;
		margin: 0;
		font-family: $font-cocoGothicSmallCaps;
		font-weight: 700;
		font-style: normal;
		font-size: 32px;
		color: $white;

		@media #{$mobile} {
			font-size: 26px;
		}
	}

	.stamped-review-header-starratings {
		position: absolute;
		top: 100%;
		left: 0;
		margin: 33px 0 0 3px;

		@media #{$mobile} {
			margin: 22px 0 0 3px;
		}

		@media #{$mobile-small} {
			margin: 10px 0 0 3px;
		}
	}

	.stamped-review-header-starratings .stamped-fa {
		margin-left: 4px;
		color: $white;

		&:before {
			font-size: 21px;

			@media #{$mobile} {
				font-size: 16px;
			}
		}
	}

	.stamped-review-header-title {
		padding: 0 0 0 137px;
		margin-bottom: 15px;
		font-family: $font-coco-gothic-fat;
		font-weight: 900;
		font-size: 27px;
		color: $white;
		text-transform: uppercase;

		@media #{$mobile} {
			padding: 0 0 0 105px;
			margin-bottom: 10px;
			font-size: 21px;
		}

		@media #{$mobile-small} {
			padding: 0;
		}
	}

	.stamped-review-content {
		padding-left: 5px;
	}

	.stamped-review-content-body {
		font-family: $font-gotham;
		font-weight: 300;
		font-size: 20px;
		line-height: 1.4;
		color: $white;

		@media #{$mobile} {
			font-size: 15px;
			line-height: 1.55;
		}
	}

	.stamped-review-product {
		display: none !important;
	}

	.stamped-review-variant {
		display: none !important;
	}

	.stamped-review-share {
		font-family: $font-gotham;
		font-weight: 500;
		font-size: 16px;
		color: $blue2;

		@media #{$mobile} {
			margin-right: 16px;
			font-size: 13px;
		}

		@media #{$mobile-small} {
			float: none !important;
		}
	}

	.stamped-share-icon {
		position: relative;
		padding-left: 23px;

		@media #{$mobile} {
			padding-left: 17px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			display: inline-block;
			width: 16px;
			height: 13px;
			background-image: url(../images/ico-share@2x.png);
			background-position: center;
			background-size: cover;
			transform: translateY(-50%);

			@media #{$mobile} {
				width: 12px;
				height: 10px;
			}
		}
	}

	.stamped-share-icon i {
		display: none;
	}

	.stamped-review-vote {
		font-family: $font-gotham;
		font-weight: 500;
		font-size: 16px;
		color: $blue2;

		@media #{$mobile} {
			float: none !important;
			font-size: 13px;
		}
	}

	.stamped-review-vote .stamped-rating-holder {
		display: flex;
		align-items: center;
	}

	.stamped-review-vote .stamped-thumbs-up {
		display: inline-block;
		width: 18px;
		height: 20px;
		margin: 0 0 4px 35px;
		background-image: url(../images/ico-thumbs-up@2x.png);
		background-position: center;
		background-size: cover;

		@media #{$mobile} {
			width: 14px;
			height: 15px;
			margin: 0 0 4px 26px;
		}
	}

	.stamped-review-vote .stamped-thumbs-up i {
		display: none;
	}

	.stamped-review-vote .stamped-thumbs-down {
		display: inline-block;
		width: 18px;
		height: 20px;
		margin: 0 0 4px 3px;
		background-image: url(../images/ico-thumbs-down@2x.png);
		background-position: center;
		background-size: cover;

		@media #{$mobile} {
			width: 14px;
			height: 15px;
			margin: 0 0 4px 2px;
		}
	}

	.stamped-review-vote .stamped-thumbs-down i {
		display: none;
	}

	.stamped-review-footer {
		padding-left: 8px;
		margin-top: 13px;

		@media #{$mobile} {
			padding-left: 4px;
			margin-top: 18px;
		}
	}

	.stamped-pagination {
		margin-top: 30px;
		color: $white;
	}
}
