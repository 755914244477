/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
  z-index: 1; 
  position: relative; 
	display: inline-block;
	max-width: 133px; 
	font-size: 0;

	@media #{$mobile} {
		max-width: 105px;
	}

	img {
		margin-bottom: 0;
	}
}
