/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/GothamLight-Italic.woff2') format('woff2'),
        url('../fonts/GothamLight-Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/GothamBook.woff2') format('woff2'),
        url('../fonts/GothamBook.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/GothamBook-Italic.woff2') format('woff2'),
        url('../fonts/GothamBook-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/GothamLight.woff2') format('woff2'),
        url('../fonts/GothamLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/GothamMedium-Italic.woff2') format('woff2'),
        url('../fonts/GothamMedium-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/GothamMedium.woff2') format('woff2'),
        url('../fonts/GothamMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/GothamBold.woff2') format('woff2'),
        url('../fonts/GothamBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/GothamBold-Italic.woff2') format('woff2'),
        url('../fonts/GothamBold-Italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Black.woff2') format('woff2'),
        url('../fonts/Gotham-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Coco Gothic';
    src: url('../fonts/CocoGothic-LightItalic.woff2') format('woff2'),
        url('../fonts/CocoGothic-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Coco Gothic Fat';
    src: url('../fonts/CocoGothic-Fat.woff2') format('woff2'),
        url('../fonts/CocoGothic-Fat.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Coco Gothic Fat';
    src: url('../fonts/CocoGothic-FatItalic.woff2') format('woff2'),
        url('../fonts/CocoGothic-FatItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Coco Gothic';
    src: url('../fonts/CocoGothic-Heavy.woff2') format('woff2'),
        url('../fonts/CocoGothic-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Coco Gothic';
    src: url('../fonts/CocoGothic-Light.woff2') format('woff2'),
        url('../fonts/CocoGothic-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Coco Gothic';
    src: url('../fonts/CocoGothic.woff2') format('woff2'),
        url('../fonts/CocoGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Coco Gothic';
    src: url('../fonts/CocoGothic-Italic.woff2') format('woff2'),
        url('../fonts/CocoGothic-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Coco Gothic';
    src: url('../fonts/CocoGothic-BoldItalic.woff2') format('woff2'),
        url('../fonts/CocoGothic-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Coco Gothic';
    src: url('../fonts/CocoGothic-Bold.woff2') format('woff2'),
        url('../fonts/CocoGothic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CocoGothicSmallCaps';
    src: url('../fonts/CocoGothicSmallCaps-Bold.woff2') format('woff2'),
        url('../fonts/CocoGothicSmallCaps-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CocoGothicSmallCaps';
    src: url('../fonts/CocoGothicSmallCaps-BoldIta.woff2') format('woff2'),
        url('../fonts/CocoGothicSmallCaps-BoldIta.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
