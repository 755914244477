/* ------------------------------------------------------------ *\
	Store Locator
\* ------------------------------------------------------------ */

#store-locator-title {
	display: none;
}

.search_bar {
	position: relative;
	top: -152px;
	width: 33.6%;
	display: flex;
	padding: 0 !important;
	margin-left: 8.2%;
	border: 4px solid $white !important;
	background: transparent !important;

	@media #{$tablet-portrait} {
		flex: 0 0 calc(100% - 98px) !important;
		max-width: calc(100% - 98px) !important;
		width: calc(100% - 98px);
		margin: 0 49px;
	}

	@media #{$mobile-small} {
		flex: 0 0 calc(100% - 40px) !important;
		max-width: calc(100% - 40px) !important;
		width: calc(100% - 40px);
		margin: 0 20px;
	}
}

.main_search_bar {
	flex: 1 0;
	float: none !important;
	position: relative;
	width: 100% !important;
	margin: 0 !important;

	.main_search_label {
		position: absolute;
		top: 50%;
		left: 15px;
		display: inline-block;
		width: 25px;
		height: 25px;
		background-image: url(https://cdn.shopify.com/s/files/1/0111/7532/9850/files/search_2x_03d14102-ab21-44f8-8804-c284dbdf8bdd.png?v=1595926840);
		background-size: cover;
		background-position: center;
		font-size: 0;
		transform: translateY(-50%);

		@media #{$mobile} {
			left: 9px;
			width: 16px;
			height: 16px;
		}
	}

	input {
		width: 100% !important;
		height: 53px;
		margin: 0;
		padding: 6px 15px 0 52px;
		border: 0;
		background: transparent;
		@include font-fix();
		font-weight: 900;
		font-size: 20px;
		color: $white;
		text-transform: uppercase;

		@media #{$mobile} {
			height: 33px;
			padding: 6px 15px 0 36px;
			font-size: 14px;
		}
	}
}

.search_within_distance {
	display: none;
}

.search_limit {
	display: none;
}

#store_map {
	top: -223px;
	width: 41.3% !important;
	height: 643px !important;
	margin-right: 8.7%;
	border: 0 !important;

	@media #{$tablet-portrait} {
		order: 10;
		float: none !important;
		top: -85px;
		flex: 0 0 calc(100% - 98px) !important;
		max-width: calc(100% - 98px) !important;
		width: calc(100% - 98px);
		margin: 0 49px;
	}

	@media #{$mobile} {
		top: -158px;
	}

	@media #{$mobile-small} {
		flex: 0 0 calc(100% - 40px) !important;
		max-width: calc(100% - 40px) !important;
		width: calc(100% - 40px);
		margin: 0 20px;
	}
}

#submitBtn {
	float: none !important;
	background: transparent;
	padding: 17px 9px 13px !important;
	margin: 0 !important;
	border: 0;
	border-left: 2px solid $white;
	border-radius: 0;
	@include font-fix();
	font-weight: 900;
	font-size: 20px;
	color: $white;
	text-transform: uppercase;
	text-shadow: none;
	box-shadow: none;

	@media #{$mobile} {
		width: auto;
		height: auto;
		padding: 10px 6px 7px !important;
		font-size: 14px;
	}
}

.header_html {
	position: relative;

	@media #{$tablet-portrait} {
		order: 0;
	}

	&:after {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		z-index: -5;
		width: 100vw;
		height: 200vh;
		background-color: $orange;
	}
}

#col-main .addresses {
	position: relative;
	top: -149px;
	height: 569px !important;
	padding-right: 0 !important;
	margin-left: 10%;

	@media #{$tablet-portrait} {
		order: 5;
		top: -130px;
		flex: 0 0 calc(100% - 98px) !important;
		max-width: calc(100% - 98px) !important;
		width: calc(100% - 98px);
		height: auto !important;
		max-height: 569px !important;
		min-height: 138px;
		margin: 0 49px;
	}

	@media #{$mobile} {
		top: -174px;
	}

	@media #{$mobile-small} {
		flex: 0 0 calc(100% - 40px) !important;
		max-width: calc(100% - 40px) !important;
		width: calc(100% - 40px);
		margin: 0 20px;
	}

	li {
		min-height: 138px !important;
		padding: 15px 5px 15px 99px !important;
		border-top: 0 !important;
		background: url(https://cdn.shopify.com/s/files/1/0111/7532/9850/files/map-pin_2x_e6db4a77-320c-4a67-a0fa-fba4bdd083cd.png?v=1595934057) !important;
		background-repeat: no-repeat !important;
		background-size: 67px 95px !important;
		background-position: 0 22px !important;
		font-family: $font-coco-gothic !important;
		font-weight: 900 !important;
		font-size: 15px !important;
		line-height: 1.45 !important;
		color: $white;

		@media #{$mobile} {
			padding: 15px 5px 15px 86px !important;
			background-size: 48px 66px !important;
			background-position: 16px 19px !important;
			font-size: 11px !important;
		}
	}

	a {
		@media #{$tablet-portrait} {
			width: 100% !important;
		}
	}

	.no_stores_found {
		display: flex;
		align-items: center;
		min-height: 108px;
		margin: 0 !important;

		@media #{$mobile} {
			min-height: 72px;
		}
	}

	.name {
		display: block;
		@include font-fix();
		font-weight: 900 !important;
		font-size: 25px;
		color: $white !important;
		text-transform: uppercase;

		@media #{$mobile} {
			font-size: 16px;
		}
	}

	.address,
	.city,
	.prov_state,
	.postal_zip,
	.phone {
		color: inherit;
	}

	.phone {
		font-size: 25px;

		@media #{$mobile} {
			font-size: 20px;
		}
	}

	.directions {
		display: none;
	}

	.store_website {
		display: block;

		a {
			display: block;
			margin: 0;
		}
	}

	.distance {
		display: none;
	}

	.store_website {
		font-size: 20px;
		color: $red;
		text-transform: uppercase;
	}
}

#col-main {
	@media #{$tablet-portrait} {
		display: flex;
		flex-flow: row wrap;

		> * {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
}
