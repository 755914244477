/* ------------------------------------------------------------ *\
  Variables
\* ------------------------------------------------------------ */

$font-gotham: 'Gotham';
$font-coco-gothic: 'Coco Gothic';
$font-cocoGothicSmallCaps: 'CocoGothicSmallCaps';
$font-coco-gothic-fat: 'Coco Gothic Fat';

$font-family-sans-serif: $font-gotham;
$font-weight-base: 400;

$font-size-base: 20px;
$line-height-base: 1.5;

$shell-max-width: 983px;
$shell-sm-max-width: 843px;
$shell-lg-max-width: 1065px;
$shell-xl-max-width: 1157px;
$shell-gutter: 15px;

$responsive-xxs: 480px;
$responsive-xs: 767px;
$responsive-sm: 1023px;
$responsive-md: 1200px;

$mobile-small: '(max-width: #{$responsive-xxs}) ';
$mobile: '(max-width: #{$responsive-xs}) ';
$tablet-portrait: '(max-width: #{$responsive-sm}) ';
$tablet-landscape: '(max-width: #{$responsive-sm + 1}) ';
$small-desktop: '(max-width: #{$responsive-md}) ';
$retina: '(min-resolution: 2dppx) ';

// Usage
// @media #{$small-desktop}

/*  Colors  */

$black: #000;
$black2: #090909;
$white: #fff;
$brown: #591d0d;
$blue: #19709d;
$blue2: #2582be;
$blue3: #417189;
$orange: #ffa800;
$orange2: #ee683a;
$orange3: #eb5924;
$purple: #3a3058;
$purple2: #af79b3;
$purple3: #624973;
$light-purple: #7079ca;
$red: #e94442;
$red2: #f87a64;
$green: #04ff23;
$green2: #64a38a;
$beige: #e87f43;

$base: $black;
$primary: #777;
$secondary: #959595;
$tertiary: #eee;
$accent: $black;

/*  Headings  */
$font-size-h1: 60px;
$font-size-h2: 45px;
$font-size-h3: 30px;
$font-size-h4: 25px;
$font-size-h5: $font-size-base;
$font-size-h6: 15px;

$font-size-mobile-h1: 60px;
$font-size-mobile-h2: 45px;
$font-size-mobile-h3: 19px;
$font-size-mobile-h4: 25px;
$font-size-mobile-h5: $font-size-base;
$font-size-mobile-h6: 15px;
