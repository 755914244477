/* ------------------------------------------------------------ *\
	Nav Trigger
\* ------------------------------------------------------------ */

.nav-trigger {
	position: relative;
	z-index: 1; 
	display: inline-block;
	width: 40px;
	height: 34px;
	color: $white;

	span {
		position: absolute;
		display: block;
		width: 100%;
		height: 6px;
		background: currentColor;
		box-shadow: 0 0 7px 1px rgba($brown, .45);
		transition: top .2s .25s, opacity .2s .25s, transform .2s 0s;

		&:nth-child(1) {
			top: 0;
		}

		&:nth-child(2) {
			top: 14px;
		}

		&:nth-child(3) {
			top: 28px;
		}
	}
}

.nav-trigger.is--active {
	span {
		transition: background .2s, top .2s, opacity .2s, transform .2s .25s;

		&:nth-child(3),
		&:nth-child(1) {
			top: 14px;
		}

		&:nth-child(2) {
			opacity: 0;
		}

		&:nth-child(1) {
			transform: rotate(45deg);
		}

		&:nth-child(3) {
			transform: rotate(-45deg);
		}
	}
}
