/* ------------------------------------------------------------ *\
	Mixins
\* ------------------------------------------------------------ */

@mixin default-field() {
	width: 100%;
	padding: 16px 20px 15px;
	border: 1px solid $tertiary;

	&::placeholder {
		color: $secondary;
	}
}

@mixin default-button() {
	background: $accent;
	padding: 16px 40px;
	font-weight: 700;
	font-size: 16px;
	line-height: $line-height-base;
	color: #fff;
	border: 1px solid transparent;
	border-radius: 0;
	text-decoration: none;

	&:hover {
		background-color: #fff;
		color: $accent;
		border-color: currentColor;
	}

	&--inverse {
		background: #fff;
		color: $accent;
		border-color: currentColor;

		&:hover {
			background: $accent;
			color: #fff;
			border-color: transparent;
		}
	}
}

@mixin font-fix() {
	font-family: $font-gotham;
	font-weight: 900;
}
