/* ------------------------------------------------------------ *\
	Package
\* ------------------------------------------------------------ */

.package {
	top: 0 !important;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100) !important;
	background-color: $white;

	.package__title {
		position: relative;
		z-index: 5;
		height: 20vh;
		height: calc(var(--vh, 1vh) * 20);
		display: inline-flex;
		margin: 0 auto 3vh;

		@media #{$mobile-small} {
			height: 20vh;
			height: calc(var(--vh, 1vh) * 20);
		}

		&:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $white;
		}
	}

	.package__title img {
		display: block;
		width: auto;
		height: 100%;
		margin: 0;
	}

	.package__background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -5;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		transform: translateY(100%);
		transition: transform .4s;

		&.is-current {
			transform: none;
		}

		&.is-animated {
			transform: none;
		}
	}

	.package__text {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		opacity: 0;
		transition: opacity .4s;

		&.is-current {
			opacity: 1;
		}
	}

	.package__entry {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: auto;
		padding: 35px 0 20px;
		font-size: 15px;
		color: $white;
		text-align: center;
		opacity: 0;
		pointer-events: none;
		transition: opacity .4s;

		@media #{$mobile} {
			padding: 10px 20px;
		}

		&:before {
			content: '';
			position: absolute;
			bottom: 100%;
			left: 0;
			z-index: 10;
			width: 100%;
			height: 9vh;
			height: calc(var(--vh, 1vh) * 9);
			margin-bottom: -1px;
			background-position: top center;
			background-repeat: repeat-x;
			background-size: contain;

			@media #{$tablet-landscape} {
				height: 70px;
				background-size: 758px 70px;
			}
		}

		&:after {
			content: '';
			position: absolute;
			top: calc(100% - 30px);
			left: 0;
			z-index: 10;
			width: 100%;
			padding-top: 8.61%;
			margin-bottom: -1px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			@media #{$mobile} {
				height: 78px;
				background-position: 56% center;
				background-size: 900px 78px;
			}
		}

		&.is-current {
			bottom: 0;
			opacity: 1;
			pointer-events: all;
		}

		&--original {
			background-color: $purple3;

			&:before {
				background-image: url(../images/package-content-top-wave-original.png);
			}

			&:after {
				background-image: url(../images/package-content-bottom-wave-original.png);
			}
		}

		&--hickory {
			background-color: $blue3;

			&:before {
				background-image: url(../images/package-content-top-wave-hickory.png);
			}

			&:after {
				background-image: url(../images/package-content-bottom-wave-hickory.png);
			}
		}

		&--peri {
			background-color: $green2;

			&:before {
				background-image: url(../images/package-content-top-wave-peri.png);
			}

			&:after {
				background-image: url(../images/package-content-bottom-wave-peri.png);
			}
		}

		&--teriyaki {
			background-color: $beige;

			&:before {
				background-image: url(../images/package-content-top-wave-teriyaki.png);
			}

			&:after {
				background-image: url(../images/package-content-bottom-wave-teriyaki.png);
			}
		}

		&--bbq {
			background-color: $orange3;

			&:before {
				background-image: url(../images/package-content-top-wave-bbq.png);
			}

			&:after {
				background-image: url(../images/package-content-bottom-wave-bbq.png);
			}
		}

		&--garlic {
			background-color: $light-purple;

			&:before {
				background-image: url(../images/package-content-top-wave-garlic.png);
			}

			&:after {
				background-image: url(../images/package-content-bottom-wave-garlic.png);
			}
		}
	}

	.package__entry h4 {
		@include font-fix();
		font-weight: 900;
		color: $white;
		text-transform: uppercase;
	}

	.package__entry-inner {
		position: relative;
		z-index: 20;
		max-width: 500px;
		margin: 0 auto;
	}

	.package__image {
		position: relative;
		z-index: 20;
		width: calc(var(--vh, 1vh) * 39.5);
		height: calc(var(--vh, 1vh) * 53.9);
		margin: 0 auto;
		overflow: hidden;

		/* Mobile */

		@media #{$mobile} {
			width: calc(var(--vh, 1vh) * 37.6);
			height: calc(var(--vh, 1vh) * 51.6);
		}

		&:before {
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0;
			width: 100%;
			height: 3px;
			background-color: $white;

			@media #{$mobile} {
				bottom: 0;
			}
		}
	}

	.package__image img {
		max-height: none;
		max-width: none;
		width: 100%;
		margin: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.package__aside {
		padding-top: 30px;
		text-align: center;
	}

	.package__btn {
		min-width: 421px;
		border: 2px solid $white;
		background: transparent;
		padding: 7px 40px;
		@include font-fix();
		font-weight: 900;
		font-size: 18px;
		letter-spacing: .045em;
		text-transform: uppercase;
		transition: background .4s, color .4s;

		@media #{$mobile} {
			min-width: 0;
			width: 100%;
			padding: 12px 40px 9px;
			font-size: 18px;
		}

		&:hover {
			border-color: $white;
			background-color: $white;
			color: $black;
		}
	}
}
