/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

html {
	min-height: -webkit-fill-available;
	padding: 0 !important;
}

body {
	min-width: 320px;
	background: #fff;
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-base;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $base;
	min-height: -webkit-fill-available;
}

a {
	color: inherit;
	text-decoration: underline;

	&:hover,
	&[href^='tel'] {
		text-decoration: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: ($line-height-base / 2) + em;
}

h1 {
	font-size: $font-size-h1;

	@media #{$mobile} {
		font-size: $font-size-mobile-h1;
	}
}
h2 {
	font-size: $font-size-h2;

	@media #{$mobile} {
		font-size: $font-size-mobile-h2;
	}
}
h3 {
	font-size: $font-size-h3;

	@media #{$mobile} {
		font-size: $font-size-mobile-h3;
	}
}
h4 {
	font-size: $font-size-h4;

	@media #{$mobile} {
		font-size: $font-size-mobile-h4;
	}
}
h5 {
	font-size: $font-size-h5;

	@media #{$mobile} {
		font-size: $font-size-mobile-h5;
	}
}
h6 {
	font-size: $font-size-h6;

	@media #{$mobile} {
		font-size: $font-size-mobile-h6;
	}
}

p,
ul,
ol,
dl,
table,
blockquote {
	margin-bottom: ($line-height-base / 2) + em;
}

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
	margin-bottom: 0;
}

ul,
ol {
	margin-left: 20px;
}

img {
	margin-bottom: 10px;
}

img[style='float: left;'] {
	max-width: 50%;
	height: auto;
	margin: 0 10px 10px 0;
}

img[style='float: right;'] {
	max-width: 50%;
	height: auto;
	margin: 0 0 10px 10px;
}

nav {
	ul,
	ol {
		margin-left: 0;
	}
}
