/* ------------------------------------------------------------ *\
  Header
\* ------------------------------------------------------------ */

.header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 50;
	width: 100%;

	.header__notice {
		position: relative;
		z-index: 5;
		padding: 11px 0 10px;
		@include font-fix();
		font-weight: 900;
		font-size: 16px;
		letter-spacing: 0.215em;
		text-transform: uppercase;
		text-align: center;
		box-shadow: -9px 6px 19.8px 2.2px rgba($black, 0.67);

		@media #{$mobile} {
			padding: 5px 0;
			font-size: 10px;
			letter-spacing: 0.24em;
		}
	}

	.header__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media #{$tablet-portrait} {
			display: block;
		}
	}

	.header__aside {
		@media #{$tablet-portrait} {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.header__content {
		display: flex;
		align-items: center;
		margin: 0 -14px;

		@media #{$tablet-portrait} {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			display: block;
			height: 100vh;
			padding: 10px;
			margin: 0;
			background-color: $orange;
			opacity: 0;
			pointer-events: none;
			transform: translateY(20px);
			transition: opacity 0.4s, transform 0.4s;
		}

		> * {
			padding: 0 14px;

			@media #{$tablet-portrait} {
				padding: 0;
			}
		}

		.nav {
			@media #{$tablet-portrait} {
				padding-top: 131px;
				margin-bottom: 75px;
			}
		}

		&.is--active {
			opacity: 1;
			pointer-events: all;
			transform: none;
		}
	}

	.header__body {
		padding: 24px 28px 24px 17px;

		@media #{$tablet-portrait} {
			padding: 3px 1px;
		}
	}

	.header__nav-trigger {
		display: none;
		margin-top: -4px;

		@media #{$tablet-portrait} {
			display: inline-block;
		}
	}

	.header__logo {
		@media #{$tablet-portrait} {
			margin: 7px 0 0 -14px;
		}
	}

	.header__btn-cart-mobile {
		display: none;
		margin-left: auto;
		margin-right: 10px;

		svg {
			width: 40px;
			height: 40px;
		}

		.btn-cart__counter {
			top: 1px;
			left: -5px;
		}

		@media #{$tablet-portrait} {
			display: inline-flex;
		}
	}
}
