/* ------------------------------------------------------------ *\
	Section Features
\* ------------------------------------------------------------ */

.section-features {
	padding: 229px 0 82px;

	@media #{$mobile} {
		padding: 160px 0 119px;
	}

	.section-features__shell {
		max-width: 1115px;

		@media #{$mobile} {
			padding: 0 30px;
		}
	}

	.section-features__head {
		margin-bottom: 70px;
		text-align: center;

		@media #{$mobile} {
			margin-bottom: 53px;
		}
	}

	.section-features__head h1 {
		font-family: $font-coco-gothic-fat;
		font-weight: 900;
		color: $white;
		text-transform: uppercase;

		@media #{$mobile} {
			font-size: 38px;
		}
	}
}
