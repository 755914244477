/* ------------------------------------------------------------ *\
	Card
\* ------------------------------------------------------------ */

.card {
	font-weight: 300;
	font-size: 20px;
	line-height: 1.8;
	color: $white;

	@media #{$mobile} {
		font-size: 15px;
		line-height: 1.5;
	}

	.card__inner {
		&:after {
			content: '';
			display: table;
			line-height: 0;
			clear: both;
		}

		img {
			float: left;
			margin: 6px 53px 0 0;
			box-shadow: 0 18px 48px 0 rgba($black, .48);

			@media #{$mobile} {
				float: right;
				max-width: 42%;
				margin: -11px 0 36px 0;
			}
		}

		h2 {
			float: left;
			clear: right;
			max-width: 295px;
			margin-bottom: 71px;
			font-family: $font-coco-gothic-fat;
			font-weight: 900;
			line-height: 1.2;
			color: $white;
			text-transform: uppercase;

			@media #{$mobile} {
				float: none;
				clear: none;
				max-width: 53%;
				margin: 0 0 36px 0;
				font-size: 25px;
				text-shadow: -32px 20px 21px rgba($black, .32);
			}
		}

		p {
			float: left;
			clear: right;
			max-width: 58%;

			@media #{$small-desktop} {
				max-width: 50%;
			}

			@media #{$tablet-portrait} {
				max-width: 40%;
			}

			@media #{$mobile} {
				float: none;
				clear: both;
				max-width: 100%;
			}
		}
	}

	&--secondary {
		@media #{$mobile} {
			line-height: 1.2;
		}

		.card__inner {
			img {
				@media #{$mobile} {
					float: left;
					max-width: 61%;
				}
			}

			h2 {
				@media #{$mobile} {
					float: right;
					max-width: 34%;
					margin: 56px 0 36px 0;
					font-size: 22px;
				}
			}
		}
	}

	&--reverse {
		.card__inner {
			img {
				float: right;
				margin: 4px 9px 0 0;

				@media #{$mobile} {
					max-width: 40%;
					margin: -29px -20px 47px 0;
				}
			}

			h2 {
				clear: left;
				margin-bottom: 67px;

				@media #{$mobile} {
					margin-bottom: 47px;
					text-shadow: none;
				}
			}

			p {
				clear: left;
			}
		}
	}
}
