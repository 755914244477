/* Cart */
.form--default {
	&.form--cart {
		table {
			text-align: left;
		}

		th,
		td {
			padding: 20px 10px;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		tbody {
			tr {
				border-bottom: 1px solid $tertiary;
			}
		}

		th {
			font-weight: 400;
			color: $secondary;
			border-bottom: 1px solid $tertiary;
		}

		tfoot {
			text-align: right;

			td {
				padding: 15px 10px;
			}
		}
	}

	.table__product {
		text-decoration: none;
	}

	.table__image {
		width: 140px;
	}

	.table__quantity {
		width: 140px;
		text-align: center;
	}

	.table__remove {
		width: 22px;
	}

	.table__variant {
		padding-top: 5px;
	}

	.table__properties {
		padding-top: 10px;
		font-style: italic;
		opacity: 0.6;
	}

	.table__total-label {
		font-size: 16px;
		color: $secondary;
	}

	.table__total-value {
		font-weight: 700;
		font-size: 18px;
		line-height: 1.56;
	}

	.table__info {
		font-style: italic;
		color: $secondary;

		td {
			padding-bottom: 40px;
			text-align: right;
		}
	}

	.table__price,
	.table__total {
		width: 160px;
		font-weight: 700;
	}

	.table__total {
		text-align: right;
	}

	.field--quantity {
		max-width: 80px;
		text-align: center;
	}

	.js-quantity {
		.field {
			appearance: textfield;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
			input[type='number'] {
				-moz-appearance: textfield;
			}
		}
	}

	.remove {
		font-size: 12px;
		text-decoration: none;

		span {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border: 1px solid $tertiary;

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 5px;
				right: 5px;
				height: 1px;
				background: $base;
				transform: translateY(-50%) rotate(45deg);
			}

			&:after {
				transform: translateY(-50%) rotate(-45deg);
			}
		}
	}

	@media #{$tablet-portrait} {
		.table__price,
		.table__total {
			width: 140px;
		}
	}

	@media #{$mobile} {
		&.form--cart {
			table,
			tbody {
				width: 100%;
				display: block;
			}

			thead,
			th {
				display: none;
			}

			table,
			tbody,
			td,
			tr,
			tfoot {
				display: block;
				width: 100%;
				text-align: center;
			}

			tr {
				position: relative;
				text-align: center;

				+ tr {
					border-top: 1px solid $base;
				}
			}

			tbody {
				td {
					width: auto;
					padding: 0 0 5px;
				}
				td:before {
					content: attr(data-attr);
					display: block;
					margin-bottom: 5px;
					font-weight: 700;
				}
			}

			tfoot {
				border-top: 1px solid $base;

				tr:first-child {
					padding: 10px 0;
					td {
						padding: 0;
						border: 0;
					}
				}
			}
		}
	}
}
