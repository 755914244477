/* ------------------------------------------------------------ *\
	Section Animate
\* ------------------------------------------------------------ */

.section-animate {
	position: relative;
	z-index: 10;
	top: 0 !important;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100) !important;
	background-color: $orange;

	h1 {
		/* Mobile */

		@media #{$mobile} {
			font-size: 36px;
		}
	}

	.section-animate__shell {
		padding: 0;
	}

	.section-animate__image {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: bottom center;
		opacity: 0;

		&.is-current {
			opacity: 1;
		}
	}

	.section-animate__image img {
		margin: 0;
	}

	.section-animate__package {
		position: absolute;
		bottom: -104px;
		left: 0;
		right: 0;
		z-index: 10;
		display: flex;
		justify-content: center;

		@media #{$tablet-portrait} {
			bottom: -61px;
		}
	}

	.section-animate__package-inner {
		display: inline-block;
		width: 79.7vh;
		height: calc(63vh - 1px);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		/* Mobile */

		@media #{$tablet-portrait} {
			min-width: auto;
			min-height: auto;
			width: 100%;
			padding: 0;
			padding-top: 80%;
		}

		/* Mobile */

		@media #{$mobile} {
			width: 100%;
			padding-top: 111%;
		}
	}

	.section-animate__texts {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		pointer-events: none;
	}

	.section-animate__text {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 15px;
		font-family: $font-coco-gothic-fat;
		font-weight: 900;
		letter-spacing: 0;
		line-height: 1.2;
		color: $white;
		text-align: center;
		text-transform: uppercase;
		text-shadow: 0px 17px 18px rgba($black2, .4);
		opacity: 0;
		pointer-events: none;
		transform: translateY(15%);
		transition: opacity .7s, transform .7s;

		&.is-current {
			opacity: 1;
			pointer-events: all;
			transform: none;
		}

		&.is-animated {
			transform: translateY(-15%);
		}
	}

	&--secondary {
		z-index: 10;
		height: auto;

		&:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			height: 200px;
			background-color: $orange;
		}

		.section-animate__images {
			position: relative;
			top: -2px !important;
			height: 100vh;
			height: calc(var(--vh, 1vh) * 100);
		}

		.section-animate__image {
			background-position: top center;
		}
	}
}
