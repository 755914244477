/* ------------------------------------------------------------ *\
	Product Feature
\* ------------------------------------------------------------ */

.product-feature {
	display: flex;
	align-items: center;
	margin: 0 -3px 5px;

	@media #{$mobile} {
		margin: 0 -5px 1px;
	}

	.product-feature__image {
		flex: 0 0 46px;
		max-width: 46px;
		padding: 0 3px;

		@media #{$mobile} {
			flex: 0 0 37px;
			max-width: 37px;
			padding: 0 5px;
			text-align: center;
		}
	}

	.product-feature__content {
		flex: 1 0;
		padding: 10px 3px 0;

		@media #{$mobile} {
			padding: 0 5px;
		}
	}

	.product-feature__content h4 {
		font-family: $font-gotham;
		font-weight: 900;
		font-size: 22px;
		letter-spacing: 0;
		text-transform: uppercase;

		@media #{$mobile} {
			font-size: 17px;
		}
	}
}
