/* ------------------------------------------------------------ *\
	Section Image
\* ------------------------------------------------------------ */

.section-image {
	background-color: $orange;

	.shell-image__shell {
		position: relative;
		top: -1px;
		height: calc(var(--vh, 1vh) * 51.3);
		min-height: 519px;

		@media #{$mobile} {
			min-height: 0;
		}
	}

	.section-image__inner {
		position: absolute;
		top: 0;
		left: 50%;
		bottom: 0;
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		transform: translateX(-50%);
	}
}
