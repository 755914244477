/* ------------------------------------------------------------ *\
	Hero
\* ------------------------------------------------------------ */

.hero {
	padding: 335px 0 154px;
	background-color: $orange;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom center;
	font-weight: 700;
	font-size: 30px;
	color: $white;
	letter-spacing: .012em;
	line-height: 1.35;
	text-align: center;

	@media #{$mobile} {
		padding: 122px 0 44px;
	}

	h1 {
		font-family: $font-coco-gothic-fat;
		font-size: 95px;
		font-weight: 900;
		letter-spacing: 0;
		line-height: 1.2;
		color: $white;
		text-transform: uppercase;
		text-shadow: 0px 17px 18px rgba($black2, .4);

		@media #{$mobile} {
			font-size: 30px;
		}
	}

	&--shop {
		padding: 335px 0 267px;

		@media #{$mobile} {
			padding: 116px 10px 229px;
		}

		h1 {
			@media #{$mobile} {
				font-size: 45px;
			}
		}
	}

	&--about {
		position: relative;
		padding: 291px 0 36px;
		background-color: $orange2;
		text-align: left;

		@media #{$mobile} {
			padding: 216px 0 111px;
			font-size: 20px;
			line-height: 1.2;
		}

		&:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			z-index: 10;
			width: 100%;
			height: 193px;
			margin-bottom: -1px;
			background-image: url(../images/product-orange-bottom-wave@2x.png);
			background-position: top center;
			background-repeat: no-repeat;
			background-size: cover;

			@media #{$mobile} {
				height: 120px;
				background-position: 56% center;
				background-size: 900px 120px;
			}
		}

		h1 {
			max-width: 510px;
			margin-bottom: 82px;

			@media #{$mobile} {
				margin-bottom: 47px;
				font-size: 45px;
			}
		}

		p {
			max-width: 96%;
		}

		.hero__shell {
			max-width: 878px;

			@media #{$mobile} {
				padding: 0 50px;
			}
		}
	}
}
