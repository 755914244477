/* ------------------------------------------------------------ *\
	Product Details
\* ------------------------------------------------------------ */

.product-details {
	padding: 0 0 0 60px;
	color: $white;

	@media #{$tablet-portrait} {
		padding: 0;
	}

	h1,
	h2,
	h3,
	h5,
	h6 {
		font-family: $font-gotham;
		font-weight: 900;
		line-height: 1.2;
		text-transform: uppercase;
	}

	h1 {
		margin-bottom: 35px;
		font-family: $font-coco-gothic-fat;

		@media #{$mobile} {
			margin-bottom: 40px;
			font-size: 38px;
		}

		@media #{$mobile-small} {
			font-size: 31px;
		}
	}

	h2 {
		margin-bottom: 1px;
		line-height: 1;

		@media #{$mobile} {
			font-size: 34px;
			line-height: .8;
		}

		@media #{$mobile-small} {
			font-size: 28px;
		}
	}

	h3 {
		margin-bottom: 16px;

		@media #{$mobile} {
			margin-bottom: 11px;
			font-size: 24px;
		}

		@media #{$mobile-small} {
			font-size: 20px;
		}
	}

	h5 {
		margin-bottom: 5px;
		line-height: 1.5;

		@media #{$mobile} {
			font-size: 15px;
			line-height: 1.2;
		}

		@media #{$mobile-small} {
			font-size: 13px;
		}
	}

	h6 {
		margin-bottom: 5px;

		@media #{$mobile} {
			font-size: 10px;
		}
	}

	.product-details__content {
		max-width: 648px;
		padding: 0 9px;
		margin-bottom: 24px;

		@media #{$mobile} {
			padding: 0 36px;
			margin-bottom: 59px;
		}
	}
}
