/* ------------------------------------------------------------ *\
	Default Styles
\* ------------------------------------------------------------ */

/*  Product  */

.product--default {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;

	.product__images {
		flex: 0 0 48%;
		max-width: 48%;

		@media #{$tablet-portrait} {
			max-width: 100%;
		}

		img {
			display: block;
			width: 100%;
			margin: 0 auto 20px;
		}
	}

	.product__thumbs {
		.slick-list {
			margin: 0 -10px;
		}

		.slick-slide {
			padding: 0 10px;
		}

		img {
			cursor: pointer;
		}
	}

	.product__content {
		flex: 0 0 48%;
		max-width: 48%;
	}

	.product__meta {
		margin-bottom: 20px;
	}

	.product__vendor {
		font-style: italic;
	}

	.product__entry {
		color: $primary;
		margin-bottom: 30px;
	}

	.product__price {
		margin-bottom: 25px;

		del {
			display: inline-block;
			margin-right: 5px;
		}

		ins {
			text-decoration: none;
		}
	}

	.product__form {
		.form__row {
			padding-bottom: 20px;
		}

		.select {
			width: auto;
			min-width: 210px;
		}
	}

	@media #{$mobile} {
		display: block;

		.product__content,
		.product__images {
			display: block;
			max-width: 100%;
		}

		.product__content {
			padding-top: 0;
		}
	}
}
