/* ------------------------------------------------------------ *\
	Product Reviews
\* ------------------------------------------------------------ */

.product-reviews {
	.product-reviews__head {
		margin-bottom: 47px;
		text-align: center;

		@media #{$mobile} {
			margin-bottom: 23px;
		}
	}

	.product-reviews__head h1 {
		font-family: $font-coco-gothic-fat;
		font-weight: 900;
		color: $white;
		text-transform: uppercase;

		@media #{$mobile} {
			font-size: 37px;
			line-height: 1.2;
		}
	}

	.product-reviews__body {
		padding: 0 48px;

		@media #{$mobile} {
			padding: 0 11px;
		}

		@media #{$mobile-small} {
			padding: 0;
		}
	}
}
