/*  Vendor  */
@import url(~slick-carousel/slick/slick.css);
/* Generic */
/* ------------------------------------------------------------ *\
  Variables
\* ------------------------------------------------------------ */
/*  Colors  */
/*  Headings  */
/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */
@import url(1-abstracts/_sprite.css);
html {
  min-height: -webkit-fill-available;
  padding: 0 !important; }

body {
  min-width: 320px;
  background: #fff;
  font-family: "Gotham";
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  color: #000;
  min-height: -webkit-fill-available; }

a {
  color: inherit;
  text-decoration: underline; }
  a:hover, a[href^='tel'] {
    text-decoration: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.75em; }

h1 {
  font-size: 60px; }
  @media (max-width: 767px) {
    h1 {
      font-size: 60px; } }

h2 {
  font-size: 45px; }
  @media (max-width: 767px) {
    h2 {
      font-size: 45px; } }

h3 {
  font-size: 30px; }
  @media (max-width: 767px) {
    h3 {
      font-size: 19px; } }

h4 {
  font-size: 25px; }
  @media (max-width: 767px) {
    h4 {
      font-size: 25px; } }

h5 {
  font-size: 20px; }
  @media (max-width: 767px) {
    h5 {
      font-size: 20px; } }

h6 {
  font-size: 15px; }
  @media (max-width: 767px) {
    h6 {
      font-size: 15px; } }

p,
ul,
ol,
dl,
table,
blockquote {
  margin-bottom: 0.75em; }

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
  margin-bottom: 0; }

ul,
ol {
  margin-left: 20px; }

img {
  margin-bottom: 10px; }

img[style='float: left;'] {
  max-width: 50%;
  height: auto;
  margin: 0 10px 10px 0; }

img[style='float: right;'] {
  max-width: 50%;
  height: auto;
  margin: 0 0 10px 10px; }

nav ul,
nav ol {
  margin-left: 0; }

/* ------------------------------------------------------------ *\
	Mixins
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */
@font-face {
  font-family: 'Gotham';
  src: url("../fonts/GothamLight-Italic.woff2") format("woff2"), url("../fonts/GothamLight-Italic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/GothamBook.woff2") format("woff2"), url("../fonts/GothamBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/GothamBook-Italic.woff2") format("woff2"), url("../fonts/GothamBook-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/GothamLight.woff2") format("woff2"), url("../fonts/GothamLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/GothamMedium-Italic.woff2") format("woff2"), url("../fonts/GothamMedium-Italic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/GothamMedium.woff2") format("woff2"), url("../fonts/GothamMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/GothamBold.woff2") format("woff2"), url("../fonts/GothamBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/GothamBold-Italic.woff2") format("woff2"), url("../fonts/GothamBold-Italic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Gotham';
  src: url("../fonts/Gotham-Black.woff2") format("woff2"), url("../fonts/Gotham-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Coco Gothic';
  src: url("../fonts/CocoGothic-LightItalic.woff2") format("woff2"), url("../fonts/CocoGothic-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Coco Gothic Fat';
  src: url("../fonts/CocoGothic-Fat.woff2") format("woff2"), url("../fonts/CocoGothic-Fat.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Coco Gothic Fat';
  src: url("../fonts/CocoGothic-FatItalic.woff2") format("woff2"), url("../fonts/CocoGothic-FatItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Coco Gothic';
  src: url("../fonts/CocoGothic-Heavy.woff2") format("woff2"), url("../fonts/CocoGothic-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Coco Gothic';
  src: url("../fonts/CocoGothic-Light.woff2") format("woff2"), url("../fonts/CocoGothic-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Coco Gothic';
  src: url("../fonts/CocoGothic.woff2") format("woff2"), url("../fonts/CocoGothic.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Coco Gothic';
  src: url("../fonts/CocoGothic-Italic.woff2") format("woff2"), url("../fonts/CocoGothic-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Coco Gothic';
  src: url("../fonts/CocoGothic-BoldItalic.woff2") format("woff2"), url("../fonts/CocoGothic-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Coco Gothic';
  src: url("../fonts/CocoGothic-Bold.woff2") format("woff2"), url("../fonts/CocoGothic-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'CocoGothicSmallCaps';
  src: url("../fonts/CocoGothicSmallCaps-Bold.woff2") format("woff2"), url("../fonts/CocoGothicSmallCaps-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'CocoGothicSmallCaps';
  src: url("../fonts/CocoGothicSmallCaps-BoldIta.woff2") format("woff2"), url("../fonts/CocoGothicSmallCaps-BoldIta.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */
.js-sticky.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; }

/*  Clear  */
.clear {
  @util clearfix; }

/*  Notext  */
.notext {
  @util text-hide; }

/*  Hidden  */
[hidden],
.hidden {
  display: none !important; }

/*  Alignleft  */
.alignleft {
  float: left; }

/*  Alignright  */
.alignright {
  float: right; }

/*  Disabled  */
[disabled],
.disabled {
  cursor: default; }

/*  Grid  */
.col {
  max-width: 100%;
  flex: 1; }
  .cols {
    display: flex;
    flex-flow: row wrap; }
  .col--1of2 {
    max-width: 50%;
    flex: 0 0 50%; }

/*  Responsive Helpers  */
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; }
  .visible-xs-block {
    display: block !important; }
  .visible-xs-inline {
    display: inline !important; }
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .hidden-sm {
    display: none !important; }
  .visible-sm-block {
    display: block !important; }
  .visible-sm-inline {
    display: inline !important; }
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 1024px) and (max-width: 1200px) {
  .hidden-md {
    display: none !important; }
  .visible-md-block {
    display: block !important; }
  .visible-md-inline {
    display: inline !important; }
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1201px) {
  .hidden-lg {
    display: none !important; }
  .visible-lg-block {
    display: block !important; }
  .visible-lg-inline {
    display: inline !important; }
  .visible-lg-inline-block {
    display: inline-block !important; } }

/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */
* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box; }
  *:before, *:after {
    box-sizing: inherit; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
  display: block; }

template {
  display: none; }

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: none; }

img,
iframe,
video,
audio,
object {
  max-width: 100%; }

img,
iframe {
  border: 0 none; }

img {
  height: auto;
  display: inline-block;
  vertical-align: middle; }

b,
strong {
  font-weight: bold; }

address {
  font-style: normal; }

svg:not(:root) {
  overflow: hidden; }

a,
button,
input[type='submit'],
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='image'],
label[for] {
  cursor: pointer; }

a[href^='tel'],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
textarea,
a[href^='tel'] {
  appearance: none; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  appearance: none; }

textarea {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; }

button,
select {
  text-transform: none; }

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

nav ul,
nav ol {
  list-style: none outside none; }

/* Default */
/* ------------------------------------------------------------ *\
	Article - Default Styles
\* ------------------------------------------------------------ */
.article--default {
  margin-bottom: 100px;
  color: #fff; }
  .article--default .article__head {
    margin-bottom: 60px;
    text-align: center; }
    @media (max-width: 767px) {
      .article--default .article__head h2 {
        font-size: 35px; } }
  .article--default .article__title {
    margin-bottom: 10px;
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    color: #fff;
    text-transform: uppercase; }
  .article--default .article__meta {
    margin-bottom: 45px; }
  .article--default .article__image {
    padding: 0 10%;
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .article--default .article__image {
        padding: 0; } }
    .article--default .article__image img {
      width: 100%;
      height: auto;
      margin-bottom: 0; }
  .article--default .article__body {
    margin-bottom: 80px;
    padding: 0 10%;
    color: #fff; }
    @media (max-width: 767px) {
      .article--default .article__body {
        padding: 0;
        font-size: 15px; } }
    .article--default .article__body h1,
    .article--default .article__body h2,
    .article--default .article__body h3,
    .article--default .article__body h4,
    .article--default .article__body h5,
    .article--default .article__body h6 {
      margin-bottom: 20px; }
    @media (max-width: 767px) {
      .article--default .article__body h1 {
        font-size: 35px; } }
    @media (max-width: 767px) {
      .article--default .article__body h2 {
        font-size: 30px; } }
    @media (max-width: 767px) {
      .article--default .article__body h3 {
        font-size: 25px; } }
    @media (max-width: 767px) {
      .article--default .article__body h4 {
        font-size: 20px; } }
    @media (max-width: 767px) {
      .article--default .article__body h5 {
        font-size: 15px; } }
    @media (max-width: 767px) {
      .article--default .article__body h6 {
        font-size: 13px; } }
    .article--default .article__body p {
      margin-bottom: 40px; }
    .article--default .article__body ul,
    .article--default .article__body ol {
      margin-bottom: 40px; }
  .article--default .article__comments {
    padding: 60px 10% 0;
    border-top: 1px solid #fff; }
    .article--default .article__comments .comments {
      margin-bottom: 60px;
      padding-bottom: 60px;
      border-bottom: 1px solid #fff; }
    .article--default .article__comments .comment {
      margin-bottom: 30px; }
      .article--default .article__comments .comment:last-child {
        margin-bottom: 0; }
      .article--default .article__comments .comment .comment__text {
        font-size: 16px;
        color: #fff;
        margin-bottom: 5px; }

/* ------------------------------------------------------------ *\
	Article Item - Default Styles
\* ------------------------------------------------------------ */
.article-item--default {
  margin-bottom: 80px;
  padding-bottom: 80px;
  border-bottom: 1px solid #fff; }
  .article-item--default .article-item__image {
    display: block;
    height: 0;
    padding-top: 56.25%;
    margin: 0 10% 50px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
  .article-item--default .article-item__content {
    padding: 0 10%; }
  .article-item--default .article-item__head {
    margin-bottom: 15px; }
  .article-item--default .article-item__title {
    margin-bottom: 5px;
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    color: #fff;
    text-transform: uppercase; }
    .article-item--default .article-item__title a {
      text-decoration: none; }
  .article-item--default .article-item__meta {
    color: #fff; }
  .article-item--default .article-item__body {
    margin-bottom: 30px;
    font-size: 16px;
    color: #fff; }
  @media (max-width: 480px) {
    .article-item--default {
      padding-bottom: 40px;
      margin-bottom: 40px; }
      .article-item--default .article-item__content {
        padding: 0 10px; } }

/* ------------------------------------------------------------ *\
	Blog
\* ------------------------------------------------------------ */
.blog--default .page__title {
  font-family: "Coco Gothic Fat";
  font-weight: 900;
  color: #fff;
  text-transform: uppercase; }

@media (max-width: 767px) {
  .blog--default h1.page__title {
    font-size: 35px; } }

/* Cart */
.form--default.form--cart table {
  text-align: left; }

.form--default.form--cart th,
.form--default.form--cart td {
  padding: 20px 10px; }
  .form--default.form--cart th:first-child,
  .form--default.form--cart td:first-child {
    padding-left: 0; }
  .form--default.form--cart th:last-child,
  .form--default.form--cart td:last-child {
    padding-right: 0; }

.form--default.form--cart tbody tr {
  border-bottom: 1px solid #eee; }

.form--default.form--cart th {
  font-weight: 400;
  color: #959595;
  border-bottom: 1px solid #eee; }

.form--default.form--cart tfoot {
  text-align: right; }
  .form--default.form--cart tfoot td {
    padding: 15px 10px; }

.form--default .table__product {
  text-decoration: none; }

.form--default .table__image {
  width: 140px; }

.form--default .table__quantity {
  width: 140px;
  text-align: center; }

.form--default .table__remove {
  width: 22px; }

.form--default .table__variant {
  padding-top: 5px; }

.form--default .table__properties {
  padding-top: 10px;
  font-style: italic;
  opacity: 0.6; }

.form--default .table__total-label {
  font-size: 16px;
  color: #959595; }

.form--default .table__total-value {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.56; }

.form--default .table__info {
  font-style: italic;
  color: #959595; }
  .form--default .table__info td {
    padding-bottom: 40px;
    text-align: right; }

.form--default .table__price,
.form--default .table__total {
  width: 160px;
  font-weight: 700; }

.form--default .table__total {
  text-align: right; }

.form--default .field--quantity {
  max-width: 80px;
  text-align: center; }

.form--default .js-quantity .field {
  appearance: textfield; }
  .form--default .js-quantity .field::-webkit-outer-spin-button, .form--default .js-quantity .field::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .form--default .js-quantity .field input[type='number'] {
    -moz-appearance: textfield; }

.form--default .remove {
  font-size: 12px;
  text-decoration: none; }
  .form--default .remove span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #eee; }
    .form--default .remove span:before, .form--default .remove span:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 5px;
      right: 5px;
      height: 1px;
      background: #000;
      transform: translateY(-50%) rotate(45deg); }
    .form--default .remove span:after {
      transform: translateY(-50%) rotate(-45deg); }

@media (max-width: 1023px) {
  .form--default .table__price,
  .form--default .table__total {
    width: 140px; } }

@media (max-width: 767px) {
  .form--default.form--cart table,
  .form--default.form--cart tbody {
    width: 100%;
    display: block; }
  .form--default.form--cart thead,
  .form--default.form--cart th {
    display: none; }
  .form--default.form--cart table,
  .form--default.form--cart tbody,
  .form--default.form--cart td,
  .form--default.form--cart tr,
  .form--default.form--cart tfoot {
    display: block;
    width: 100%;
    text-align: center; }
  .form--default.form--cart tr {
    position: relative;
    text-align: center; }
    .form--default.form--cart tr + tr {
      border-top: 1px solid #000; }
  .form--default.form--cart tbody td {
    width: auto;
    padding: 0 0 5px; }
  .form--default.form--cart tbody td:before {
    content: attr(data-attr);
    display: block;
    margin-bottom: 5px;
    font-weight: 700; }
  .form--default.form--cart tfoot {
    border-top: 1px solid #000; }
    .form--default.form--cart tfoot tr:first-child {
      padding: 10px 0; }
      .form--default.form--cart tfoot tr:first-child td {
        padding: 0;
        border: 0; } }

.collection--default .collection__head {
  text-align: center;
  margin-bottom: 25px; }
  .collection--default .collection__head--image {
    min-height: 14%;
    padding: 3.5% 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    color: #fff; }

.collection--default .collection__sort {
  margin-bottom: 90px; }
  .collection--default .collection__sort label {
    color: #fff;
    margin-right: 30px; }
  .collection--default .collection__sort select {
    display: inline-block;
    max-width: 310px; }

.collections--default .collection__head {
  text-align: center;
  margin-bottom: 80px; }

.collections--default .collection__items {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -2.5%;
  overflow: hidden; }
  .collections--default .collection__items > * {
    flex: 0 0 28.33%;
    max-width: 28.33%;
    margin: 0 2.5% 60px; }

.collections--default .collection__item {
  text-align: center; }
  .collections--default .collection__item a {
    text-decoration: none; }
  .collections--default .collection__item .collection__item-link {
    position: relative;
    display: block;
    padding-top: 100%;
    margin-bottom: 15px; }
  .collections--default .collection__item .collection__item-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }

.form--small {
  max-width: 580px;
  margin: 0 auto 40px; }
  .form--small:last-child {
    margin-bottom: 0; }

.form--default {
  /* Modifiers */ }
  .form--default .form__title {
    text-align: center; }
  .form--default .form__head {
    margin-bottom: 80px; }
  .form--default .form__description {
    padding: 40px 10% 0;
    font-size: 16px;
    color: #777;
    text-align: center; }
  .form--default .form__actions {
    text-align: right; }
  .form--default .form__btn {
    margin: 5px; }
  .form--default .form__empty {
    text-align: center; }
    .form--default .form__empty h2 {
      padding-bottom: 50px;
      border-bottom: 1px solid #eee;
      margin-bottom: 50px; }
    .form--default .form__empty p {
      color: #777;
      margin-bottom: 35px; }
  .form--default.form--login .form__btn {
    width: 100%;
    margin: 0 0 40px; }
  .form--default.form--login .form__label {
    display: none; }
  .form--default.form--guest {
    padding: 30px 0; }
  .form--default.form--recover .form__actions, .form--default.form--register .form__actions {
    text-align: center; }
  .form--default.form--recover .form__btn, .form--default.form--register .form__btn {
    width: 100%;
    margin: 0 0 40px; }
  .form--default.form--recover .form__label, .form--default.form--register .form__label {
    display: none; }
  .form--default.form--recover {
    display: none; }
  .form--default.form--contact .form__head {
    padding-bottom: 0;
    border-bottom: 1px solid #eee; }
  .form--default.form--contact .form__description {
    text-align: left; }
  .form--default.form--contact .form__label {
    display: none; }
  .form--default.form--contact .form__body,
  .form--default.form--contact .form__actions {
    padding: 0 10%; }
  @media (max-width: 767px) {
    .form--default .form__head {
      margin-bottom: 40px; }
    .form--default .form__description {
      padding-left: 0;
      padding-right: 0; }
    .form--default .form__actions {
      text-align: center; }
    .form--default.form--contact .form__head {
      padding-bottom: 40px; }
    .form--default.form--contact .form__body,
    .form--default.form--contact .form__actions {
      padding-left: 0;
      padding-right: 0; } }

.default--holder {
  /*  No Orders  */
  /* Order */
  /* Address */ }
  .default--holder .page__title {
    margin-bottom: 80px;
    text-align: center; }
  .default--holder .collection__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5%; }
  .default--holder .product__item,
  .default--holder .article-item--default {
    flex: 0 0 25%;
    width: 25%;
    padding: 0 1.5%;
    margin-bottom: 60px; }
  .default--holder .article-item--default {
    position: relative;
    padding-bottom: 47px;
    border-bottom: 0; }
    .default--holder .article-item--default .article-item__image {
      padding-top: 100%;
      margin: 0 0 28px; }
    .default--holder .article-item--default .article-item__content {
      padding: 0; }
    .default--holder .article-item--default .article-item__body {
      display: none; }
    .default--holder .article-item--default .article-item__meta {
      display: none; }
    .default--holder .article-item--default h3.article-item__title {
      font-size: 25px;
      text-align: center; }
    .default--holder .article-item--default .article-item__foot {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center; }
    .default--holder .article-item--default .btn {
      min-width: 207px;
      padding: 12px 20px 6px;
      font-size: 17px;
      letter-spacing: .08em; }
      @media (max-width: 480px) {
        .default--holder .article-item--default .btn {
          min-width: 0; } }
  .default--holder.page--error {
    /*  404  */ }
    .default--holder.page--error .page__title {
      margin-bottom: 0; }
    .default--holder.page--error .page__content {
      text-align: center; }
      .default--holder.page--error .page__content p {
        margin-bottom: 50px;
        font-size: 20px; }
  .default--holder.section-account {
    padding-bottom: 100px;
    overflow: hidden;
    /* Accounts */ }
    @media (max-width: 767px) {
      .default--holder.section-account {
        padding-bottom: 50px; } }
    .default--holder.section-account .section__inner {
      display: flex;
      justify-content: space-between; }
    .default--holder.section-account .section__title {
      font-family: "Coco Gothic Fat";
      font-weight: 900;
      font-size: 32px;
      margin-bottom: 50px;
      color: #fff;
      text-transform: uppercase; }
    .default--holder.section-account .section__aside {
      position: relative;
      width: 22.5%;
      min-width: 250px;
      padding-top: 90px;
      background: transparent;
      color: #fff; }
      .default--holder.section-account .section__aside:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        width: 100vw;
        background: inherit; }
      .default--holder.section-account .section__aside ul {
        margin: 0;
        list-style: none; }
        .default--holder.section-account .section__aside ul li {
          margin-bottom: 40px; }
        .default--holder.section-account .section__aside ul a {
          display: block;
          font-size: 16px;
          text-decoration: none; }
        .default--holder.section-account .section__aside ul .active a {
          font-weight: 700;
          text-decoration: underline; }
    .default--holder.section-account .section__content {
      padding-top: 90px;
      padding-left: 90px;
      width: 77.5%; }
  .default--holder .no-orders {
    padding-top: 50px;
    font-size: 16px;
    color: #fff;
    border-top: 1px solid #fff; }
  .default--holder .order {
    text-align: left; }
    .default--holder .order th {
      font-weight: 400;
      padding: 20px;
      color: #fff; }
    .default--holder .order td {
      padding: 25px 20px;
      text-align: left; }
      .default--holder .order td.order__number, .default--holder .order td.order__total {
        font-weight: 700; }
    .default--holder .order th,
    .default--holder .order td {
      border-bottom: 1px solid #fff; }
      .default--holder .order th:first-child,
      .default--holder .order td:first-child {
        padding-left: 0; }
      .default--holder .order th:last-child,
      .default--holder .order td:last-child {
        padding-right: 0; }
  .default--holder .order-head {
    margin-bottom: 15px; }
    .default--holder .order-head .order-head__date {
      color: #fff; }
  .default--holder .table-order--products {
    margin-bottom: 50px; }
    .default--holder .table-order--products a {
      text-decoration: none; }
      .default--holder .table-order--products a:hover {
        text-decoration: underline; }
    .default--holder .table-order--products th,
    .default--holder .table-order--products td {
      border: 1px solid #fff;
      padding: 15px 30px !important; }
  .default--holder .table-order--info th {
    text-align: left; }
  .default--holder .order-details .order-details__value {
    font-weight: 700; }
  .default--holder .btn--new {
    border: 2px solid #fff;
    background: #3a3058;
    min-width: 207px;
    padding: 12px 20px 6px;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    font-size: 17px;
    letter-spacing: .08em;
    text-transform: uppercase;
    transition: background .4s; }
    @media (max-width: 480px) {
      .default--holder .btn--new {
        min-width: 0; } }
    .default--holder .btn--new:hover {
      border-color: #fff;
      background-color: #af79b3;
      color: #fff; }
  .default--holder .form--address {
    width: 100%;
    order: -1;
    margin-bottom: 30px;
    padding: 30px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee; }
    .default--holder .form--address .form__label {
      display: none; }
  .default--holder .addresses {
    display: flex;
    flex-wrap: wrap; }
    .default--holder .addresses .address {
      display: flex;
      flex-flow: row wrap;
      width: 49%;
      margin: 0 1% 30px 0;
      border: 1px solid #fff;
      color: #fff; }
    .default--holder .addresses .address__title {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: auto;
      padding: 20px 30px;
      border-bottom: 1px solid #fff; }
      .default--holder .addresses .address__title span {
        margin-left: auto;
        font-size: 16px; }
        .default--holder .addresses .address__title span:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          height: 6px;
          width: 9px;
          border-left: 2px solid;
          border-bottom: 2px solid;
          transform: rotate(-45deg);
          margin-top: -4px;
          margin-left: 4px; }
    .default--holder .addresses .address__details {
      width: 100%;
      padding: 20px 30px; }
    .default--holder .addresses .address__actions {
      width: 100%;
      margin-top: auto;
      padding: 20px 30px;
      text-align: right;
      border-top: 1px solid #fff; }
      .default--holder .addresses .address__actions a {
        margin: 0 5px; }
        .default--holder .addresses .address__actions a:first-child:after {
          content: '|';
          margin-left: 10px; }
  .default--holder .addresses__actions {
    border-top: 1px solid #fff;
    padding: 30px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 767px) {
      .default--holder .addresses__actions {
        display: block; } }
    .default--holder .addresses__actions a {
      margin-left: auto; }
      @media (max-width: 767px) {
        .default--holder .addresses__actions a {
          display: block;
          width: 100%;
          margin-top: 20px; } }
  .default--holder .addresses__empty {
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
    flex: 1 1; }
    @media (max-width: 767px) {
      .default--holder .addresses__empty {
        text-align: center; } }
  @media (max-width: 1023px) {
    .default--holder .product__item,
    .default--holder .article-item--default {
      flex: 0 0 33.3%;
      width: 33.3%; }
    .default--holder.section-account .section__content {
      padding-left: 25px; }
    .default--holder .table-order--products th,
    .default--holder .table-order--products td {
      padding: 15px !important; } }
  @media (max-width: 767px) {
    .default--holder {
      /* Accounts */ }
      .default--holder .product__item,
      .default--holder .article-item--default {
        width: 50%;
        flex: 0 0 50%; }
      .default--holder.section-account .shell {
        padding: 0; }
      .default--holder.section-account .section__inner {
        display: block;
        min-height: auto; }
      .default--holder.section-account .section__aside {
        width: auto;
        padding: 30px 15px;
        text-align: center; }
        .default--holder.section-account .section__aside ul li {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 0; }
          .default--holder.section-account .section__aside ul li:last-child {
            margin-right: 0; }
      .default--holder.section-account .section__content {
        width: auto;
        padding: 45px 15px; }
      .default--holder.section-account .section__head {
        text-align: center; }
      .default--holder table,
      .default--holder tr,
      .default--holder td,
      .default--holder tbody,
      .default--holder tfoot {
        display: block; }
      .default--holder th {
        display: none; }
      .default--holder td:before {
        content: attr(data-heading);
        display: block;
        margin-right: 5px;
        font-weight: 700; }
      .default--holder tr + tr {
        border-top: 1px solid #000; }
      .default--holder .addresses .address {
        width: 100%; }
      .default--holder .table-order--products {
        margin-bottom: 0; }
        .default--holder .table-order--products td {
          padding: 5px 10px !important;
          border: 0; }
        .default--holder .table-order--products .order-details__title {
          font-weight: 700; }
        .default--holder .table-order--products tfoot {
          padding-top: 5px;
          border-top: 1px solid #000;
          border-bottom: 1px solid #000; }
          .default--holder .table-order--products tfoot tr,
          .default--holder .table-order--products tfoot td {
            border: 0;
            padding: 0; }
          .default--holder .table-order--products tfoot tr {
            margin-bottom: 5px; } }
  @media (max-width: 480px) {
    .default--holder {
      /* Accounts */ }
      .default--holder .collection__items {
        display: block;
        margin: 0; }
      .default--holder .product__item,
      .default--holder .article-item--default {
        width: auto;
        padding: 0;
        margin: 0 0 40px; }
      .default--holder .section__inner {
        display: block; }
      .default--holder .section__aside {
        width: auto;
        margin-right: 0; }
        .default--holder .section__aside ul a {
          margin: 5px 0; }
      .default--holder .section__content {
        width: auto; }
      .default--holder .order-info .cols {
        display: block; } }

.paging--default {
  margin-bottom: 90px;
  padding: 30px 0;
  text-align: center; }
  .paging--default ul,
  .paging--default li {
    display: inline-block;
    vertical-align: middle;
    margin: 10px; }
  .paging--default li a,
  .paging--default li.current span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    font-size: 14px;
    line-height: 1;
    padding: 15px 0;
    text-align: center;
    border: 1px solid #000; }
  .paging--default li.current span,
  .paging--default li:hover a {
    border-color: #fff;
    color: #fff; }
  .paging--default a {
    text-decoration: none; }
  .paging--default .paging-prev,
  .paging--default .paging-next {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    width: 50px;
    height: 50px; }
    .paging--default .paging-prev:after,
    .paging--default .paging-next:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 10px; }
  .paging--default > span {
    color: #000;
    opacity: .7; }
  .paging--default .paging-prev:after {
    content: '\25C0'; }
  .paging--default .paging-next:after {
    content: '\25B6'; }
  @media (max-width: 480px) {
    .paging--default ul,
    .paging--default li {
      margin: 2px; }
    .paging--default li a,
    .paging--default li.current span {
      width: 36px;
      height: 36px;
      font-size: 12px;
      padding: 9px 0; }
    .paging--default .paging-prev,
    .paging--default .paging-next {
      width: 36px;
      height: 36px; } }

/* ------------------------------------------------------------ *\
	Default Styles
\* ------------------------------------------------------------ */
/*  Product  */
.product--default {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }
  .product--default .product__images {
    flex: 0 0 48%;
    max-width: 48%; }
    @media (max-width: 1023px) {
      .product--default .product__images {
        max-width: 100%; } }
    .product--default .product__images img {
      display: block;
      width: 100%;
      margin: 0 auto 20px; }
  .product--default .product__thumbs .slick-list {
    margin: 0 -10px; }
  .product--default .product__thumbs .slick-slide {
    padding: 0 10px; }
  .product--default .product__thumbs img {
    cursor: pointer; }
  .product--default .product__content {
    flex: 0 0 48%;
    max-width: 48%; }
  .product--default .product__meta {
    margin-bottom: 20px; }
  .product--default .product__vendor {
    font-style: italic; }
  .product--default .product__entry {
    color: #777;
    margin-bottom: 30px; }
  .product--default .product__price {
    margin-bottom: 25px; }
    .product--default .product__price del {
      display: inline-block;
      margin-right: 5px; }
    .product--default .product__price ins {
      text-decoration: none; }
  .product--default .product__form .form__row {
    padding-bottom: 20px; }
  .product--default .product__form .select {
    width: auto;
    min-width: 210px; }
  @media (max-width: 767px) {
    .product--default {
      display: block; }
      .product--default .product__content,
      .product--default .product__images {
        display: block;
        max-width: 100%; }
      .product--default .product__content {
        padding-top: 0; } }

.search--default .results,
.search--default .form-search,
.search--default .page__head {
  margin-bottom: 10px;
  text-align: center; }

.search--default .page__title {
  margin-bottom: 50px;
  font-family: "Coco Gothic Fat";
  font-weight: 900;
  color: #fff;
  text-transform: uppercase; }

@media (max-width: 767px) {
  .search--default h1.page__title {
    font-size: 35px; } }

.search--default .form-search form {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff; }
  @media (max-width: 767px) {
    .search--default .form-search form {
      display: block; } }
  .search--default .form-search form > * {
    margin-left: 10px; }
    @media (max-width: 767px) {
      .search--default .form-search form > * {
        margin: 0 0 10px; } }
  .search--default .form-search form label {
    display: inline-block; }

.search--default .no-results {
  padding: 50px 0 0;
  color: #fff;
  text-align: center; }

.search--default .results {
  padding: 20px 0;
  color: #fff; }

.search--default .search__field {
  height: 58px;
  padding: 8px 17px 0;
  border: 2px solid #fff;
  background-color: transparent;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 23px;
  letter-spacing: -.02em;
  color: #fff;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .search--default .search__field {
      width: 100%;
      height: 43px;
      padding: 3px 15px 0;
      font-size: 16px; } }
  .search--default .search__field::placeholder {
    color: #fff;
    opacity: 1 !important; }

.search--default .search__btn {
  background: #000;
  padding: 16px 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  text-decoration: none;
  margin-top: 1px;
  border: 2px solid #fff;
  background: #3a3058;
  padding: 15px 40px 6px;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 24px;
  letter-spacing: .045em;
  text-transform: uppercase;
  transition: background .4s; }
  .search--default .search__btn:hover {
    background-color: #fff;
    color: #000;
    border-color: currentColor; }
  .search--default .search__btn--inverse {
    background: #fff;
    color: #000;
    border-color: currentColor; }
    .search--default .search__btn--inverse:hover {
      background: #000;
      color: #fff;
      border-color: transparent; }
  @media (max-width: 767px) {
    .search--default .search__btn {
      display: block;
      width: 100%; } }
  .search--default .search__btn:hover {
    border-color: #fff;
    background-color: #af79b3;
    color: #fff; }

.search--default .paging {
  margin-bottom: 0; }

/*  Base  */
/* ------------------------------------------------------------ *\
  Form Elements Reset
\* ------------------------------------------------------------ */
input::placeholder {
  color: inherit;
  opacity: 1; }

textarea::placeholder {
  color: inherit;
  opacity: 1; }

input:-webkit-autofill {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0 1000px #fff inset; }

input[type='checkbox'] {
  opacity: 0;
  position: absolute; }
  input[type='checkbox'] ~ label {
    position: relative; }
    input[type='checkbox'] ~ label:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;
      height: 20px;
      width: 20px;
      border: 1px solid #eee; }
    input[type='checkbox'] ~ label:after {
      content: none;
      position: absolute;
      left: 5px;
      top: 5px;
      transform: rotate(-45deg);
      display: inline-block;
      height: 6px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid; }
  input[type='checkbox']:checked ~ label:after {
    content: ''; }

/* ------------------------------------------------------------ *\
  Field
\* ------------------------------------------------------------ */
.field {
  width: 100%;
  padding: 16px 20px 15px;
  border: 1px solid #eee; }
  .field::placeholder {
    color: #959595; }

/* ------------------------------------------------------------ *\
  Textarea
\* ------------------------------------------------------------ */
.textarea {
  width: 100%;
  padding: 16px 20px 15px;
  border: 1px solid #eee; }
  .textarea::placeholder {
    color: #959595; }

/* ------------------------------------------------------------ *\
  Select
\* ------------------------------------------------------------ */
.select {
  display: block;
  width: 100%;
  padding: 16px 20px 16px;
  border: 1px solid #eee; }

/* ------------------------------------------------------------ *\
  Form Btn
\* ------------------------------------------------------------ */
.form__btn {
  background: #000;
  padding: 16px 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  text-decoration: none; }
  .form__btn:hover {
    background-color: #fff;
    color: #000;
    border-color: currentColor; }
  .form__btn--inverse {
    background: #fff;
    color: #000;
    border-color: currentColor; }
    .form__btn--inverse:hover {
      background: #000;
      color: #fff;
      border-color: transparent; }

/* ------------------------------------------------------------ *\
  Form Row
\* ------------------------------------------------------------ */
.form__row {
  margin-bottom: 20px; }

/* ------------------------------------------------------------ *\
  Form Label
\* ------------------------------------------------------------ */
.form__label {
  display: block;
  margin-bottom: 8px; }

/* ------------------------------------------------------------ *\
  Form Cols
\* ------------------------------------------------------------ */
.form__cols {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between; }

.form__col {
  flex: 0 0 48%;
  max-width: 48%; }

/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */
/* Regions */
/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Content
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */
.footer {
  position: relative;
  padding: 2px 0 44px;
  background-color: #19709d; }
  @media (max-width: 767px) {
    .footer {
      padding: 2px 0 12px; } }
  .footer:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 85px;
    margin-bottom: 12px;
    background-image: url(../images/footer-wave.png);
    background-position: left center;
    background-repeat: repeat-x;
    background-size: 1427px 85px; }
    @media (max-width: 767px) {
      .footer:before {
        height: 55px;
        margin-bottom: 0;
        background-position: 40% center;
        background-size: 923px 55px; } }
  .footer:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 13px;
    background-color: #19709d; }
    @media (max-width: 767px) {
      .footer:after {
        display: none; } }
  .footer .footer__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap; }
  .footer .footer__body {
    padding: 20px 28px 66px; }
    @media (max-width: 767px) {
      .footer .footer__body {
        padding: 0 9px 22px; } }
  .footer .footer__bar {
    padding: 9px 30px 0 20px; }
  .footer .footer__bar-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    margin: 0 -10px; }
    @media (max-width: 1023px) {
      .footer .footer__bar-inner {
        margin: 0; } }
  .footer .footer__bar-aside {
    padding: 0 10px; }
    @media (max-width: 1023px) {
      .footer .footer__bar-aside {
        order: 5;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0; } }
  .footer .footer__bar-content {
    padding: 0 10px; }
    @media (max-width: 1023px) {
      .footer .footer__bar-content {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0; } }
  .footer .footer__nav-secondary {
    font-family: "Gotham";
    font-weight: 900;
    font-size: 11px;
    letter-spacing: .1em;
    color: #fff;
    text-transform: uppercase; }
    @media (max-width: 1023px) {
      .footer .footer__nav-secondary {
        margin-bottom: 20px; } }
    @media (max-width: 767px) {
      .footer .footer__nav-secondary {
        text-align: center; } }
  .footer .footer__nav-secondary ul {
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    margin: 0 -9px;
    list-style-type: none; }
    @media (max-width: 1023px) {
      .footer .footer__nav-secondary ul {
        justify-content: center; } }
    @media (max-width: 767px) {
      .footer .footer__nav-secondary ul {
        display: block;
        margin: 0; } }
  .footer .footer__nav-secondary li {
    padding: 0 9px; }
    @media (max-width: 767px) {
      .footer .footer__nav-secondary li {
        padding: 0; } }
  .footer .footer__nav-secondary li + li {
    position: relative; }
    @media (max-width: 767px) {
      .footer .footer__nav-secondary li + li {
        margin-top: 20px; } }
    .footer .footer__nav-secondary li + li:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -2px;
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #fff;
      transform: translateY(-50%); }
      @media (max-width: 767px) {
        .footer .footer__nav-secondary li + li:before {
          top: -12px;
          left: 50%;
          transform: translateX(-50%); } }
  .footer .footer__nav-secondary a {
    text-decoration: none; }
  .footer .footer__copyright {
    font-family: "Gotham";
    font-weight: 300;
    font-size: 10px;
    color: #fff;
    text-transform: uppercase; }
    @media (max-width: 1023px) {
      .footer .footer__copyright {
        text-align: center; } }
  .footer .footer__nav {
    margin: 5px 0 0 89px;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .15em; }
    @media (max-width: 1200px) {
      .footer .footer__nav {
        order: 5;
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 40px;
        margin-left: 0; } }
    @media (max-width: 767px) {
      .footer .footer__nav {
        padding-left: 8px;
        margin-top: 20px;
        font-size: 10px;
        letter-spacing: .2em; } }
    .footer .footer__nav ul {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0 -16px;
      list-style-type: none; }
      @media (max-width: 1200px) {
        .footer .footer__nav ul {
          justify-content: center; } }
      @media (max-width: 767px) {
        .footer .footer__nav ul {
          margin: 0 -10px; } }
    .footer .footer__nav li {
      padding: 0 16px; }
      @media (max-width: 767px) {
        .footer .footer__nav li {
          padding: 0 10px; } }
    .footer .footer__nav a {
      text-decoration: none;
      transition: color .4s; }
      .footer .footer__nav a:hover {
        color: #ffa800; }
  .footer .footer__socials {
    display: flex;
    align-items: center;
    padding: 0 2px 0 0;
    margin: 10px -5px 0;
    color: #fff;
    list-style-type: none; }
    @media (max-width: 767px) {
      .footer .footer__socials {
        transform: scale(0.65);
        transform-origin: right; } }
    .footer .footer__socials li {
      padding: 0 5px; }
    .footer .footer__socials a {
      display: inline-block;
      transition: color .4s; }
      .footer .footer__socials a:hover {
        color: #ffa800; }
    .footer .footer__socials .facebook {
      margin-right: 11px; }
    .footer .footer__socials .instagram {
      margin-right: 4px; }
  @media (max-width: 767px) {
    .footer .footer__logo {
      max-width: 94px;
      margin: 6px 0 0 9px; } }

/* ------------------------------------------------------------ *\
  Header
\* ------------------------------------------------------------ */
.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%; }
  .header .header__notice {
    position: relative;
    z-index: 5;
    padding: 11px 0 10px;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    font-size: 16px;
    letter-spacing: 0.215em;
    text-transform: uppercase;
    text-align: center;
    box-shadow: -9px 6px 19.8px 2.2px rgba(0, 0, 0, 0.67); }
    @media (max-width: 767px) {
      .header .header__notice {
        padding: 5px 0;
        font-size: 10px;
        letter-spacing: 0.24em; } }
  .header .header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 1023px) {
      .header .header__inner {
        display: block; } }
  @media (max-width: 1023px) {
    .header .header__aside {
      display: flex;
      justify-content: space-between;
      align-items: center; } }
  .header .header__content {
    display: flex;
    align-items: center;
    margin: 0 -14px; }
    @media (max-width: 1023px) {
      .header .header__content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        height: 100vh;
        padding: 10px;
        margin: 0;
        background-color: #ffa800;
        opacity: 0;
        pointer-events: none;
        transform: translateY(20px);
        transition: opacity 0.4s, transform 0.4s; } }
    .header .header__content > * {
      padding: 0 14px; }
      @media (max-width: 1023px) {
        .header .header__content > * {
          padding: 0; } }
    @media (max-width: 1023px) {
      .header .header__content .nav {
        padding-top: 131px;
        margin-bottom: 75px; } }
    .header .header__content.is--active {
      opacity: 1;
      pointer-events: all;
      transform: none; }
  .header .header__body {
    padding: 24px 28px 24px 17px; }
    @media (max-width: 1023px) {
      .header .header__body {
        padding: 3px 1px; } }
  .header .header__nav-trigger {
    display: none;
    margin-top: -4px; }
    @media (max-width: 1023px) {
      .header .header__nav-trigger {
        display: inline-block; } }
  @media (max-width: 1023px) {
    .header .header__logo {
      margin: 7px 0 0 -14px; } }
  .header .header__btn-cart-mobile {
    display: none;
    margin-left: auto;
    margin-right: 10px; }
    .header .header__btn-cart-mobile svg {
      width: 40px;
      height: 40px; }
    .header .header__btn-cart-mobile .btn-cart__counter {
      top: 1px;
      left: -5px; }
    @media (max-width: 1023px) {
      .header .header__btn-cart-mobile {
        display: inline-flex; } }

/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */
.shell {
  max-width: 1013px;
  padding-right: 15px;
  padding-left: 15px;
  margin: auto; }
  .shell--fluid {
    max-width: none; }
  .shell--sm {
    max-width: 873px; }
  .shell--lg {
    max-width: 1095px; }
  .shell--xl {
    max-width: 1187px; }

/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
  Wrapper
\* ------------------------------------------------------------ */
.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow: hidden;
  min-height: -webkit-fill-available;
  /*IE11 fix*/ }
  .wrapper .footer {
    margin-top: auto; }
  .wrapper > * {
    flex: 0 0 auto; }

/*IE11 fix*/
body {
  display: flex;
  flex-direction: column; }

/* Components */
/* ------------------------------------------------------------ *\
  Accordion alt
\* ------------------------------------------------------------ */
.accordion-alt .accordion__section:first-child {
  margin-bottom: 19px;
  /* Mobile */ }
  @media (max-width: 767px) {
    .accordion-alt .accordion__section:first-child {
      margin-bottom: 0; } }

.accordion-alt .accordion__section + .accordion__section {
  /* Mobile */ }
  @media (max-width: 767px) {
    .accordion-alt .accordion__section + .accordion__section {
      margin-top: 24px; } }

.accordion-alt .accordion__section.is-current .accordion__content {
  color: #fff !important; }

.accordion-alt .accordion__content {
  padding-left: 85px;
  transition: color 0.3s;
  /* Mobile */ }
  @media (max-width: 767px) {
    .accordion-alt .accordion__content {
      padding-left: 39px; } }

.accordion-alt img {
  max-width: 65px;
  margin-bottom: 0;
  position: absolute;
  top: 50%;
  left: -85px;
  opacity: 0;
  transform: translate(-20px, -50%);
  transition: opacity 0.3s, transform 0.3s;
  /* Mobile */ }
  @media (max-width: 767px) {
    .accordion-alt img {
      max-width: 23px;
      left: -39px; } }

.accordion-alt .accordion__section.is-current img {
  opacity: 1;
  transform: translate(0, -50%); }

.accordion-alt .accordion__head {
  font-family: "Coco Gothic Fat";
  font-size: 46px;
  line-height: 2.14;
  text-transform: uppercase;
  letter-spacing: -0.04em;
  position: relative;
  /* Tablet Portrait */ }
  @media (max-width: 1023px) {
    .accordion-alt .accordion__head {
      font-size: 30px; } }
  @media (max-width: 767px) {
    .accordion-alt .accordion__head {
      font-size: 20px;
      line-height: 1.2; } }

.accordion-alt .accordion__body {
  font-family: "Gotham";
  font-weight: 300;
  font-size: 21px;
  letter-spacing: -0.04em;
  line-height: 1.2;
  padding-right: 178px;
  /* Tablet Portrait */
  /* Mobile */ }
  @media (max-width: 767px) {
    .accordion-alt .accordion__body {
      font-size: 15px; } }
  @media (max-width: 767px) {
    .accordion-alt .accordion__body {
      padding-right: 0;
      padding-top: 14px; } }

/* ------------------------------------------------------------ *\
	Accordion
\* ------------------------------------------------------------ */
.accordion .accordion__section {
  position: relative; }
  .accordion .accordion__section .accordion__head {
    padding: 41px calc(100% - 79.1%); }
    @media (max-width: 1094px) {
      .accordion .accordion__section .accordion__head {
        padding: 30px 15px 84px; } }
    @media (max-width: 767px) {
      .accordion .accordion__section .accordion__head {
        padding: 23px 38px 23px; } }
  .accordion .accordion__section h1 {
    margin-bottom: 0;
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    font-size: 50px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .accordion .accordion__section h1 {
        font-size: 17px; } }
  .accordion .accordion__section h3 {
    margin-bottom: 12px;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 700 !important;
    font-size: 35px;
    color: #fff;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .accordion .accordion__section h3 {
        margin-bottom: 4px;
        font-size: 11px; } }
  .accordion .accordion__section.is-current .accordion__image {
    opacity: 1;
    transition: opacity .4s .4s; }

.accordion .accordion__head {
  cursor: pointer; }

.accordion .accordion__body {
  max-width: 60%;
  padding: 51px 0 83px calc(100% - 79.1%);
  font-weight: 500;
  font-size: 26px;
  color: #fff;
  line-height: 1.45; }
  @media (max-width: 1094px) {
    .accordion .accordion__body {
      padding: 131px 0 30px 15px; } }
  @media (max-width: 767px) {
    .accordion .accordion__body {
      max-width: 80%;
      padding: 15px 38px 37px;
      font-size: 15px;
      line-height: 1.2; } }

.accordion .accordion__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 43%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  opacity: 0;
  transition: opacity .1s; }
  @media (max-width: 767px) {
    .accordion .accordion__image {
      width: 46%; } }

.accordion .accordion__content {
  position: relative;
  z-index: 5; }

/* ------------------------------------------------------------ *\
  Animate CSS
\* ------------------------------------------------------------ */
.animate__animated {
  -webkit-animation-fill-mode: backwards !important;
  animation-fill-mode: backwards !important; }

.js-animation-fade-in {
  opacity: 0;
  transition: opacity .4s; }
  .js-animation-fade-in.animated {
    opacity: 1; }

/* ------------------------------------------------------------ *\
	Bold-ro__vanilla-modal
\* ------------------------------------------------------------ */
.bold-ro__vanilla-modal #manage-subscription-root {
  padding: 190px 0 100px;
  background-color: #ffa800; }
  @media (max-width: 767px) {
    .bold-ro__vanilla-modal #manage-subscription-root {
      padding: 160px 0 100px; } }

/* ------------------------------------------------------------ *\
	Breadcrumbs
\* ------------------------------------------------------------ */
.breadcrumbs {
  margin-bottom: 60px;
  font-size: 12px;
  color: #777; }
  .breadcrumbs a {
    text-decoration: none; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .current {
    font-weight: 700;
    color: #000; }
  .breadcrumbs .breadcrumbs__divider {
    padding: 0 5px; }
  @media (max-width: 767px) {
    .breadcrumbs {
      margin-bottom: 30px; } }

/* ------------------------------------------------------------ *\
  Button Cart
\* ------------------------------------------------------------ */
.btn-cart {
  position: relative;
  z-index: 5;
  display: inline-flex;
  text-align: center;
  color: #fff; }
  .btn-cart .btn-cart__counter {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    background: #e94442;
    font-weight: 700;
    color: #fff;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: -7px;
    left: -6px; }
  .btn-cart:hover:after {
    transform: scaleX(1); }
  .btn-cart:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: 5px;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.4s; }
    @media (max-width: 1023px) {
      .btn-cart:after {
        content: none; } }
  .btn-cart:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -5;
    display: inline-block;
    width: 1px;
    height: 1px;
    transform: translate(-50%, -50%);
    box-shadow: -5px 3px 15px 7px rgba(89, 29, 13, 0.45); }
    @media (max-width: 1023px) {
      .btn-cart:before {
        content: none; } }

/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */
.btn {
  display: inline-block;
  border-radius: 0;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  box-shadow: none;
  appearance: none;
  background: #000;
  padding: 16px 40px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  text-decoration: none; }
  .btn:hover {
    background-color: #fff;
    color: #000;
    border-color: currentColor; }
  .btn--inverse {
    background: #fff;
    color: #000;
    border-color: currentColor; }
    .btn--inverse:hover {
      background: #000;
      color: #fff;
      border-color: transparent; }
  .btn--block {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0; }
  .btn--primary {
    border: 2px solid #fff;
    background: #3a3058;
    padding: 13px 40px 8px;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    font-size: 24px;
    letter-spacing: .045em;
    text-transform: uppercase;
    transition: background .4s; }
    @media (max-width: 767px) {
      .btn--primary {
        padding: 12px 40px;
        font-size: 18px; } }
    .btn--primary:hover {
      border-color: #fff;
      background-color: #af79b3;
      color: #fff; }
  .btn--secondary {
    padding: 13px 40px 8px;
    border: 2px solid #fff;
    background-color: transparent;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    font-size: 24px;
    letter-spacing: .04em;
    color: #fff;
    text-transform: uppercase;
    transition: border .4s, background .4s, color .4s; }
    @media (max-width: 767px) {
      .btn--secondary {
        padding: 12px 20px 4px;
        font-size: 18px; } }
    @media (max-width: 480px) {
      .btn--secondary {
        padding: 4px 10px; } }
    .btn--secondary:hover {
      border-color: #fff;
      background-color: #fff;
      color: #ffa800; }
  .btn--sm {
    min-width: 207px;
    padding: 11px 20px 7px;
    font-size: 17px;
    letter-spacing: .08em; }
    @media (max-width: 480px) {
      .btn--sm {
        min-width: 0; } }

/* ------------------------------------------------------------ *\
	Card
\* ------------------------------------------------------------ */
.card {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.8;
  color: #fff; }
  @media (max-width: 767px) {
    .card {
      font-size: 15px;
      line-height: 1.5; } }
  .card .card__inner:after {
    content: '';
    display: table;
    line-height: 0;
    clear: both; }
  .card .card__inner img {
    float: left;
    margin: 6px 53px 0 0;
    box-shadow: 0 18px 48px 0 rgba(0, 0, 0, 0.48); }
    @media (max-width: 767px) {
      .card .card__inner img {
        float: right;
        max-width: 42%;
        margin: -11px 0 36px 0; } }
  .card .card__inner h2 {
    float: left;
    clear: right;
    max-width: 295px;
    margin-bottom: 71px;
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    line-height: 1.2;
    color: #fff;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .card .card__inner h2 {
        float: none;
        clear: none;
        max-width: 53%;
        margin: 0 0 36px 0;
        font-size: 25px;
        text-shadow: -32px 20px 21px rgba(0, 0, 0, 0.32); } }
  .card .card__inner p {
    float: left;
    clear: right;
    max-width: 58%; }
    @media (max-width: 1200px) {
      .card .card__inner p {
        max-width: 50%; } }
    @media (max-width: 1023px) {
      .card .card__inner p {
        max-width: 40%; } }
    @media (max-width: 767px) {
      .card .card__inner p {
        float: none;
        clear: both;
        max-width: 100%; } }
  @media (max-width: 767px) {
    .card--secondary {
      line-height: 1.2; } }
  @media (max-width: 767px) {
    .card--secondary .card__inner img {
      float: left;
      max-width: 61%; } }
  @media (max-width: 767px) {
    .card--secondary .card__inner h2 {
      float: right;
      max-width: 34%;
      margin: 56px 0 36px 0;
      font-size: 22px; } }
  .card--reverse .card__inner img {
    float: right;
    margin: 4px 9px 0 0; }
    @media (max-width: 767px) {
      .card--reverse .card__inner img {
        max-width: 40%;
        margin: -29px -20px 47px 0; } }
  .card--reverse .card__inner h2 {
    clear: left;
    margin-bottom: 67px; }
    @media (max-width: 767px) {
      .card--reverse .card__inner h2 {
        margin-bottom: 47px;
        text-shadow: none; } }
  .card--reverse .card__inner p {
    clear: left; }

/* ------------------------------------------------------------ *\
	Collection
\* ------------------------------------------------------------ */
.collection--default .collection__title {
  font-family: "Coco Gothic Fat";
  font-weight: 900;
  color: #fff;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .collection--default .collection__title {
      font-size: 35px; } }

.collection--default .collection__description {
  font-size: 20px;
  color: #fff;
  line-height: 1.5; }

.collection--default .collection__sort {
  display: flex;
  align-items: center; }

.collection--default .select {
  position: relative;
  padding: 0;
  border: 0;
  min-width: 0;
  max-width: 310px;
  border: 2px solid #fff; }
  .collection--default .select:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 13px;
    width: 19px;
    height: 11px;
    background-image: url(../images/ico-arrow-down.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-50%);
    pointer-events: none; }

.collection--default .select select {
  width: 100%;
  max-width: 100%;
  height: 59px;
  padding: 0 40px 0 12px;
  border: 0;
  background-color: #ffa800;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 23px;
  color: #fff;
  text-transform: uppercase;
  appearance: none; }
  .collection--default .select select::-ms-expand {
    display: none; }

/* ------------------------------------------------------------ *\
	Collections
\* ------------------------------------------------------------ */
.collections--default .collection__title {
  font-family: "Coco Gothic Fat";
  font-weight: 900;
  color: #fff;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .collections--default .collection__title {
      font-size: 35px; } }

.collections--default .collection__item-title {
  color: #fff; }

/* ------------------------------------------------------------ *\
	Company Features
\* ------------------------------------------------------------ */
.company-features .company-features__head {
  margin-bottom: 50px;
  color: #fff;
  text-align: center; }
  @media (max-width: 767px) {
    .company-features .company-features__head {
      padding: 0 40px;
      margin-bottom: 26px; } }

.company-features .company-features__head h1,
.company-features .company-features__head h3 {
  font-weight: 900;
  line-height: 1.2;
  text-transform: uppercase; }

.company-features .company-features__head h1 {
  margin-bottom: 9px;
  font-family: "Coco Gothic Fat"; }
  @media (max-width: 767px) {
    .company-features .company-features__head h1 {
      margin-bottom: 10px;
      font-size: 37px; } }

.company-features .company-features__head h3 {
  font-family: "Gotham";
  font-weight: 900;
  letter-spacing: .1em; }
  @media (max-width: 767px) {
    .company-features .company-features__head h3 {
      line-height: 1.8; } }

/* ------------------------------------------------------------ *\
  Drawer
\* ------------------------------------------------------------ */
.drawer {
  background-color: #fff;
  width: 767px;
  max-width: 90%;
  padding-bottom: 30px;
  border-left: 1px solid #e6e6e6;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1500;
  transform: translateX(100%);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s; }
  .drawer.is-active {
    transform: translateX(0); }
  .drawer .drawer__close {
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 24px;
    right: 15px; }
  .drawer .drawer__close svg,
  .drawer .drawer__close path {
    display: block;
    width: 100%;
    height: 100%; }
  .drawer .drawer__head {
    text-align: center;
    padding: 0 30px;
    margin: 20px 0; }
  .drawer h3 {
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-top: 30px;
    margin-bottom: 15px; }
  .drawer .drawer__table {
    margin-bottom: 85px; }
  .drawer .drawer__note {
    padding: 0 30px;
    margin-bottom: 70px; }
  .drawer .drawer__note label {
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    display: block;
    margin-bottom: 21px; }
  .drawer .drawer__note textarea {
    font-size: 14px;
    line-height: 1.6;
    color: #333;
    width: 100%;
    min-height: 100px;
    display: block;
    padding: 8px 10px;
    border: 1px solid #e6e6e6;
    resize: vertical; }
  .drawer .drawer__foot {
    text-align: center; }
  .drawer .drawer__foot h6 {
    font-family: "Gotham";
    font-weight: 900;
    font-size: 11px;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    text-transform: uppercase;
    margin-bottom: 4px; }
  .drawer .drawer__foot h4 {
    font-family: "Gotham";
    font-weight: 900;
    font-size: 26px;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    margin-bottom: 16px; }
  .drawer .drawer__foot p {
    font-family: "Gotham";
    font-weight: 900;
    font-size: 17px;
    line-height: 1.6;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
    margin-bottom: 16px; }
  .drawer .drawer__foot strong {
    font-weight: 700; }
  .drawer .drawer__foot img {
    margin-bottom: 0; }

.drawer-overlay {
  background-color: rgba(0, 0, 0, 0);
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1499;
  transition: visibility 0.3s, background-color 0.3s; }

.drawer.is-active + .drawer-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  visibility: visible; }

/* ------------------------------------------------------------ *\
	Feature Secondary
\* ------------------------------------------------------------ */
.feature-secondary .feature-secondary__image {
  min-height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 43px; }
  @media (max-width: 767px) {
    .feature-secondary .feature-secondary__image {
      margin-bottom: 48px; } }

.feature-secondary .feature-secondary__image img {
  max-width: 65px;
  max-height: 87px;
  margin-bottom: 0; }

.feature-secondary .feature-secondary__content h4 {
  max-width: 140px;
  margin: 0 auto;
  font-family: "Coco Gothic Fat";
  font-weight: 900;
  line-height: 1.2;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  word-break: break-word; }
  @media (max-width: 767px) {
    .feature-secondary .feature-secondary__content h4 {
      font-size: 15px; } }

/* ------------------------------------------------------------ *\
	Feature
\* ------------------------------------------------------------ */
.feature {
  font-weight: 300;
  line-height: 1.2;
  color: #fff;
  text-align: center;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .feature {
      font-size: 15px; } }
  .feature .feature__image {
    min-height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 19px; }
    @media (max-width: 767px) {
      .feature .feature__image {
        margin-bottom: 0; } }
  .feature .feature__image img {
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .feature .feature__image img {
        transform: scale(0.8);
        transform-origin: center; } }

/* ------------------------------------------------------------ *\
	Features Secondary
\* ------------------------------------------------------------ */
.features-secondary {
  padding: 0 13px; }
  @media (max-width: 767px) {
    .features-secondary {
      padding: 0; } }
  .features-secondary .features-secondary__items {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0 -10px -20px; }
    @media (max-width: 1023px) {
      .features-secondary .features-secondary__items {
        justify-content: center;
        flex-wrap: wrap; } }
    @media (max-width: 767px) {
      .features-secondary .features-secondary__items {
        margin: 0 -5px; } }
  .features-secondary .features-secondary__item {
    padding: 0 20px;
    flex: 0 0 16.66%;
    max-width: 16.66%;
    margin-bottom: 20px; }
    @media (max-width: 1200px) {
      .features-secondary .features-secondary__item {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 0 10px; } }
    @media (max-width: 767px) {
      .features-secondary .features-secondary__item {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 5px; } }

/* ------------------------------------------------------------ *\
	Features
\* ------------------------------------------------------------ */
.features .features__items {
  display: flex;
  flex-flow: row wrap;
  padding: 0 6px 0 10px;
  margin: 0 -10px; }
  @media (max-width: 767px) {
    .features .features__items {
      padding: 0; } }

.features .features__item {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 10px; }
  @media (max-width: 1023px) {
    .features .features__item {
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .features .features__item {
      margin-bottom: 18px; } }

.features img {
  max-width: 80px; }

/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */
.form--product .form__controls {
  height: 100%; }

.form--product .form__cols {
  display: flex;
  flex-flow: row wrap; }
  @media (max-width: 480px) {
    .form--product .form__cols {
      display: block; } }

.form--product .form__col {
  flex: 1 0; }

.form--product .form__col--size-1 {
  flex: 0 0 68%;
  max-width: 68%; }
  @media (max-width: 767px) {
    .form--product .form__col--size-1 {
      flex: 0 0 57.6%;
      max-width: 57.6%; } }
  @media (max-width: 480px) {
    .form--product .form__col--size-1 {
      max-width: 100%; } }

.form--product .form__col--size-2 {
  flex: 0 0 32%;
  max-width: 32%; }
  @media (max-width: 767px) {
    .form--product .form__col--size-2 {
      flex: 0 0 42.4%;
      max-width: 42.4%; } }
  @media (max-width: 480px) {
    .form--product .form__col--size-2 {
      max-width: 100%; } }

.form--product .form__row {
  padding: 0 !important;
  border: 2px solid #fff; }
  @media (max-width: 767px) {
    .form--product .form__row {
      border-width: 3px; } }
  .form--product .form__row--quantity {
    border-left: 1px; }
    @media (max-width: 480px) {
      .form--product .form__row--quantity {
        border-left: 3px solid #fff; } }

.form--product .form__quantity {
  padding: 0 16px 0 13px;
  height: 100%;
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .form--product .form__quantity {
      background-color: #ffa800;
      padding: 0 13px; } }
  .form--product .form__quantity .form__quantity-inner {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 -3px; }
    @media (max-width: 767px) {
      .form--product .form__quantity .form__quantity-inner {
        height: 40px;
        padding-bottom: 6px;
        margin: 0 -6px; } }
  .form--product .form__quantity .form__quantity-aside {
    padding: 0 3px; }
    @media (max-width: 767px) {
      .form--product .form__quantity .form__quantity-aside {
        padding: 0 6px; } }
  .form--product .form__quantity .form__quantity-content {
    line-height: 1;
    flex: 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3px; }
    @media (max-width: 767px) {
      .form--product .form__quantity .form__quantity-content {
        justify-content: space-between;
        padding: 0 6px; } }
  .form--product .form__quantity .form__quantity-btn {
    margin-top: 7px;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    font-family: "Gotham";
    font-weight: 900;
    letter-spacing: 0 !important;
    font-weight: 900;
    font-size: 23px;
    appearance: none; }
    @media (max-width: 767px) {
      .form--product .form__quantity .form__quantity-btn {
        font-size: 17px; } }
  .form--product .form__quantity .form__label {
    margin: 7px 0 0; }
  .form--product .form__quantity .form__quantity-entry {
    min-width: 19px;
    margin-top: 7px;
    font-family: "Gotham";
    font-weight: 900;
    letter-spacing: 0 !important;
    font-weight: 900;
    font-size: 23px;
    text-transform: uppercase;
    text-align: center; }
    @media (max-width: 767px) {
      .form--product .form__quantity .form__quantity-entry {
        font-size: 17px; } }

.form--product .form__label {
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 23px;
  letter-spacing: 0 !important;
  text-transform: uppercase;
  line-height: 1; }
  @media (max-width: 767px) {
    .form--product .form__label {
      font-size: 17px; } }

.form--product .form__row {
  height: 58px;
  margin-bottom: 12px; }

.form--product .bold-ro__frequency-type,
.form--product .bold-ro__frequency-num {
  margin-bottom: 20px !important;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid #000;
  background-color: transparent;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 23px;
  height: 54px;
  text-transform: uppercase;
  letter-spacing: 0 !important;
  line-height: 1;
  appearance: none; }
  @media (max-width: 767px) {
    .form--product .bold-ro__frequency-type,
    .form--product .bold-ro__frequency-num {
      height: 40px;
      padding-left: 7px;
      padding-right: 7px;
      font-size: 17px; } }
  .form--product .bold-ro__frequency-type:last-child,
  .form--product .bold-ro__frequency-num:last-child {
    margin-bottom: 0 !important; }

.form--product .select {
  position: relative;
  padding: 0;
  border: 0;
  min-width: 0 !important;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #ffa800; }
  .form--product .select:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 13px;
    width: 19px;
    height: 11px;
    background-image: url(../images/ico-arrow-down.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-50%);
    pointer-events: none; }

.form--product .select select {
  width: 100%;
  padding: 4px 40px 0 12px;
  border: 0;
  background-color: #ffa800;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 23px;
  text-transform: uppercase;
  letter-spacing: 0 !important;
  line-height: 1;
  appearance: none; }
  @media (max-width: 767px) {
    .form--product .select select {
      height: 40px;
      padding: 0 45px 0 7px;
      font-size: 17px; } }
  .form--product .select select::-ms-expand {
    display: none; }

.form--product .field {
  height: 59px;
  border: 0;
  background-color: #ffa800;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 23px;
  text-transform: uppercase; }

.form--product .form__subscribe {
  position: relative;
  margin-top: 13px; }
  @media (max-width: 767px) {
    .form--product .form__subscribe {
      margin-top: 17px; } }
  .form--product .form__subscribe .bold-ro__recurring-title {
    display: none; }
  .form--product .form__subscribe .bold-ro__bold-active {
    border: 0;
    background-color: transparent; }
  .form--product .form__subscribe .bold-ro__one-time-div,
  .form--product .form__subscribe .bold-ro__recurring-div {
    padding: 0;
    border: 0; }
  .form--product .form__subscribe .bold-ro__frequency-label {
    padding: 0; }
  .form--product .form__subscribe .bold-ro__order-interval-container {
    display: block !important; }
    .form--product .form__subscribe .bold-ro__order-interval-container .bold-ro__order-interval-lbl,
    .form--product .form__subscribe .bold-ro__order-interval-container .bold-ro__frequency-num,
    .form--product .form__subscribe .bold-ro__order-interval-container .bold-ro__frequency-type {
      display: block;
      width: 100%;
      margin: 0; }
    .form--product .form__subscribe .bold-ro__order-interval-container .bold-ro__order-interval-lbl {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 0.045em; }
      @media (max-width: 767px) {
        .form--product .form__subscribe .bold-ro__order-interval-container .bold-ro__order-interval-lbl {
          font-size: 18px; } }
  .form--product .form__subscribe .bold-ro__frequency-div {
    margin-top: 20px; }
  .form--product .form__subscribe .form__subscribe-inner {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 40px 20px;
    margin-top: 10px;
    border: 1px solid #dbdbdb;
    background-color: #f5f5f5;
    color: #000;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s; }
  .form--product .form__subscribe.is-open .form__subscribe-inner {
    opacity: 1;
    pointer-events: all; }

.form--product .form__btn {
  /* Mobile */ }
  @media (max-width: 767px) {
    .form--product .form__btn {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1; } }

.form--subscribe .form__head {
  margin-bottom: 11px; }
  @media (max-width: 767px) {
    .form--subscribe .form__head {
      margin-bottom: 16px; } }

.form--subscribe .form__head h3 {
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  color: #fff;
  text-transform: uppercase; }

.form--subscribe .form__body {
  max-width: 417px;
  display: flex;
  border: 2px solid #fff; }
  @media (max-width: 480px) {
    .form--subscribe .form__body {
      display: block; } }

.form--subscribe .form__row {
  flex: 1 0;
  margin-bottom: 0;
  border-right: 2px solid #fff; }
  @media (max-width: 480px) {
    .form--subscribe .form__row {
      border-right: 0;
      border-bottom: 2px solid #fff; } }

.form--subscribe .form__field {
  height: 58px;
  border: 0;
  padding: 8px 17px 0;
  background-color: transparent;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 23px;
  letter-spacing: -0.02em;
  color: #fff;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .form--subscribe .form__field {
      height: 43px;
      padding: 3px 15px 0;
      font-size: 16px; } }
  .form--subscribe .form__field::placeholder {
    color: #fff;
    opacity: 1 !important; }

.form--subscribe .form__btn {
  min-width: 127px;
  padding: 15px 5px 12px;
  border: 0;
  border-radius: 0;
  background-color: #e94442;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 23px;
  letter-spacing: -0.02em !important;
  text-transform: uppercase;
  color: #fff; }
  @media (max-width: 767px) {
    .form--subscribe .form__btn {
      min-width: 130px;
      padding: 10px 5px 8px;
      font-size: 17px; } }
  @media (max-width: 480px) {
    .form--subscribe .form__btn {
      width: 100%; } }

.form--subscribe .form__message {
  padding: 20px 0; }
  .form--subscribe .form__message .errors ul {
    padding: 0;
    margin: 0;
    list-style-type: none; }
  .form--subscribe .form__message--error {
    color: #e94442; }
  .form--subscribe .form__message--success {
    color: #04ff23; }

.form--cart {
  color: #fff; }
  @media (max-width: 767px) {
    .form--cart h1 {
      font-size: 35px; } }
  .form--cart h2 {
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .form--cart h2 {
        font-size: 35px; } }
  .form--cart th {
    color: inherit !important; }
  .form--cart .form__title {
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    text-transform: uppercase; }
  .form--cart .table__info {
    color: currentColor; }
  .form--cart .table__total-label {
    color: inherit; }
  .form--cart .form__empty p {
    color: currentColor; }
  .form--cart .field--quantity {
    color: #000; }
  .form--cart .form__additional-buttons {
    padding: 50px 0 0; }
  .form--cart .remove span:before, .form--cart .remove span:after {
    background-color: currentColor; }

.form--login, .form--recover, .form--register, .form--address, .form--address-new {
  color: #fff; }
  @media (max-width: 767px) {
    .form--login h1, .form--recover h1, .form--register h1, .form--address h1, .form--address-new h1 {
      font-size: 35px; } }
  .form--login .form__description, .form--recover .form__description, .form--register .form__description, .form--address .form__description, .form--address-new .form__description {
    font-size: 20px;
    color: #fff; }
    @media (max-width: 767px) {
      .form--login .form__description, .form--recover .form__description, .form--register .form__description, .form--address .form__description, .form--address-new .form__description {
        font-size: 15px; } }
  .form--login .form__title, .form--recover .form__title, .form--register .form__title, .form--address .form__title, .form--address-new .form__title {
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.2; }
  .form--login .field, .form--recover .field, .form--register .field, .form--address .field, .form--address-new .field {
    color: #000; }
  .form--login .form__errors,
  .form--login .form__error, .form--recover .form__errors,
  .form--recover .form__error, .form--register .form__errors,
  .form--register .form__error, .form--address .form__errors,
  .form--address .form__error, .form--address-new .form__errors,
  .form--address-new .form__error {
    margin-bottom: 40px;
    text-align: center; }
    .form--login .form__errors ul,
    .form--login .form__error ul, .form--recover .form__errors ul,
    .form--recover .form__error ul, .form--register .form__errors ul,
    .form--register .form__error ul, .form--address .form__errors ul,
    .form--address .form__error ul, .form--address-new .form__errors ul,
    .form--address-new .form__error ul {
      list-style-type: none; }
  @media (max-width: 767px) {
    .form--login .form__cols, .form--recover .form__cols, .form--register .form__cols, .form--address .form__cols, .form--address-new .form__cols {
      display: block; } }
  @media (max-width: 767px) {
    .form--login .form__col, .form--recover .form__col, .form--register .form__col, .form--address .form__col, .form--address-new .form__col {
      max-width: 100%; } }

@media (max-width: 767px) {
  .form--contact .form__btn {
    display: block;
    width: 100%;
    margin: 0 !important; } }

.form--contact .field {
  resize: none; }

/* ------------------------------------------------------------ *\
	Hero
\* ------------------------------------------------------------ */
.hero {
  padding: 335px 0 154px;
  background-color: #ffa800;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  font-weight: 700;
  font-size: 30px;
  color: #fff;
  letter-spacing: .012em;
  line-height: 1.35;
  text-align: center; }
  @media (max-width: 767px) {
    .hero {
      padding: 122px 0 44px; } }
  .hero h1 {
    font-family: "Coco Gothic Fat";
    font-size: 95px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 1.2;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0px 17px 18px rgba(9, 9, 9, 0.4); }
    @media (max-width: 767px) {
      .hero h1 {
        font-size: 30px; } }
  .hero--shop {
    padding: 335px 0 267px; }
    @media (max-width: 767px) {
      .hero--shop {
        padding: 116px 10px 229px; } }
    @media (max-width: 767px) {
      .hero--shop h1 {
        font-size: 45px; } }
  .hero--about {
    position: relative;
    padding: 291px 0 36px;
    background-color: #ee683a;
    text-align: left; }
    @media (max-width: 767px) {
      .hero--about {
        padding: 216px 0 111px;
        font-size: 20px;
        line-height: 1.2; } }
    .hero--about:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 193px;
      margin-bottom: -1px;
      background-image: url(../images/product-orange-bottom-wave@2x.png);
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover; }
      @media (max-width: 767px) {
        .hero--about:after {
          height: 120px;
          background-position: 56% center;
          background-size: 900px 120px; } }
    .hero--about h1 {
      max-width: 510px;
      margin-bottom: 82px; }
      @media (max-width: 767px) {
        .hero--about h1 {
          margin-bottom: 47px;
          font-size: 45px; } }
    .hero--about p {
      max-width: 96%; }
    .hero--about .hero__shell {
      max-width: 878px; }
      @media (max-width: 767px) {
        .hero--about .hero__shell {
          padding: 0 50px; } }

/* ------------------------------------------------------------ *\
  Is busy
\* ------------------------------------------------------------ */
.is-busy {
  pointer-events: none;
  opacity: 0.3; }

/* ------------------------------------------------------------ *\
  List
\* ------------------------------------------------------------ */
[class^='list-'] {
  list-style: none outside none;
  margin-left: 0; }

/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */
.logo {
  z-index: 1;
  position: relative;
  display: inline-block;
  max-width: 133px;
  font-size: 0; }
  @media (max-width: 767px) {
    .logo {
      max-width: 105px; } }
  .logo img {
    margin-bottom: 0; }

/* ------------------------------------------------------------ *\
  Main
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Manage Subscription Page
\* ------------------------------------------------------------ */
.manage-subscription-page {
  color: #fff; }
  .manage-subscription-page > h3 {
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    text-align: center;
    text-transform: uppercase; }
  .manage-subscription-page > p {
    margin-bottom: 40px;
    text-align: center; }
  .manage-subscription-page .no-subscriptions {
    border: 2px solid #fff;
    border-radius: 0;
    background-color: transparent; }

/* ------------------------------------------------------------ *\
	Nav Access
\* ------------------------------------------------------------ */
.nav-access {
  color: #fff; }
  .nav-access ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 -9px;
    list-style-type: none; }
    @media (max-width: 1023px) {
      .nav-access ul {
        justify-content: center; } }
  .nav-access li {
    display: inline-flex;
    padding: 0 9px; }
  @media (max-width: 1023px) {
    .nav-access li.nav-access__cart-btn {
      display: none; } }
  .nav-access a {
    position: relative;
    z-index: 5;
    display: inline-flex;
    text-align: center; }
    .nav-access a:hover:after {
      transform: scaleX(1); }
    .nav-access a:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 2px;
      margin-top: 5px;
      background-color: #fff;
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 0.4s; }
    .nav-access a:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -5;
      display: inline-block;
      width: 1px;
      height: 1px;
      transform: translate(-50%, -50%);
      box-shadow: -5px 3px 15px 7px rgba(89, 29, 13, 0.45); }
  .nav-access a:before,
  .nav-access a:after {
    /* Mobile */ }
    @media (max-width: 1023px) {
      .nav-access a:before,
      .nav-access a:after {
        content: none; } }

/* ------------------------------------------------------------ *\
	Nav Trigger
\* ------------------------------------------------------------ */
.nav-trigger {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 40px;
  height: 34px;
  color: #fff; }
  .nav-trigger span {
    position: absolute;
    display: block;
    width: 100%;
    height: 6px;
    background: currentColor;
    box-shadow: 0 0 7px 1px rgba(89, 29, 13, 0.45);
    transition: top .2s .25s, opacity .2s .25s, transform .2s 0s; }
    .nav-trigger span:nth-child(1) {
      top: 0; }
    .nav-trigger span:nth-child(2) {
      top: 14px; }
    .nav-trigger span:nth-child(3) {
      top: 28px; }

.nav-trigger.is--active span {
  transition: background .2s, top .2s, opacity .2s, transform .2s .25s; }
  .nav-trigger.is--active span:nth-child(3), .nav-trigger.is--active span:nth-child(1) {
    top: 14px; }
  .nav-trigger.is--active span:nth-child(2) {
    opacity: 0; }
  .nav-trigger.is--active span:nth-child(1) {
    transform: rotate(45deg); }
  .nav-trigger.is--active span:nth-child(3) {
    transform: rotate(-45deg); }

/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */
.nav {
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 16px;
  letter-spacing: .2em;
  color: #fff;
  text-shadow: -5px 4px 11px rgba(89, 29, 13, 0.6);
  text-transform: uppercase; }
  @media (max-width: 1023px) {
    .nav {
      font-size: 20px;
      line-height: 1.52;
      text-align: center;
      text-shadow: none; } }
  .nav > ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0 -14px;
    list-style-type: none; }
    @media (max-width: 1023px) {
      .nav > ul {
        display: block;
        margin: 0; } }
  .nav > ul > li {
    padding: 0 14px; }
    @media (max-width: 1023px) {
      .nav > ul > li {
        padding: 0; } }
  @media (max-width: 1023px) {
    .nav > ul > li + li {
      margin-top: 31px; } }
  .nav > ul > li > a {
    position: relative;
    text-decoration: none; }
    .nav > ul > li > a:hover:after {
      transform: scaleX(1); }
    .nav > ul > li > a:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 2px;
      margin-top: 5px;
      background-color: #fff;
      transform: scaleX(0);
      transform-origin: center;
      transition: transform .4s; }
  .nav > ul > li > ul {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 1023px) {
      .nav > ul > li > ul {
        position: relative;
        top: 0;
        left: 0;
        transform: none; } }
  .nav > ul > li > ul ul {
    position: absolute;
    top: 0;
    left: 100%; }
    @media (max-width: 1023px) {
      .nav > ul > li > ul ul {
        position: relative;
        left: 0;
        font-size: 12px; } }
  .nav li {
    position: relative; }
    .nav li:hover > ul {
      opacity: 1;
      pointer-events: all; }
  .nav ul ul {
    border-radius: 5px;
    background-color: #fff;
    font-size: 14px;
    color: #19709d;
    opacity: 0;
    pointer-events: none;
    transition: opacity .4s; }
    @media (max-width: 1023px) {
      .nav ul ul {
        display: none;
        background-color: transparent;
        color: #fff;
        opacity: 1; } }
  .nav ul ul a {
    display: inline-block;
    padding: 10px 15px;
    text-decoration: none; }

/* ------------------------------------------------------------ *\
  No scroll
\* ------------------------------------------------------------ */
.no-scroll {
  overflow: hidden; }

/* ------------------------------------------------------------ *\
	Package
\* ------------------------------------------------------------ */
.package {
  top: 0 !important;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100) !important;
  background-color: #fff; }
  .package .package__title {
    position: relative;
    z-index: 5;
    height: 20vh;
    height: calc(var(--vh, 1vh) * 20);
    display: inline-flex;
    margin: 0 auto 3vh; }
    @media (max-width: 480px) {
      .package .package__title {
        height: 20vh;
        height: calc(var(--vh, 1vh) * 20); } }
    .package .package__title:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff; }
  .package .package__title img {
    display: block;
    width: auto;
    height: 100%;
    margin: 0; }
  .package .package__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -5;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: translateY(100%);
    transition: transform .4s; }
    .package .package__background.is-current {
      transform: none; }
    .package .package__background.is-animated {
      transform: none; }
  .package .package__text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: 0;
    transition: opacity .4s; }
    .package .package__text.is-current {
      opacity: 1; }
  .package .package__entry {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 35px 0 20px;
    font-size: 15px;
    color: #fff;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity .4s; }
    @media (max-width: 767px) {
      .package .package__entry {
        padding: 10px 20px; } }
    .package .package__entry:before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 9vh;
      height: calc(var(--vh, 1vh) * 9);
      margin-bottom: -1px;
      background-position: top center;
      background-repeat: repeat-x;
      background-size: contain; }
      @media (max-width: 1024px) {
        .package .package__entry:before {
          height: 70px;
          background-size: 758px 70px; } }
    .package .package__entry:after {
      content: '';
      position: absolute;
      top: calc(100% - 30px);
      left: 0;
      z-index: 10;
      width: 100%;
      padding-top: 8.61%;
      margin-bottom: -1px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
      @media (max-width: 767px) {
        .package .package__entry:after {
          height: 78px;
          background-position: 56% center;
          background-size: 900px 78px; } }
    .package .package__entry.is-current {
      bottom: 0;
      opacity: 1;
      pointer-events: all; }
    .package .package__entry--original {
      background-color: #624973; }
      .package .package__entry--original:before {
        background-image: url(../images/package-content-top-wave-original.png); }
      .package .package__entry--original:after {
        background-image: url(../images/package-content-bottom-wave-original.png); }
    .package .package__entry--hickory {
      background-color: #417189; }
      .package .package__entry--hickory:before {
        background-image: url(../images/package-content-top-wave-hickory.png); }
      .package .package__entry--hickory:after {
        background-image: url(../images/package-content-bottom-wave-hickory.png); }
    .package .package__entry--peri {
      background-color: #64a38a; }
      .package .package__entry--peri:before {
        background-image: url(../images/package-content-top-wave-peri.png); }
      .package .package__entry--peri:after {
        background-image: url(../images/package-content-bottom-wave-peri.png); }
    .package .package__entry--teriyaki {
      background-color: #e87f43; }
      .package .package__entry--teriyaki:before {
        background-image: url(../images/package-content-top-wave-teriyaki.png); }
      .package .package__entry--teriyaki:after {
        background-image: url(../images/package-content-bottom-wave-teriyaki.png); }
    .package .package__entry--bbq {
      background-color: #eb5924; }
      .package .package__entry--bbq:before {
        background-image: url(../images/package-content-top-wave-bbq.png); }
      .package .package__entry--bbq:after {
        background-image: url(../images/package-content-bottom-wave-bbq.png); }
    .package .package__entry--garlic {
      background-color: #7079ca; }
      .package .package__entry--garlic:before {
        background-image: url(../images/package-content-top-wave-garlic.png); }
      .package .package__entry--garlic:after {
        background-image: url(../images/package-content-bottom-wave-garlic.png); }
  .package .package__entry h4 {
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    color: #fff;
    text-transform: uppercase; }
  .package .package__entry-inner {
    position: relative;
    z-index: 20;
    max-width: 500px;
    margin: 0 auto; }
  .package .package__image {
    position: relative;
    z-index: 20;
    width: calc(var(--vh, 1vh) * 39.5);
    height: calc(var(--vh, 1vh) * 53.9);
    margin: 0 auto;
    overflow: hidden;
    /* Mobile */ }
    @media (max-width: 767px) {
      .package .package__image {
        width: calc(var(--vh, 1vh) * 37.6);
        height: calc(var(--vh, 1vh) * 51.6); } }
    .package .package__image:before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #fff; }
      @media (max-width: 767px) {
        .package .package__image:before {
          bottom: 0; } }
  .package .package__image img {
    max-height: none;
    max-width: none;
    width: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .package .package__aside {
    padding-top: 30px;
    text-align: center; }
  .package .package__btn {
    min-width: 421px;
    border: 2px solid #fff;
    background: transparent;
    padding: 7px 40px;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    font-size: 18px;
    letter-spacing: .045em;
    text-transform: uppercase;
    transition: background .4s, color .4s; }
    @media (max-width: 767px) {
      .package .package__btn {
        min-width: 0;
        width: 100%;
        padding: 12px 40px 9px;
        font-size: 18px; } }
    .package .package__btn:hover {
      border-color: #fff;
      background-color: #fff;
      color: #000; }

/* ------------------------------------------------------------ *\
	Packages Review
\* ------------------------------------------------------------ */
.packages-review {
  position: relative;
  z-index: 10; }
  .packages-review:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 142px;
    margin-bottom: -1px;
    background-image: url(../images/packages-review-top-wave.png);
    background-position: 100% center;
    background-repeat: repeat-x;
    background-size: 2286px 142px; }
    @media (max-width: 767px) {
      .packages-review:before {
        height: 69px;
        background-position: 56% center;
        background-size: 900px 69px; } }
  .packages-review .packages-review__shell {
    padding: 142px 0 0; }
    @media (max-width: 767px) {
      .packages-review .packages-review__shell {
        padding-top: 69px; } }

/* ------------------------------------------------------------ *\
	Page
\* ------------------------------------------------------------ */
.page .page__section--pull {
  margin-bottom: -116px; }
  .page .page__section--pull .section-products {
    position: relative;
    top: -199px; }
  @media (max-width: 767px) {
    .page .page__section--pull {
      margin-bottom: -156px; } }

.page .page__section--yellow {
  background-color: #ffa800; }

.page .page__section--orange {
  background-color: #ee683a; }

.page .page__section--red {
  background-color: #e94442; }

.page .page__section--light-red {
  background-color: #f87a64; }

.page .page__section--purple {
  background-color: #af79b3; }

.page .page__section--blue {
  background-color: #2582be; }

.page .page__section--blue-wave {
  position: relative; }
  .page .page__section--blue-wave:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 209px;
    background-image: url(../images/section-blue-bottom-wave@2x.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media (max-width: 767px) {
      .page .page__section--blue-wave:after {
        height: 92px;
        background-size: 810px 92px; } }

.page .page__section--purple-wave {
  position: relative; }
  .page .page__section--purple-wave:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 140px;
    background-image: url(../images/section-purple-bottom-wave@2x.png);
    background-position: left center;
    background-repeat: repeat-x;
    background-size: 1515px 140px; }
    @media (max-width: 767px) {
      .page .page__section--purple-wave:after {
        height: 87px;
        background-position: 37% center;
        background-size: 940px 87px; } }

@media (max-width: 767px) {
  .page .page__section--product-info {
    background-color: #af79b3; } }

.page--error {
  padding: 0 !important;
  color: #fff; }
  .page--error h1 {
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .page--error h1 {
        font-size: 35px; } }

.page--default {
  padding: 190px 0 100px;
  color: #fff; }
  @media (max-width: 767px) {
    .page--default {
      padding: 160px 0 100px; } }
  .page--default .page__head {
    margin-bottom: 40px; }
  .page--default .page__title {
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .page--default .page__title {
        font-size: 35px; } }
  .page--default .page__content h1 {
    font-size: 50px; }
    @media (max-width: 767px) {
      .page--default .page__content h1 {
        font-size: 35px; } }
  @media (max-width: 767px) {
    .page--default .page__content h2 {
      font-size: 30px; } }
  @media (max-width: 767px) {
    .page--default .page__content h3 {
      font-size: 25px; } }
  @media (max-width: 767px) {
    .page--default .page__content h4 {
      font-size: 20px; } }
  @media (max-width: 767px) {
    .page--default .page__content h5 {
      font-size: 15px; } }
  @media (max-width: 767px) {
    .page--default .page__content h6 {
      font-size: 12px; } }
  .page--default .page__content p {
    margin-bottom: 40px; }
    @media (max-width: 767px) {
      .page--default .page__content p {
        font-size: 15px; } }

/* ------------------------------------------------------------ *\
  Paging
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Process Step
\* ------------------------------------------------------------ */
.process-step {
  color: currentColor; }
  .process-step .process-step__inner {
    display: flex;
    align-items: flex-end;
    margin: 0 -3px;
    padding-left: 7px; }
    @media (max-width: 767px) {
      .process-step .process-step__inner {
        align-items: flex-start;
        padding-left: 0;
        margin: 0; } }
  .process-step .process-step__aside {
    flex: 0 0 38.8%;
    max-width: 38.8%;
    padding: 16px 3px 0;
    text-align: center; }
    @media (max-width: 1200px) {
      .process-step .process-step__aside {
        flex: 0 0 34%;
        max-width: 34%; } }
    @media (max-width: 767px) {
      .process-step .process-step__aside {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 12px 0 0; } }
  .process-step .process-step__aside svg,
  .process-step .process-step__aside img {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 19px; }
    @media (max-width: 767px) {
      .process-step .process-step__aside svg,
      .process-step .process-step__aside img {
        margin-bottom: 10px; } }
  .process-step .process-step__aside h3 {
    margin-bottom: 0;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    font-size: 35px;
    line-height: 1;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .process-step .process-step__aside h3 {
        font-size: 16px; } }
  .process-step .process-step__content {
    flex: 1 0;
    padding: 0 3px 9px;
    line-height: 1.2; }
    @media (max-width: 767px) {
      .process-step .process-step__content {
        padding: 0 0 0 16px;
        font-size: 15px; } }
  .process-step.is-current {
    color: #fff; }

/* ------------------------------------------------------------ *\
	Process
\* ------------------------------------------------------------ */
.process .process__item:nth-child(1) {
  color: #f87a64; }

.process .process__item:nth-child(2) {
  color: #e94442; }

.process .process__item:nth-child(3) {
  color: #2582be; }

.process .process__item ~ .process__item {
  margin-top: 46px; }
  @media (max-width: 767px) {
    .process .process__item ~ .process__item {
      margin-top: 52px; } }

/* ------------------------------------------------------------ *\
	Product About
\* ------------------------------------------------------------ */
.product-about .product-about__image {
  position: absolute;
  top: 5px;
  left: 0;
  width: 1091px;
  height: 722px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  pointer-events: none; }
  @media (max-width: 1023px) {
    .product-about .product-about__image {
      top: 270px;
      left: -45px;
      width: 700px;
      height: 463px; } }
  @media (max-width: 767px) {
    .product-about .product-about__image {
      top: 222px;
      left: -72px;
      width: 433px;
      height: 286px;
      transform: rotate(-10deg); } }

.product-about .product-about__entries {
  position: relative; }

.product-about .product-about__entry {
  font-weight: 300;
  line-height: 1.8;
  color: #fff; }
  @media (max-width: 767px) {
    .product-about .product-about__entry {
      font-size: 15px;
      line-height: 1.2; } }
  .product-about .product-about__entry:nth-child(1) {
    margin-bottom: 360px; }
    @media (max-width: 767px) {
      .product-about .product-about__entry:nth-child(1) {
        margin-bottom: 239px; } }
  .product-about .product-about__entry h1 {
    padding: 0 2px;
    margin-bottom: 4px;
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    letter-spacing: .04em;
    line-height: 1.2;
    text-transform: uppercase;
    appearance: none; }
    @media (max-width: 767px) {
      .product-about .product-about__entry h1 {
        margin-bottom: 17px;
        font-size: 37px; } }
  @media (max-width: 767px) {
    .product-about .product-about__entry p {
      padding: 0 6px; } }
  .product-about .product-about__entry.full-width {
    display: flex;
    align-items: flex-start;
    padding-left: 5px;
    margin: 0 -5px; }
    @media (max-width: 767px) {
      .product-about .product-about__entry.full-width {
        display: block;
        padding-left: 0;
        margin: 0; } }
    .product-about .product-about__entry.full-width > * {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 5px; }
      @media (max-width: 767px) {
        .product-about .product-about__entry.full-width > * {
          max-width: 100%;
          padding: 0; } }
    .product-about .product-about__entry.full-width h1 {
      letter-spacing: 0; }
      @media (max-width: 767px) {
        .product-about .product-about__entry.full-width h1 {
          margin-bottom: 23px;
          letter-spacing: .04em; } }
    .product-about .product-about__entry.full-width p {
      margin-top: -3px; }
      @media (max-width: 767px) {
        .product-about .product-about__entry.full-width p {
          padding: 0 6px;
          margin: 0; } }
  .product-about .product-about__entry.half-width-left {
    max-width: 50%;
    padding: 0 7px; }
    @media (max-width: 767px) {
      .product-about .product-about__entry.half-width-left {
        max-width: 100%;
        padding: 0; } }
  .product-about .product-about__entry.half-width-right {
    max-width: 50%;
    padding: 0 7px;
    margin-left: auto; }
    @media (max-width: 767px) {
      .product-about .product-about__entry.half-width-right {
        max-width: 100%;
        padding: 0; } }

.product-about .product-about__entry ~ .product-about__entry {
  margin-top: 46px; }
  @media (max-width: 767px) {
    .product-about .product-about__entry ~ .product-about__entry {
      margin-top: 72px; } }

/* ------------------------------------------------------------ *\
	Product Details
\* ------------------------------------------------------------ */
.product-details {
  padding: 0 0 0 60px;
  color: #fff; }
  @media (max-width: 1023px) {
    .product-details {
      padding: 0; } }
  .product-details h1,
  .product-details h2,
  .product-details h3,
  .product-details h5,
  .product-details h6 {
    font-family: "Gotham";
    font-weight: 900;
    line-height: 1.2;
    text-transform: uppercase; }
  .product-details h1 {
    margin-bottom: 35px;
    font-family: "Coco Gothic Fat"; }
    @media (max-width: 767px) {
      .product-details h1 {
        margin-bottom: 40px;
        font-size: 38px; } }
    @media (max-width: 480px) {
      .product-details h1 {
        font-size: 31px; } }
  .product-details h2 {
    margin-bottom: 1px;
    line-height: 1; }
    @media (max-width: 767px) {
      .product-details h2 {
        font-size: 34px;
        line-height: .8; } }
    @media (max-width: 480px) {
      .product-details h2 {
        font-size: 28px; } }
  .product-details h3 {
    margin-bottom: 16px; }
    @media (max-width: 767px) {
      .product-details h3 {
        margin-bottom: 11px;
        font-size: 24px; } }
    @media (max-width: 480px) {
      .product-details h3 {
        font-size: 20px; } }
  .product-details h5 {
    margin-bottom: 5px;
    line-height: 1.5; }
    @media (max-width: 767px) {
      .product-details h5 {
        font-size: 15px;
        line-height: 1.2; } }
    @media (max-width: 480px) {
      .product-details h5 {
        font-size: 13px; } }
  .product-details h6 {
    margin-bottom: 5px; }
    @media (max-width: 767px) {
      .product-details h6 {
        font-size: 10px; } }
  .product-details .product-details__content {
    max-width: 648px;
    padding: 0 9px;
    margin-bottom: 24px; }
    @media (max-width: 767px) {
      .product-details .product-details__content {
        padding: 0 36px;
        margin-bottom: 59px; } }

/* ------------------------------------------------------------ *\
	Product Faq
\* ------------------------------------------------------------ */
.product-faq .product-faq__cols {
  display: flex;
  height: 718px; }
  @media (max-width: 1023px) {
    .product-faq .product-faq__cols {
      display: block;
      height: auto; } }
  @media (min-width: 1024px) {
    .product-faq .product-faq__cols:hover .product-faq__col:nth-child(3) {
      max-width: 25%; }
    .product-faq .product-faq__cols:hover .product-faq__col:nth-child(3) + .product-faq__col {
      max-width: 25%; } }

.product-faq .product-faq__col {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  transition: max-width .4s; }
  @media (max-width: 1023px) {
    .product-faq .product-faq__col {
      display: block; } }
  @media (min-width: 1024px) {
    .product-faq .product-faq__col:nth-child(1):hover {
      max-width: 25%; }
      .product-faq .product-faq__col:nth-child(1):hover ~ .product-faq__col--2of12 {
        max-width: 16.667% !important; }
      .product-faq .product-faq__col:nth-child(1):hover ~ .product-faq__col--4of12 {
        max-width: 33.33% !important; }
      .product-faq .product-faq__col:nth-child(1):hover + .product-faq__col {
        max-width: 25% !important; } }
  .product-faq .product-faq__col:nth-child(2) > * {
    max-height: 50%; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__col:nth-child(2) > * {
        max-height: 100%; } }
  @media (min-width: 1024px) {
    .product-faq .product-faq__col:nth-child(2):hover > *:first-child:hover {
      max-height: 80% !important; }
      .product-faq .product-faq__col:nth-child(2):hover > *:first-child:hover + * {
        max-height: 20% !important; }
    .product-faq .product-faq__col:nth-child(2):hover ~ .product-faq__col--2of12 {
      max-width: 16.667% !important; }
    .product-faq .product-faq__col:nth-child(2):hover ~ .product-faq__col--4of12 {
      max-width: 33.33% !important; } }
  .product-faq .product-faq__col:nth-child(3) > * {
    max-height: 50%; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__col:nth-child(3) > * {
        max-height: 100%; } }
  @media (min-width: 1024px) {
    .product-faq .product-faq__col:nth-child(3):hover {
      max-width: 33.33% !important; }
      .product-faq .product-faq__col:nth-child(3):hover ~ .product-faq__col--2of12 {
        max-width: 16.667% !important; }
      .product-faq .product-faq__col:nth-child(3):hover > *:first-child {
        max-height: 24%; }
        .product-faq .product-faq__col:nth-child(3):hover > *:first-child:hover {
          max-height: 79% !important; }
          .product-faq .product-faq__col:nth-child(3):hover > *:first-child:hover + * {
            max-height: 21% !important; }
      .product-faq .product-faq__col:nth-child(3):hover > *:last-child {
        max-height: 76%; } }
  .product-faq .product-faq__col > * {
    flex: 1 0;
    height: 100%;
    transition: max-height .4s; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__col > * {
        height: auto; } }
  .product-faq .product-faq__col--2of12 {
    max-width: 16.667%; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__col--2of12 {
        max-width: 100%; } }
  .product-faq .product-faq__col--4of12 {
    max-width: 33.33%; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__col--4of12 {
        max-width: 100%; } }

.product-faq .product-faq__item {
  position: relative;
  overflow: hidden; }
  @media (min-width: 1024px) {
    .product-faq .product-faq__item:hover .product-faq__item-head {
      bottom: 100%;
      transform: translateY(92%); }
    .product-faq .product-faq__item:hover .product-faq__item-body {
      bottom: 47.2%;
      transform: none;
      opacity: 1;
      pointer-events: all; }
    .product-faq .product-faq__item:hover .product-faq__item-image {
      opacity: 1; } }
  @media (min-width: 1024px) {
    .product-faq .product-faq__item--second:hover .product-faq__item-head {
      bottom: 98%;
      transform: translateY(100%); }
    .product-faq .product-faq__item--second:hover .product-faq__item-body {
      bottom: 3px; }
    .product-faq .product-faq__item--second:hover + .product-faq__item .product-faq__item-head {
      top: 15px;
      max-width: 100%; } }
  .product-faq .product-faq__item--second .product-faq__item-head {
    max-width: 220px;
    padding: 0 13px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--second .product-faq__item-head {
        max-width: 100%;
        padding: 23px 15px 72px; } }
  .product-faq .product-faq__item--second .product-faq__item-head h6 {
    margin-bottom: 8px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--second .product-faq__item-head h6 {
        margin-bottom: 2px; } }
  .product-faq .product-faq__item--second .product-faq__item-body {
    padding: 17px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--second .product-faq__item-body {
        padding: 69px 11px 27px; } }
  .product-faq .product-faq__item--second .product-faq__item-image {
    width: 203px;
    height: 525px;
    margin-left: 59px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--second .product-faq__item-image {
        width: 126px;
        height: 325px; } }
  @media (min-width: 1024px) {
    .product-faq .product-faq__item--third:hover .product-faq__item-head {
      top: 14px;
      bottom: auto;
      max-width: 100%;
      transform: none; }
    .product-faq .product-faq__item--third:hover .product-faq__item-body {
      bottom: 15px; } }
  .product-faq .product-faq__item--third .product-faq__item-head {
    bottom: auto;
    top: 56px;
    max-width: 260px;
    padding: 0 13px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--third .product-faq__item-head {
        top: 0;
        padding: 17px 11px 72px; } }
  .product-faq .product-faq__item--third .product-faq__item-head h3 {
    letter-spacing: -.04em; }
  .product-faq .product-faq__item--third .product-faq__item-head h6 {
    margin-bottom: 5px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--third .product-faq__item-head h6 {
        margin-bottom: 2px; } }
  .product-faq .product-faq__item--third .product-faq__item-image {
    width: 276px;
    height: 285px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--third .product-faq__item-image {
        margin: 0 0 -4px 11px; } }
  @media (min-width: 1024px) {
    .product-faq .product-faq__item--fourth:hover .product-faq__item-head {
      bottom: 96%;
      transform: translateY(100%); }
    .product-faq .product-faq__item--fourth:hover .product-faq__item-body {
      bottom: 1.8%; }
    .product-faq .product-faq__item--fourth:hover + .product-faq__item .product-faq__item-head {
      top: 14px; } }
  .product-faq .product-faq__item--fourth .product-faq__item-head {
    max-width: 345px;
    padding: 12px 13px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--fourth .product-faq__item-head {
        max-width: 100%;
        padding: 25px 15px 36px; } }
  .product-faq .product-faq__item--fourth .product-faq__item-head h6 {
    margin-bottom: 1px; }
  .product-faq .product-faq__item--fourth .product-faq__item-body {
    padding: 18px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--fourth .product-faq__item-body {
        padding: 69px 11px 27px; } }
  .product-faq .product-faq__item--fourth .product-faq__item-image {
    top: -4px;
    bottom: auto;
    width: 229px;
    height: 460px;
    margin-left: 28px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--fourth .product-faq__item-image {
        width: 134px;
        height: 270px; } }
  @media (min-width: 1024px) {
    .product-faq .product-faq__item--fifth:hover .product-faq__item-head {
      bottom: auto;
      transform: none; }
    .product-faq .product-faq__item--fifth:hover .product-faq__item-body {
      bottom: 7px; } }
  .product-faq .product-faq__item--fifth .product-faq__item-head {
    bottom: auto;
    top: 2px;
    max-width: 345px;
    padding: 12px 13px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--fifth .product-faq__item-head {
        top: 0;
        padding: 26px 17px 34px; } }
  .product-faq .product-faq__item--fifth .product-faq__item-head h6 {
    margin-bottom: 5px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--fifth .product-faq__item-head h6 {
        margin-bottom: 2px; } }
  .product-faq .product-faq__item--fifth .product-faq__item-body {
    padding: 20px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--fifth .product-faq__item-body {
        padding: 69px 11px 27px; } }
  .product-faq .product-faq__item--fifth .product-faq__item-image {
    left: auto;
    right: 0;
    width: 405px;
    height: 369px;
    transform: none; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--fifth .product-faq__item-image {
        width: 263px;
        height: 240px; } }
  @media (min-width: 1024px) {
    .product-faq .product-faq__item--sixth:hover .product-faq__item-head {
      transform: translateY(102%); }
    .product-faq .product-faq__item--sixth:hover .product-faq__item-body {
      bottom: 4px; } }
  .product-faq .product-faq__item--sixth .product-faq__item-head {
    left: auto;
    right: 0;
    padding: 27px 13px;
    text-align: right; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--sixth .product-faq__item-head {
        left: 0;
        text-align: left;
        padding: 26px 15px 36px; } }
  .product-faq .product-faq__item--sixth .product-faq__item-head h6 {
    margin-bottom: 12px; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--sixth .product-faq__item-head h6 {
        margin-bottom: 2px; } }
  .product-faq .product-faq__item--sixth .product-faq__item-body {
    padding: 28px 20px 28px 28px;
    line-height: 1.5; }
    @media (max-width: 1023px) {
      .product-faq .product-faq__item--sixth .product-faq__item-body {
        padding: 69px 11px 27px; } }
  .product-faq .product-faq__item--sixth .product-faq__item-image {
    bottom: auto;
    top: 50%;
    left: auto;
    right: 0;
    width: 290px;
    height: 187px;
    margin-top: -22px;
    transform: translateY(-50%); }
  @media (max-width: 1023px) {
    .product-faq .product-faq__item.is-current .product-faq__item-image {
      opacity: 1; } }

.product-faq .product-faq__item-inner {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none; }

.product-faq .product-faq__item-image {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 420px;
  height: 309px;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity .4s;
  transform: translateX(-50%); }

.product-faq .product-faq__item-head {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 29px 13px;
  transition: top .4s, bottom .4s, transform .4s; }
  @media (max-width: 1023px) {
    .product-faq .product-faq__item-head {
      position: relative;
      padding: 31px 13px 64px; } }

.product-faq .product-faq__item-head h3 {
  font-family: "Coco Gothic Fat";
  font-weight: 900;
  line-height: 1.2;
  color: #fff;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .product-faq .product-faq__item-head h3 {
      font-size: 30px; } }

.product-faq .product-faq__item-head h6 {
  margin-bottom: 4px;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 700 !important;
  color: #fff;
  text-transform: uppercase; }

.product-faq .product-faq__item-body {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 13px;
  font-size: 15px;
  color: #fff;
  line-height: 1.4;
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
  transition: bottom .4s, transform .4s, opacity .4s; }
  @media (max-width: 1023px) {
    .product-faq .product-faq__item-body {
      position: relative;
      padding: 69px 11px 27px;
      opacity: 1;
      pointer-events: all;
      transform: none; } }

/* ------------------------------------------------------------ *\
	Product Feature
\* ------------------------------------------------------------ */
.product-feature {
  display: flex;
  align-items: center;
  margin: 0 -3px 5px; }
  @media (max-width: 767px) {
    .product-feature {
      margin: 0 -5px 1px; } }
  .product-feature .product-feature__image {
    flex: 0 0 46px;
    max-width: 46px;
    padding: 0 3px; }
    @media (max-width: 767px) {
      .product-feature .product-feature__image {
        flex: 0 0 37px;
        max-width: 37px;
        padding: 0 5px;
        text-align: center; } }
  .product-feature .product-feature__content {
    flex: 1 0;
    padding: 10px 3px 0; }
    @media (max-width: 767px) {
      .product-feature .product-feature__content {
        padding: 0 5px; } }
  .product-feature .product-feature__content h4 {
    font-family: "Gotham";
    font-weight: 900;
    font-size: 22px;
    letter-spacing: 0;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .product-feature .product-feature__content h4 {
        font-size: 17px; } }

/* ------------------------------------------------------------ *\
	Product Features
\* ------------------------------------------------------------ */
.product-features .product-features__items {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0 -12px -20px; }
  @media (max-width: 767px) {
    .product-features .product-features__items {
      display: block;
      padding: 0 34px;
      margin: 0; } }

.product-features .product-features__item {
  flex: 0 0 31.5%;
  max-width: 31.5%;
  padding: 0 12px;
  margin-bottom: 6px; }
  @media (max-width: 1023px) {
    .product-features .product-features__item {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (max-width: 767px) {
    .product-features .product-features__item {
      max-width: 100%;
      padding: 0;
      margin-bottom: 10px; } }

.product-features .product-feature__image {
  margin-right: 2px; }

.product-features img {
  max-width: 33px;
  max-height: 55px;
  margin-bottom: 0;
  /* Mobile */ }
  @media (max-width: 767px) {
    .product-features img {
      max-width: 100%;
      max-height: 40px; } }

/* ------------------------------------------------------------ *\
	Product Info
\* ------------------------------------------------------------ */
.product-info {
  position: relative;
  padding: 121px 0 76px; }
  @media (max-width: 767px) {
    .product-info {
      padding: 95px 0 104px; } }
  .product-info .product-info__image {
    position: absolute;
    top: 57px;
    left: 0;
    width: 1186px;
    height: 516px;
    background-size: cover;
    background-position: center; }
    @media (max-width: 1200px) {
      .product-info .product-info__image {
        top: 90px;
        width: 1000px;
        height: 435px; } }
    @media (max-width: 1023px) {
      .product-info .product-info__image {
        top: 160px;
        width: 700px;
        height: 305px; } }
    @media (max-width: 767px) {
      .product-info .product-info__image {
        top: 117px;
        width: 562px;
        height: 245px; } }
  .product-info .product-info__content {
    position: relative;
    z-index: 5; }
    .product-info .product-info__content .shell {
      display: flex;
      justify-content: flex-end; }
  .product-info .product-info__content-inner {
    width: 100%;
    min-height: 494px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 6px; }
    @media (max-width: 767px) {
      .product-info .product-info__content-inner {
        min-height: 464px; } }
  .product-info .product-info__head {
    text-align: center; }
  .product-info .product-info__head h3 {
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    font-size: 35px;
    color: #fff;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .product-info .product-info__head h3 {
        font-size: 27px; } }
  .product-info .product-simple {
    max-width: 207px;
    margin-left: auto; }
    @media (max-width: 767px) {
      .product-info .product-simple {
        margin: 0 auto; } }

/* ------------------------------------------------------------ *\
	Product Item
\* ------------------------------------------------------------ */
.product__item {
  position: relative;
  padding-bottom: 47px !important; }
  .product__item .product__item-image {
    display: block;
    padding-bottom: 114.06%;
    margin-bottom: 0;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat; }
  .product__item .product__item-title {
    margin-bottom: 23px;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    letter-spacing: 0 !important;
    color: #fff;
    text-align: center;
    text-transform: uppercase; }
    .product__item .product__item-title a {
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      text-decoration: none; }
      .product__item .product__item-title a > * {
        flex: 0 0 100%;
        max-width: 100%; }
      .product__item .product__item-title a small {
        order: -5;
        display: block;
        font-size: 60%; }
        @media (max-width: 767px) {
          .product__item .product__item-title a small {
            font-size: 50%; } }
  .product__item .product__item-price {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.75;
    text-transform: uppercase; }
    .product__item .product__item-price del {
      display: inline-block;
      font-weight: 400;
      color: #777;
      margin-right: 5px; }
    .product__item .product__item-price ins {
      text-decoration: none; }
  .product__item .product__item-info {
    color: #777; }
  .product__item .product__item-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center; }

/* ------------------------------------------------------------ *\
	Product Reviews
\* ------------------------------------------------------------ */
.product-reviews .product-reviews__head {
  margin-bottom: 47px;
  text-align: center; }
  @media (max-width: 767px) {
    .product-reviews .product-reviews__head {
      margin-bottom: 23px; } }

.product-reviews .product-reviews__head h1 {
  font-family: "Coco Gothic Fat";
  font-weight: 900;
  color: #fff;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .product-reviews .product-reviews__head h1 {
      font-size: 37px;
      line-height: 1.2; } }

.product-reviews .product-reviews__body {
  padding: 0 48px; }
  @media (max-width: 767px) {
    .product-reviews .product-reviews__body {
      padding: 0 11px; } }
  @media (max-width: 480px) {
    .product-reviews .product-reviews__body {
      padding: 0; } }

/* ------------------------------------------------------------ *\
	Product Simple
\* ------------------------------------------------------------ */
.product-simple h4 {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-bottom: 23px;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  color: #fff;
  text-align: center;
  text-transform: uppercase; }
  .product-simple h4 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .product-simple h4 small {
    order: -5;
    display: block;
    font-size: 60%; }

.product-simple .btn {
  padding: 12px 20px 7px;
  font-size: 18px; }

/* ------------------------------------------------------------ *\
	Product
\* ------------------------------------------------------------ */
.product .product__inner {
  display: flex;
  margin: 0 -10px; }
  @media (max-width: 1023px) {
    .product .product__inner {
      display: block;
      margin: 0; } }

.product .product__slider .slick-list {
  margin: 0 -20px; }

.product .product__slider .slick-slide {
  padding: 0 20px; }

.product .product__images {
  padding: 0 10px; }
  @media (max-width: 1023px) {
    .product .product__images {
      padding: 0;
      margin-bottom: 50px; } }
  @media (max-width: 767px) {
    .product .product__images {
      margin-bottom: 31px; } }

.product .product__thumbs {
  padding-left: 10px; }
  @media (max-width: 767px) {
    .product .product__thumbs {
      padding: 0 39px; } }

@media (max-width: 767px) {
  .product .product__thumbs .slick-list {
    margin: 0 -6px; } }

.product .product__thumbs .slick-slide {
  padding: 0 9px; }
  @media (max-width: 767px) {
    .product .product__thumbs .slick-slide {
      padding: 0 6px; } }

.product .product__thumbs .slick-slide img {
  width: 59px !important;
  height: 59px;
  border-radius: 50%;
  border: 1px solid #fff; }
  @media (max-width: 767px) {
    .product .product__thumbs .slick-slide img {
      width: 39px !important;
      height: 39px; } }

.product .product__content {
  flex: 0 0 43.7%;
  max-width: 43.7%;
  padding: 0 10px;
  color: #fff; }
  @media (max-width: 1023px) {
    .product .product__content {
      max-width: 100%;
      padding: 0; } }
  @media (max-width: 767px) {
    .product .product__content {
      padding: 0 27px 0 38px; } }

.product .product__title {
  font-family: "Coco Gothic Fat";
  font-weight: 900;
  line-height: 1.4;
  letter-spacing: -.04em;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .product .product__title {
      line-height: 1.45; } }
  @media (max-width: 480px) {
    .product .product__title {
      line-height: 1.2; } }

@media (max-width: 767px) {
  .product h1.product__title {
    font-size: 44px; } }

@media (max-width: 480px) {
  .product h1.product__title {
    font-size: 25px; } }

.product .product__title small {
  display: block;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 50%;
  letter-spacing: -.03em; }

.product .product__entry {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -.04em;
  color: inherit; }
  @media (max-width: 767px) {
    .product .product__entry {
      font-size: 15px; } }

.product .product__section {
  position: relative; }
  .product .product__section--main {
    position: relative;
    z-index: 15;
    padding: 74px 0 0; }
    @media (max-width: 767px) {
      .product .product__section--main {
        padding: 27px 0 0; } }
    .product .product__section--main .product__inner {
      position: relative;
      bottom: -125px; }
      @media (max-width: 767px) {
        .product .product__section--main .product__inner {
          bottom: -93px; } }
  .product .product__section--reviews {
    padding: 238px 0 164px; }
    @media (max-width: 767px) {
      .product .product__section--reviews {
        padding: 106px 0 80px; } }
  .product .product__section--orange {
    background-color: #ffa800; }
  .product .product__section--red {
    position: relative;
    z-index: 10;
    padding: 260px 0 53px;
    background-color: #e94442; }
    @media (max-width: 1023px) {
      .product .product__section--red {
        padding: 200px 0 53px; } }
    @media (max-width: 767px) {
      .product .product__section--red {
        padding: 150px 0 61px; } }
    .product .product__section--red:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 87px;
      margin-bottom: -1px;
      background-image: url(../images/product-red-bottom-wave@2x.png);
      background-position: 37% center;
      background-repeat: repeat-x;
      background-size: 1330px 87px; }
      @media (max-width: 767px) {
        .product .product__section--red:after {
          height: 40px;
          background-position: 41% center;
          background-size: 605px 40px; } }
  .product .product__section--light-red {
    padding: 89px 0 142px;
    background-color: #f87a64; }
  .product .product__section--purple {
    position: relative;
    z-index: 5;
    padding: 148px 0 154px;
    background-color: #af79b3; }
    @media (max-width: 767px) {
      .product .product__section--purple {
        padding: 100px 33px 34px; } }
    .product .product__section--purple:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 250px;
      background-image: url(../images/alternate-shape_01.png);
      background-position: 24% center;
      background-repeat: repeat-x;
      background-size: 2262px 250px; }
      @media (max-width: 767px) {
        .product .product__section--purple:after {
          height: 103px;
          background-position: 42% center;
          background-size: 900px 103px; } }
  .product .product__section--blue {
    position: relative;
    padding: 239px 0 55px;
    background-color: #2582be; }
    @media (max-width: 767px) {
      .product .product__section--blue {
        padding: 112px 0 55px; } }
    .product .product__section--blue:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 151px;
      background-image: url(../images/product-blue-bottom-wave@2x.png);
      background-position: left center;
      background-repeat: repeat-x;
      background-size: 1565px 151px; }
      @media (max-width: 767px) {
        .product .product__section--blue:after {
          height: 58px;
          background-size: 600px 58px; } }

/* ------------------------------------------------------------ *\
	Products
\* ------------------------------------------------------------ */
.products .products__items {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -10px -20px; }
  @media (max-width: 1023px) {
    .products .products__items {
      display: block;
      margin: 0; } }

.products .products__item {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 10px;
  margin-bottom: 20px; }
  @media (max-width: 1023px) {
    .products .products__item {
      max-width: 100%;
      padding: 0;
      margin-bottom: 0; } }
  .products .products__item .product__item {
    height: 100%; }

.products .slick-list {
  margin: 0 -10px; }

.products .slick-slide {
  padding: 0 10px; }

/* ------------------------------------------------------------ *\
  Quantity
\* ------------------------------------------------------------ */
.quantity {
  display: inline-block; }
  .quantity input {
    font-size: 14px;
    text-align: center;
    width: 53px;
    border: 0;
    border-radius: 0;
    -moz-appearance: textfield; }
    .quantity input::-webkit-outer-spin-button, .quantity input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  .quantity a {
    text-decoration: none;
    font-weight: 500;
    display: inline-block; }

/* ------------------------------------------------------------ *\
	Related Products
\* ------------------------------------------------------------ */
@media (max-width: 1023px) {
  .related-products {
    padding: 0 26px; } }

.related-products .related-products__head {
  margin-bottom: 47px;
  text-align: center; }

.related-products .related-products__head h3 {
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 35px;
  color: #fff;
  letter-spacing: .02em;
  text-transform: uppercase; }

/* ------------------------------------------------------------ *\
	Scrollmagic Container
\* ------------------------------------------------------------ */
.scrollmagic-container {
  background-color: #ffa800; }

/* ------------------------------------------------------------ *\
	Section Animate
\* ------------------------------------------------------------ */
.section-animate {
  position: relative;
  z-index: 10;
  top: 0 !important;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100) !important;
  background-color: #ffa800; }
  .section-animate h1 {
    /* Mobile */ }
    @media (max-width: 767px) {
      .section-animate h1 {
        font-size: 36px; } }
  .section-animate .section-animate__shell {
    padding: 0; }
  .section-animate .section-animate__image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    opacity: 0; }
    .section-animate .section-animate__image.is-current {
      opacity: 1; }
  .section-animate .section-animate__image img {
    margin: 0; }
  .section-animate .section-animate__package {
    position: absolute;
    bottom: -104px;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center; }
    @media (max-width: 1023px) {
      .section-animate .section-animate__package {
        bottom: -61px; } }
  .section-animate .section-animate__package-inner {
    display: inline-block;
    width: 79.7vh;
    height: calc(63vh - 1px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* Mobile */
    /* Mobile */ }
    @media (max-width: 1023px) {
      .section-animate .section-animate__package-inner {
        min-width: auto;
        min-height: auto;
        width: 100%;
        padding: 0;
        padding-top: 80%; } }
    @media (max-width: 767px) {
      .section-animate .section-animate__package-inner {
        width: 100%;
        padding-top: 111%; } }
  .section-animate .section-animate__texts {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    pointer-events: none; }
  .section-animate .section-animate__text {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    letter-spacing: 0;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0px 17px 18px rgba(9, 9, 9, 0.4);
    opacity: 0;
    pointer-events: none;
    transform: translateY(15%);
    transition: opacity .7s, transform .7s; }
    .section-animate .section-animate__text.is-current {
      opacity: 1;
      pointer-events: all;
      transform: none; }
    .section-animate .section-animate__text.is-animated {
      transform: translateY(-15%); }
  .section-animate--secondary {
    z-index: 10;
    height: auto; }
    .section-animate--secondary:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: 200px;
      background-color: #ffa800; }
    .section-animate--secondary .section-animate__images {
      position: relative;
      top: -2px !important;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100); }
    .section-animate--secondary .section-animate__image {
      background-position: top center; }

/* ------------------------------------------------------------ *\
	Section Card
\* ------------------------------------------------------------ */
.section-card {
  padding: 258px 0 72px; }
  @media (max-width: 767px) {
    .section-card {
      padding: 131px 0 30px; } }
  .section-card .section-card__shell {
    max-width: 1099px; }
    @media (max-width: 767px) {
      .section-card .section-card__shell {
        padding: 0 50px; } }
  .section-card .section-card__head {
    margin-bottom: 84px;
    text-align: center; }
  .section-card .section-card__head h1 {
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    line-height: 1.2;
    color: #fff;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .section-card .section-card__head h1 {
        font-family: "Gotham";
        font-weight: 900;
        font-size: 18px; } }
  .section-card--secondary {
    position: relative;
    padding: 96px 0;
    box-shadow: inset 0 -25px 25px -25px rgba(0, 0, 0, 0.48); }
    @media (max-width: 767px) {
      .section-card--secondary {
        padding: 74px 0 66px;
        box-shadow: none; } }
    .section-card--secondary:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 10;
      display: none;
      width: 100%;
      height: 108px;
      margin-bottom: -1px;
      background-image: url(../images/section-red-bottom-wave@2x.png);
      background-position: left center;
      background-repeat: repeat-x;
      background-size: 618px 108px;
      pointer-events: none; }
      @media (max-width: 767px) {
        .section-card--secondary:after {
          display: block; } }
    .section-card--secondary .section-card__shell {
      max-width: 1090px; }
  .section-card--tertiary {
    padding: 179px 0 53px; }
    @media (max-width: 767px) {
      .section-card--tertiary {
        padding: 86px 0 49px; } }
    .section-card--tertiary .section-card__shell {
      max-width: 1090px; }
    @media (max-width: 767px) {
      .section-card--tertiary .section-card__head {
        margin-bottom: 44px; } }

/* ------------------------------------------------------------ *\
  Section features alt
\* ------------------------------------------------------------ */
.section-features-alt {
  top: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(var(--vh, 1vh) * 100) !important;
  background-position: calc(50% + 39px) -110px;
  background-repeat: no-repeat;
  background-size: 410px 844px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 87px;
  position: relative;
  transition: background-color 0.3s;
  /* Mobile */ }
  @media (max-width: 767px) {
    .section-features-alt {
      background-position: calc(50% + 11px) -18px;
      background-size: 152px 312px;
      padding-top: 236px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 56px; } }
  .section-features-alt .section__aside-text {
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 300 !important;
    font-size: 20px;
    line-height: 1;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    white-space: nowrap;
    color: #fff;
    transform: rotate(-90deg);
    position: absolute;
    top: 365px;
    left: -106px;
    /* Small Desktop */
    /* Mobile */ }
    @media (max-width: 1200px) {
      .section-features-alt .section__aside-text {
        top: 325px; } }
    @media (max-width: 1023px) {
      .section-features-alt .section__aside-text {
        top: 325px;
        left: -160px; } }
    @media (max-width: 767px) {
      .section-features-alt .section__aside-text {
        display: none; } }
  .section-features-alt .section__content {
    margin: 0 auto;
    max-width: 927px;
    /* Tablet Portrait */
    /* Mobile */ }
    @media (max-width: 1023px) {
      .section-features-alt .section__content {
        padding: 0 30px; } }
    @media (max-width: 767px) {
      .section-features-alt .section__content {
        padding: 0;
        max-width: 281px; } }

/* ------------------------------------------------------------ *\
	Section Features
\* ------------------------------------------------------------ */
.section-features {
  padding: 229px 0 82px; }
  @media (max-width: 767px) {
    .section-features {
      padding: 160px 0 119px; } }
  .section-features .section-features__shell {
    max-width: 1115px; }
    @media (max-width: 767px) {
      .section-features .section-features__shell {
        padding: 0 30px; } }
  .section-features .section-features__head {
    margin-bottom: 70px;
    text-align: center; }
    @media (max-width: 767px) {
      .section-features .section-features__head {
        margin-bottom: 53px; } }
  .section-features .section-features__head h1 {
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    color: #fff;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .section-features .section-features__head h1 {
        font-size: 38px; } }

/* ------------------------------------------------------------ *\
	Section Image
\* ------------------------------------------------------------ */
.section-image {
  background-color: #ffa800; }
  .section-image .shell-image__shell {
    position: relative;
    top: -1px;
    height: calc(var(--vh, 1vh) * 51.3);
    min-height: 519px; }
    @media (max-width: 767px) {
      .section-image .shell-image__shell {
        min-height: 0; } }
  .section-image .section-image__inner {
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform: translateX(-50%); }

/* ------------------------------------------------------------ *\
	Section Info
\* ------------------------------------------------------------ */
.section-info {
  position: relative;
  padding: 111px 0 168px;
  font-weight: 300;
  font-size: 20px;
  color: #fff;
  line-height: 1.8; }
  @media (max-width: 767px) {
    .section-info {
      padding: 30px 0 122px;
      font-size: 15px;
      line-height: 1.5; } }
  .section-info:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 190px;
    margin-bottom: -1px;
    background-image: url(../images/section-yellow-top-wave@2x.png);
    background-position: left center;
    background-repeat: repeat-x;
    background-size: 1380px 190px;
    pointer-events: none; }
    @media (max-width: 767px) {
      .section-info:before {
        height: 124px;
        background-position: 38% center;
        background-size: 900px 124px; } }
  @media (max-width: 767px) {
    .section-info .section-info__shell {
      padding: 0 50px; } }
  .section-info .section-info__inner {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -25px; }
    @media (max-width: 767px) {
      .section-info .section-info__inner {
        display: block;
        margin: 0; } }
  .section-info .section-info__aside {
    flex: 0 0 35.7%;
    max-width: 35.7%;
    padding: 13px 25px;
    text-align: right; }
    @media (max-width: 767px) {
      .section-info .section-info__aside {
        display: flex;
        align-items: center;
        max-width: 100%;
        padding: 0;
        margin-bottom: 10px;
        text-align: center; } }
  .section-info .section-info__aside h2 {
    margin-bottom: 10px;
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    line-height: 1.2;
    letter-spacing: .04em;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .section-info .section-info__aside h2 {
        padding: 17px 9px 0;
        margin-bottom: 0;
        font-size: 28px; } }
  .section-info .section-info__aside img {
    margin-right: 9px;
    max-width: 147px; }
    @media (max-width: 767px) {
      .section-info .section-info__aside img {
        order: -5;
        max-width: 80px; } }
  .section-info .section-info__content {
    flex: 1 0;
    padding: 7px 25px; }
    @media (max-width: 767px) {
      .section-info .section-info__content {
        padding: 0; } }
  .section-info .section-info__image {
    position: absolute;
    bottom: -183px;
    left: 0;
    width: 1159px;
    height: 481px;
    background-size: cover;
    background-position: center;
    pointer-events: none; }
    @media (max-width: 1200px) {
      .section-info .section-info__image {
        width: 900px;
        height: 374px; } }
    @media (max-width: 767px) {
      .section-info .section-info__image {
        bottom: -87px;
        left: -153px;
        width: 551px;
        height: 228px; } }

/* ------------------------------------------------------------ *\
	Section Made
\* ------------------------------------------------------------ */
.section-made .section-made__shell {
  padding: 0; }

.section-made .section-made__inner {
  display: flex; }

.section-made .section-made__content {
  flex: 1 0;
  padding: 163px 104px 170px;
  background-color: #f87a64; }
  @media (max-width: 1200px) {
    .section-made .section-made__content {
      padding: 163px 30px 170px; } }
  @media (max-width: 767px) {
    .section-made .section-made__content {
      padding: 114px 50px 170px; } }
  .section-made .section-made__content > h1 {
    margin-bottom: 34px;
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    color: #fff;
    text-transform: uppercase; }
    @media (max-width: 1023px) {
      .section-made .section-made__content > h1 {
        text-align: center; } }
    @media (max-width: 767px) {
      .section-made .section-made__content > h1 {
        margin-bottom: 22px;
        font-size: 35px;
        line-height: 1.2; } }

.section-made .section-made__image {
  flex: 0 0 41.6%;
  max-width: 41.6%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  @media (max-width: 1023px) {
    .section-made .section-made__image {
      display: none; } }

/* ------------------------------------------------------------ *\
	Section Products
\* ------------------------------------------------------------ */
.section-products .section-products__head {
  margin-bottom: 48px;
  text-align: center; }

.section-products .section-products__head h3 {
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 35px;
  color: #fff;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .section-products .section-products__head h3 {
      font-size: 27px; } }

.section-products .section-products__body {
  padding: 0 26px; }

.section-products .section-products__notice {
  color: #fff;
  text-align: center; }

.section-products .products {
  position: relative;
  z-index: 5; }

.section-products--secondary {
  padding: 95px 0 0;
  margin-bottom: -23px; }
  @media (max-width: 1023px) {
    .section-products--secondary {
      margin-bottom: -43px; } }
  @media (max-width: 767px) {
    .section-products--secondary {
      margin-bottom: -19px; } }

.section-products--tertiary {
  padding: 156px 0 79px; }
  @media (max-width: 767px) {
    .section-products--tertiary {
      padding: 122px 0 79px; } }
  @media (max-width: 767px) {
    .section-products--tertiary .section-products__body {
      padding: 0 52px; } }

.section-products--tabbed {
  padding-top: 68px;
  margin-bottom: -24px; }
  @media (max-width: 767px) {
    .section-products--tabbed {
      margin-bottom: -19px; } }

.section-products--small-slider .slider {
  padding: 0 80px; }
  @media (max-width: 1023px) {
    .section-products--small-slider .slider {
      padding: 0; } }

/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Shopify Challenge
\* ------------------------------------------------------------ */
.shopify-challenge__container {
  max-width: 100% !important;
  padding: 190px 0 100px;
  background-color: #ffa800;
  color: #fff; }
  .shopify-challenge__container > * {
    max-width: 500px;
    margin: 0 auto; }
  .shopify-challenge__container .btn {
    border: 2px solid #fff;
    background: #3a3058;
    min-width: 207px;
    padding: 12px 20px 6px;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    font-size: 17px;
    letter-spacing: .08em;
    text-transform: uppercase;
    transition: background .4s; }
    @media (max-width: 480px) {
      .shopify-challenge__container .btn {
        min-width: 0; } }
    .shopify-challenge__container .btn:hover {
      border-color: #fff;
      background-color: #af79b3;
      color: #fff; }

/* ------------------------------------------------------------ *\
  Slick slider
\* ------------------------------------------------------------ */
.slick-slider {
  z-index: 1; }

/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */
.slider--products {
  position: relative; }
  @media (max-width: 767px) {
    .slider--products {
      margin: 0 28px; } }
  .slider--products .slick-track {
    display: flex; }
  .slider--products .slick-list {
    margin: 0 -10px; }
  .slider--products .slick-slide {
    float: none;
    min-height: 0;
    height: auto;
    padding: 0 10px; }
    .slider--products .slick-slide > div,
    .slider--products .slick-slide .slider__slide,
    .slider--products .slick-slide .product__item {
      height: 100%; }
      @media (max-width: 767px) {
        .slider--products .slick-slide > div,
        .slider--products .slick-slide .slider__slide,
        .slider--products .slick-slide .product__item {
          height: auto; } }
  .slider--products .slider__nav {
    position: absolute;
    top: 50%;
    left: -40px;
    right: -40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 71px;
    pointer-events: none;
    transform: translateY(-50%); }
    @media (max-width: 1023px) {
      .slider--products .slider__nav {
        left: -34px;
        right: -34px; } }
  .slider--products .slider__prev,
  .slider--products .slider__next {
    color: #fff;
    cursor: pointer;
    pointer-events: all;
    transition: color .4s; }
    .slider--products .slider__prev:hover,
    .slider--products .slider__next:hover {
      color: #19709d; }

/* ------------------------------------------------------------ *\
	Stamped Container
\* ------------------------------------------------------------ */
#stamped-main-widget {
  color: #fff; }
  @media (max-width: 767px) {
    #stamped-main-widget {
      margin: 0; } }
  #stamped-main-widget .stamped-summary-actions {
    display: none !important; }
  #stamped-main-widget .stamped-summary {
    display: flex;
    align-items: flex-end;
    margin: 0 -10px; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-summary {
        display: block;
        margin: 0;
        padding: 0 18px; } }
    @media (max-width: 480px) {
      #stamped-main-widget .stamped-summary {
        padding: 0; } }
  #stamped-main-widget .summary-overview {
    float: none !important;
    flex: 1 0;
    width: 100% !important;
    padding: 0 10px;
    margin: 0; }
    @media (max-width: 767px) {
      #stamped-main-widget .summary-overview {
        padding: 0;
        margin: 0 0 35px !important;
        text-align: left; } }
  #stamped-main-widget .stamped-summary-ratings {
    flex: 0 0 61.5%;
    max-width: 61.5%;
    width: 100% !important;
    padding: 0 10px;
    margin: 0 !important;
    border: 0; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-summary-ratings {
        max-width: 100%;
        padding: 0; } }
  #stamped-main-widget .stamped-tab-container {
    display: none !important; }
  #stamped-main-widget .stamped-summary-caption .stamped-summary-text-1 {
    font-family: "Coco Gothic Fat";
    font-weight: 900 !important;
    font-size: 64px !important;
    color: #fff; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-summary-caption .stamped-summary-text-1 {
        margin: 0 17px 0 7px !important; } }
  @media (max-width: 767px) {
    #stamped-main-widget .stamped-summary-caption.stamped-summary-caption-2 {
      display: block !important; } }
  #stamped-main-widget .stamped-summary-starrating i {
    color: #fff !important; }
    #stamped-main-widget .stamped-summary-starrating i:before {
      font-size: 35px !important; }
  #stamped-main-widget .stamped-summary-text {
    padding: 5px 5px 0;
    font-family: "Gotham";
    font-weight: 300;
    font-size: 22px;
    color: #fff; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-summary-text {
        display: block;
        text-align: left;
        padding-top: 6px; } }
  #stamped-main-widget .summary-rating {
    display: flex;
    align-items: center;
    margin: 0 -11px 9px; }
    @media (max-width: 767px) {
      #stamped-main-widget .summary-rating {
        margin: 0 -7px 7px; } }
  #stamped-main-widget .summary-rating-title {
    flex: 0 0 127px;
    max-width: 127px;
    width: 127px !important;
    padding: 0 11px; }
    @media (max-width: 767px) {
      #stamped-main-widget .summary-rating-title {
        flex: 0 0 108px;
        max-width: 108px;
        width: 108px !important;
        padding: 0 7px;
        text-align: left; } }
    #stamped-main-widget .summary-rating-title:before {
      font-size: 22px !important;
      color: #fff; }
      @media (max-width: 767px) {
        #stamped-main-widget .summary-rating-title:before {
          display: inline-block;
          margin: -4px 0 0 8px;
          font-size: 15px !important;
          letter-spacing: .1em; } }
  #stamped-main-widget .summary-rating-bar {
    flex: 1 0;
    margin: 0 11px;
    width: 100% !important;
    height: 21px !important;
    border: 1px solid #fff; }
    @media (max-width: 767px) {
      #stamped-main-widget .summary-rating-bar {
        height: 15px !important;
        margin: 0 7px; } }
    #stamped-main-widget .summary-rating-bar .summary-rating-bar-content {
      height: 100%;
      background-color: #ee683a; }
  #stamped-main-widget .summary-rating-count {
    width: auto !important;
    min-width: 81px;
    padding: 0 11px;
    font-family: "Gotham";
    font-weight: 300;
    font-size: 21px;
    color: #fff !important;
    text-align: left !important; }
    @media (max-width: 767px) {
      #stamped-main-widget .summary-rating-count {
        min-width: 62px;
        padding: 0 7px;
        font-size: 16px; } }
  #stamped-main-widget .stamped-header {
    margin-bottom: 47px !important; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-header {
        margin-bottom: 40px !important; } }
  #stamped-main-widget .stamped-reviews-filter {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    padding-bottom: 29px;
    border-bottom: 0; }
  #stamped-main-widget .stamped-reviews-filter-label {
    display: none; }
  #stamped-main-widget .stamped-reviews-search-text {
    flex: 0 0 67%;
    max-width: 67%;
    margin: 0;
    order: -5; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-reviews-search-text {
        flex: 0 0 59%;
        max-width: 59%; } }
  #stamped-main-widget .stamped-reviews-search-clear {
    color: #fff; }
  #stamped-main-widget .stamped-summary-actions-clear {
    flex: 0 0 100%;
    max-width: 100%;
    order: 5;
    border: 0;
    color: #fff; }
  #stamped-main-widget .stamped-sort-select-wrapper {
    flex: 1 0; }
  #stamped-main-widget .stamped-sort-select {
    width: 100%;
    height: 57px;
    padding: 6px 60px 1px 22px !important;
    margin: 0;
    border-radius: 0 !important;
    border: solid #fff !important;
    border-width: 3px 4px 3px 2px !important;
    background-color: #ffa800;
    background-image: url(https://cdn.shopify.com/s/files/1/0111/7532/9850/t/26/assets/ico-arrow-down.svg?v=232818860571541522) !important;
    background-size: 29px 29px !important;
    background-position: right 19px center;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    appearance: none; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-sort-select {
        height: 43px;
        padding: 6px 49px 1px 11px !important;
        font-size: 14px;
        background-size: 23px 23px !important; } }
  #stamped-main-widget .stamped-reviews-search-input {
    width: 100%;
    height: 57px;
    padding: 5px 51px 0 !important;
    border-radius: 0 !important;
    border: solid #fff !important;
    border-width: 3px 2px 3px 3px !important;
    background-color: transparent;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-reviews-search-input {
        height: 43px;
        padding: 5px 10px 0 47px !important;
        font-size: 15px; } }
    @media (max-width: 480px) {
      #stamped-main-widget .stamped-reviews-search-input {
        padding: 5px 5px 0 30px !important; } }
  #stamped-main-widget .stamped-reviews-search-icon {
    top: 50%;
    left: 13px;
    font-size: 36px;
    color: #fff;
    transform: translateY(-50%); }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-reviews-search-icon {
        left: 24px;
        font-size: 27px; } }
    @media (max-width: 480px) {
      #stamped-main-widget .stamped-reviews-search-icon {
        left: 7px; } }
  #stamped-main-widget .stamped-review {
    padding-top: 30px;
    border-top: 0; }
  #stamped-main-widget .stamped-review-header {
    position: relative;
    padding: 0 10px;
    margin-bottom: 24px; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review-header {
        margin-bottom: 14px; } }
    @media (max-width: 480px) {
      #stamped-main-widget .stamped-review-header {
        margin-bottom: 30px; } }
  #stamped-main-widget .stamped-review-header .created {
    display: inline-block;
    margin: 4px -9px 0 0;
    font-family: "Gotham";
    font-weight: 500;
    font-size: 16px;
    color: #2582be; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review-header .created {
        margin: 4px 3px 0 0;
        font-size: 10px; } }
  #stamped-main-widget .stamped-review-header .review-location {
    margin-top: 9px;
    font-family: "Gotham";
    font-weight: 500;
    font-size: 20px;
    color: #fff; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review-header .review-location {
        margin-top: 4px;
        font-size: 16px; } }
  #stamped-main-widget .stamped-review .author {
    display: inline-block;
    margin: 5px 7px 0 0;
    font-family: "Gotham";
    font-weight: 500;
    font-size: 20px;
    color: #fff; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review .author {
        margin: 1px 7px 0 0;
        font-size: 16px; } }
  #stamped-main-widget .stamped-verified-badge {
    margin-left: 5px;
    font-family: "Gotham";
    font-weight: 500;
    font-size: 20px;
    color: #2582be; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-verified-badge {
        margin-left: 2px;
        font-size: 16px; } }
    @media (max-width: 480px) {
      #stamped-main-widget .stamped-verified-badge {
        display: block; } }
  #stamped-main-widget .stamped-review-avatar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #2582be; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review-avatar {
        width: 52px;
        height: 52px; } }
    #stamped-main-widget .stamped-review-avatar:before {
      content: '';
      z-index: 5;
      display: inline-block;
      width: 27px;
      height: 21px;
      background-image: url(https://cdn.shopify.com/s/files/1/0111/7532/9850/files/ico-check_2x_1db2bbec-6f65-4dec-bb7e-a7d00d5eaa20.png?v=1595338370);
      background-size: cover;
      background-position: center; }
      @media (max-width: 767px) {
        #stamped-main-widget .stamped-review-avatar:before {
          width: 21px;
          height: 16px; } }
  #stamped-main-widget .stamped-review-avatar img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 50%; }
  #stamped-main-widget .stamped-review-avatar-content {
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0;
    font-family: "CocoGothicSmallCaps";
    font-weight: 700;
    font-style: normal;
    font-size: 32px;
    color: #fff; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review-avatar-content {
        font-size: 26px; } }
  #stamped-main-widget .stamped-review-header-starratings {
    position: absolute;
    top: 100%;
    left: 0;
    margin: 33px 0 0 3px; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review-header-starratings {
        margin: 22px 0 0 3px; } }
    @media (max-width: 480px) {
      #stamped-main-widget .stamped-review-header-starratings {
        margin: 10px 0 0 3px; } }
  #stamped-main-widget .stamped-review-header-starratings .stamped-fa {
    margin-left: 4px;
    color: #fff; }
    #stamped-main-widget .stamped-review-header-starratings .stamped-fa:before {
      font-size: 21px; }
      @media (max-width: 767px) {
        #stamped-main-widget .stamped-review-header-starratings .stamped-fa:before {
          font-size: 16px; } }
  #stamped-main-widget .stamped-review-header-title {
    padding: 0 0 0 137px;
    margin-bottom: 15px;
    font-family: "Coco Gothic Fat";
    font-weight: 900;
    font-size: 27px;
    color: #fff;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review-header-title {
        padding: 0 0 0 105px;
        margin-bottom: 10px;
        font-size: 21px; } }
    @media (max-width: 480px) {
      #stamped-main-widget .stamped-review-header-title {
        padding: 0; } }
  #stamped-main-widget .stamped-review-content {
    padding-left: 5px; }
  #stamped-main-widget .stamped-review-content-body {
    font-family: "Gotham";
    font-weight: 300;
    font-size: 20px;
    line-height: 1.4;
    color: #fff; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review-content-body {
        font-size: 15px;
        line-height: 1.55; } }
  #stamped-main-widget .stamped-review-product {
    display: none !important; }
  #stamped-main-widget .stamped-review-variant {
    display: none !important; }
  #stamped-main-widget .stamped-review-share {
    font-family: "Gotham";
    font-weight: 500;
    font-size: 16px;
    color: #2582be; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review-share {
        margin-right: 16px;
        font-size: 13px; } }
    @media (max-width: 480px) {
      #stamped-main-widget .stamped-review-share {
        float: none !important; } }
  #stamped-main-widget .stamped-share-icon {
    position: relative;
    padding-left: 23px; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-share-icon {
        padding-left: 17px; } }
    #stamped-main-widget .stamped-share-icon:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-block;
      width: 16px;
      height: 13px;
      background-image: url(../images/ico-share@2x.png);
      background-position: center;
      background-size: cover;
      transform: translateY(-50%); }
      @media (max-width: 767px) {
        #stamped-main-widget .stamped-share-icon:before {
          width: 12px;
          height: 10px; } }
  #stamped-main-widget .stamped-share-icon i {
    display: none; }
  #stamped-main-widget .stamped-review-vote {
    font-family: "Gotham";
    font-weight: 500;
    font-size: 16px;
    color: #2582be; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review-vote {
        float: none !important;
        font-size: 13px; } }
  #stamped-main-widget .stamped-review-vote .stamped-rating-holder {
    display: flex;
    align-items: center; }
  #stamped-main-widget .stamped-review-vote .stamped-thumbs-up {
    display: inline-block;
    width: 18px;
    height: 20px;
    margin: 0 0 4px 35px;
    background-image: url(../images/ico-thumbs-up@2x.png);
    background-position: center;
    background-size: cover; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review-vote .stamped-thumbs-up {
        width: 14px;
        height: 15px;
        margin: 0 0 4px 26px; } }
  #stamped-main-widget .stamped-review-vote .stamped-thumbs-up i {
    display: none; }
  #stamped-main-widget .stamped-review-vote .stamped-thumbs-down {
    display: inline-block;
    width: 18px;
    height: 20px;
    margin: 0 0 4px 3px;
    background-image: url(../images/ico-thumbs-down@2x.png);
    background-position: center;
    background-size: cover; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review-vote .stamped-thumbs-down {
        width: 14px;
        height: 15px;
        margin: 0 0 4px 2px; } }
  #stamped-main-widget .stamped-review-vote .stamped-thumbs-down i {
    display: none; }
  #stamped-main-widget .stamped-review-footer {
    padding-left: 8px;
    margin-top: 13px; }
    @media (max-width: 767px) {
      #stamped-main-widget .stamped-review-footer {
        padding-left: 4px;
        margin-top: 18px; } }
  #stamped-main-widget .stamped-pagination {
    margin-top: 30px;
    color: #fff; }

/* ------------------------------------------------------------ *\
	Store Locator
\* ------------------------------------------------------------ */
#store-locator-title {
  display: none; }

.search_bar {
  position: relative;
  top: -152px;
  width: 33.6%;
  display: flex;
  padding: 0 !important;
  margin-left: 8.2%;
  border: 4px solid #fff !important;
  background: transparent !important; }
  @media (max-width: 1023px) {
    .search_bar {
      flex: 0 0 calc(100% - 98px) !important;
      max-width: calc(100% - 98px) !important;
      width: calc(100% - 98px);
      margin: 0 49px; } }
  @media (max-width: 480px) {
    .search_bar {
      flex: 0 0 calc(100% - 40px) !important;
      max-width: calc(100% - 40px) !important;
      width: calc(100% - 40px);
      margin: 0 20px; } }

.main_search_bar {
  flex: 1 0;
  float: none !important;
  position: relative;
  width: 100% !important;
  margin: 0 !important; }
  .main_search_bar .main_search_label {
    position: absolute;
    top: 50%;
    left: 15px;
    display: inline-block;
    width: 25px;
    height: 25px;
    background-image: url(https://cdn.shopify.com/s/files/1/0111/7532/9850/files/search_2x_03d14102-ab21-44f8-8804-c284dbdf8bdd.png?v=1595926840);
    background-size: cover;
    background-position: center;
    font-size: 0;
    transform: translateY(-50%); }
    @media (max-width: 767px) {
      .main_search_bar .main_search_label {
        left: 9px;
        width: 16px;
        height: 16px; } }
  .main_search_bar input {
    width: 100% !important;
    height: 53px;
    margin: 0;
    padding: 6px 15px 0 52px;
    border: 0;
    background: transparent;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .main_search_bar input {
        height: 33px;
        padding: 6px 15px 0 36px;
        font-size: 14px; } }

.search_within_distance {
  display: none; }

.search_limit {
  display: none; }

#store_map {
  top: -223px;
  width: 41.3% !important;
  height: 643px !important;
  margin-right: 8.7%;
  border: 0 !important; }
  @media (max-width: 1023px) {
    #store_map {
      order: 10;
      float: none !important;
      top: -85px;
      flex: 0 0 calc(100% - 98px) !important;
      max-width: calc(100% - 98px) !important;
      width: calc(100% - 98px);
      margin: 0 49px; } }
  @media (max-width: 767px) {
    #store_map {
      top: -158px; } }
  @media (max-width: 480px) {
    #store_map {
      flex: 0 0 calc(100% - 40px) !important;
      max-width: calc(100% - 40px) !important;
      width: calc(100% - 40px);
      margin: 0 20px; } }

#submitBtn {
  float: none !important;
  background: transparent;
  padding: 17px 9px 13px !important;
  margin: 0 !important;
  border: 0;
  border-left: 2px solid #fff;
  border-radius: 0;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  text-shadow: none;
  box-shadow: none; }
  @media (max-width: 767px) {
    #submitBtn {
      width: auto;
      height: auto;
      padding: 10px 6px 7px !important;
      font-size: 14px; } }

.header_html {
  position: relative; }
  @media (max-width: 1023px) {
    .header_html {
      order: 0; } }
  .header_html:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -5;
    width: 100vw;
    height: 200vh;
    background-color: #ffa800; }

#col-main .addresses {
  position: relative;
  top: -149px;
  height: 569px !important;
  padding-right: 0 !important;
  margin-left: 10%; }
  @media (max-width: 1023px) {
    #col-main .addresses {
      order: 5;
      top: -130px;
      flex: 0 0 calc(100% - 98px) !important;
      max-width: calc(100% - 98px) !important;
      width: calc(100% - 98px);
      height: auto !important;
      max-height: 569px !important;
      min-height: 138px;
      margin: 0 49px; } }
  @media (max-width: 767px) {
    #col-main .addresses {
      top: -174px; } }
  @media (max-width: 480px) {
    #col-main .addresses {
      flex: 0 0 calc(100% - 40px) !important;
      max-width: calc(100% - 40px) !important;
      width: calc(100% - 40px);
      margin: 0 20px; } }
  #col-main .addresses li {
    min-height: 138px !important;
    padding: 15px 5px 15px 99px !important;
    border-top: 0 !important;
    background: url(https://cdn.shopify.com/s/files/1/0111/7532/9850/files/map-pin_2x_e6db4a77-320c-4a67-a0fa-fba4bdd083cd.png?v=1595934057) !important;
    background-repeat: no-repeat !important;
    background-size: 67px 95px !important;
    background-position: 0 22px !important;
    font-family: "Coco Gothic" !important;
    font-weight: 900 !important;
    font-size: 15px !important;
    line-height: 1.45 !important;
    color: #fff; }
    @media (max-width: 767px) {
      #col-main .addresses li {
        padding: 15px 5px 15px 86px !important;
        background-size: 48px 66px !important;
        background-position: 16px 19px !important;
        font-size: 11px !important; } }
  @media (max-width: 1023px) {
    #col-main .addresses a {
      width: 100% !important; } }
  #col-main .addresses .no_stores_found {
    display: flex;
    align-items: center;
    min-height: 108px;
    margin: 0 !important; }
    @media (max-width: 767px) {
      #col-main .addresses .no_stores_found {
        min-height: 72px; } }
  #col-main .addresses .name {
    display: block;
    font-family: "Gotham";
    font-weight: 900;
    font-weight: 900 !important;
    font-size: 25px;
    color: #fff !important;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      #col-main .addresses .name {
        font-size: 16px; } }
  #col-main .addresses .address,
  #col-main .addresses .city,
  #col-main .addresses .prov_state,
  #col-main .addresses .postal_zip,
  #col-main .addresses .phone {
    color: inherit; }
  #col-main .addresses .phone {
    font-size: 25px; }
    @media (max-width: 767px) {
      #col-main .addresses .phone {
        font-size: 20px; } }
  #col-main .addresses .directions {
    display: none; }
  #col-main .addresses .store_website {
    display: block; }
    #col-main .addresses .store_website a {
      display: block;
      margin: 0; }
  #col-main .addresses .distance {
    display: none; }
  #col-main .addresses .store_website {
    font-size: 20px;
    color: #e94442;
    text-transform: uppercase; }

@media (max-width: 1023px) {
  #col-main {
    display: flex;
    flex-flow: row wrap; }
    #col-main > * {
      flex: 0 0 100%;
      max-width: 100%; } }

/* ------------------------------------------------------------ *\
	Subscribe
\* ------------------------------------------------------------ */
.subscribe .subscribe__shell {
  padding: 0; }

.subscribe .subscribe__inner {
  display: flex;
  flex-flow: row wrap; }

.subscribe .subscribe__image {
  flex: 1 0;
  background-position: center;
  background-size: cover; }
  @media (max-width: 767px) {
    .subscribe .subscribe__image {
      order: 5;
      flex: 0 0 100%;
      max-width: 100%;
      padding-top: 120%; } }

.subscribe .subscribe__content {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 108px 109px 165px;
  background-color: #3a3058; }
  @media (max-width: 1200px) {
    .subscribe .subscribe__content {
      padding-left: 80px;
      padding-right: 80px; } }
  @media (max-width: 1023px) {
    .subscribe .subscribe__content {
      padding: 100px 40px 165px; } }
  @media (max-width: 767px) {
    .subscribe .subscribe__content {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 68px 44px 93px; } }

.subscribe .subscribe__entry {
  margin-bottom: 70px;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 45px;
  line-height: 1.03;
  color: #fff;
  text-transform: uppercase; }
  @media (max-width: 1023px) {
    .subscribe .subscribe__entry {
      font-size: 35px; } }
  @media (max-width: 767px) {
    .subscribe .subscribe__entry {
      margin-bottom: 35px;
      font-size: 29px; } }

.subscribe .subscribe__entry p {
  margin: 0; }

/* ------------------------------------------------------------ *\
  Table products
\* ------------------------------------------------------------ */
.table-products td {
  vertical-align: top;
  padding: 15px 0; }

.table-products td:first-child {
  width: 25%; }

.table-products td:first-child,
.table-products td:nth-child(2) {
  padding-left: 30px; }

.table-products td:first-child a {
  display: block;
  padding-left: 30px;
  margin: 20px 0;
  /* Mobile */ }
  @media (max-width: 767px) {
    .table-products td:first-child a {
      padding-left: 0; } }

.table-products td:first-child img {
  margin-bottom: 0; }

.table-products td:nth-child(2) {
  text-align: center;
  width: 48%; }

.table-products td:last-child {
  text-align: center;
  vertical-align: bottom;
  padding-right: 30px; }

.table-products td:last-child a {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-bottom: 9px;
  margin-left: 27px; }

.table-products td:last-child a svg,
.table-products td:last-child a path {
  display: block;
  width: 100%;
  height: 100%; }

.table-products ul {
  list-style-type: none;
  font-size: 15px;
  padding-top: 8px;
  margin: 0; }

.table-products h5 {
  font-weight: 500;
  font-size: 15px;
  padding-top: 26px;
  margin-bottom: 0; }

.table-products h5 a {
  color: #000;
  text-decoration: none;
  transition: color 0.15s; }
  .table-products h5 a:hover {
    color: #9f9f9f; }

.table-products .table-products__item p {
  font-size: 17px;
  margin-bottom: 25px;
  margin-top: 16px; }

@media (max-width: 480px) {
  .table-products table,
  .table-products tbody,
  .table-products tr,
  .table-products td {
    display: block;
    width: 100%; }
  .table-products td:first-child,
  .table-products td:nth-child(2) {
    text-align: center;
    width: 100%;
    padding-left: 0; } }

/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Tabs
\* ------------------------------------------------------------ */
.tabs .tabs__nav {
  margin-bottom: 63px;
  font-family: "Gotham";
  font-weight: 900;
  font-weight: 900;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  text-align: center; }
  @media (max-width: 767px) {
    .tabs .tabs__nav {
      padding: 0 33px;
      margin-bottom: 51px;
      font-size: 12px; } }
  .tabs .tabs__nav ul {
    display: inline-flex;
    justify-content: center;
    flex-flow: row wrap;
    border: solid #fff;
    border-width: 2px 2px 0 0; }
  .tabs .tabs__nav li {
    flex: 1 0 auto;
    border: solid #fff;
    border-width: 0 0 2px 2px; }
  .tabs .tabs__nav a {
    display: inline-block;
    width: 100%;
    padding: 13px 26px 8px;
    text-decoration: none;
    transition: background .4s, color .4s; }
    @media (max-width: 767px) {
      .tabs .tabs__nav a {
        padding: 9px 15px 5px; } }
    .tabs .tabs__nav a:hover {
      background-color: #fff;
      color: #ee683a; }

.tabs .tabs__body {
  position: relative;
  z-index: 1; }

.tabs .tab {
  position: absolute;
  top: 0;
  left: 26px;
  right: 26px;
  z-index: -5;
  opacity: 0;
  pointer-events: none;
  transition: opacity .4s; }
  .tabs .tab.is-current {
    position: relative;
    left: 0;
    right: 0;
    z-index: 0;
    opacity: 1;
    pointer-events: all;
    transition: opacity .4s .4s; }

/* ------------------------------------------------------------ *\
	Template 404
\* ------------------------------------------------------------ */
.template-404 .main {
  padding: 190px 0 100px;
  background-color: #ffa800; }
  @media (max-width: 767px) {
    .template-404 .main {
      padding: 160px 0 100px; } }

/* ------------------------------------------------------------ *\
	Template Article
\* ------------------------------------------------------------ */
.template-article .main {
  padding: 190px 0 100px;
  background-color: #ffa800; }
  @media (max-width: 767px) {
    .template-article .main {
      padding: 160px 0 100px; } }

/* ------------------------------------------------------------ *\
	Template Blog
\* ------------------------------------------------------------ */
.template-blog .main {
  padding: 190px 0 100px;
  background-color: #ffa800; }
  @media (max-width: 767px) {
    .template-blog .main {
      padding: 160px 0 100px; } }

/* ------------------------------------------------------------ *\
	Template Cart
\* ------------------------------------------------------------ */
.template-cart .main {
  padding: 190px 0 100px;
  background-color: #ffa800; }
  @media (max-width: 767px) {
    .template-cart .main {
      padding: 160px 0 100px; } }

/* ------------------------------------------------------------ *\
	Template Collection
\* ------------------------------------------------------------ */
.template-collection .main {
  padding: 190px 0 100px;
  background-color: #ffa800; }
  @media (max-width: 767px) {
    .template-collection .main {
      padding: 160px 0 100px; } }

/* ------------------------------------------------------------ *\
	Template Customer
\* ------------------------------------------------------------ */
.template-customer .main {
  padding: 190px 0 100px;
  background-color: #ffa800; }
  @media (max-width: 767px) {
    .template-customer .main {
      padding: 160px 0 100px; } }

/* ------------------------------------------------------------ *\
	Template Index
\* ------------------------------------------------------------ */
@media (max-width: 767px) {
  .template-index .section-features-alt,
  .template-index .subscribe,
  .template-index .footer {
    opacity: 1 !important;
    transform: none !important; } }

/* ------------------------------------------------------------ *\
	Template List Collections
\* ------------------------------------------------------------ */
.template-list-collections .main {
  padding: 190px 0 100px;
  background-color: #ffa800; }
  @media (max-width: 767px) {
    .template-list-collections .main {
      padding: 160px 0 100px; } }

/* ------------------------------------------------------------ *\
	Template Page
\* ------------------------------------------------------------ */
.template-page .main {
  background-color: #ffa800; }

/* ------------------------------------------------------------ *\
	Template Search
\* ------------------------------------------------------------ */
.template-search .main {
  padding: 190px 0 100px;
  background-color: #ffa800; }
  @media (max-width: 767px) {
    .template-search .main {
      padding: 160px 0 100px; } }

/*  Pages  */
