/* ------------------------------------------------------------ *\
	Product
\* ------------------------------------------------------------ */

.product {
	.product__inner {
		display: flex;
		margin: 0 -10px;

		@media #{$tablet-portrait} {
			display: block;
			margin: 0;
		}
	}

	.product__slider .slick-list {
		margin: 0 -20px;
	}

	.product__slider .slick-slide {
		padding: 0 20px;
	}

	.product__images {
		padding: 0 10px;

		@media #{$tablet-portrait} {
			padding: 0;
			margin-bottom: 50px;
		}

		@media #{$mobile} {
			margin-bottom: 31px;
		}
	}

	.product__thumbs {
		padding-left: 10px;

		@media #{$mobile} {
			padding: 0 39px;
		}
	}

	.product__thumbs .slick-list {
		@media #{$mobile} {
			margin: 0 -6px;
		}
	}

	.product__thumbs .slick-slide {
		padding: 0 9px;

		@media #{$mobile} {
			padding: 0 6px;
		}
	}

	.product__thumbs .slick-slide img {
		width: 59px !important;
		height: 59px;
		border-radius: 50%;
		border: 1px solid $white;

		@media #{$mobile} {
			width: 39px !important;
			height: 39px;
		}
	}

	.product__content {
		flex: 0 0 43.7%;
		max-width: 43.7%;
		padding: 0 10px;
		color: $white;

		@media #{$tablet-portrait} {
			max-width: 100%;
			padding: 0;
		}

		@media #{$mobile} {
			padding: 0 27px 0 38px;
		}
	}

	.product__title {
		font-family: $font-coco-gothic-fat;
		font-weight: 900;
		line-height: 1.4;
		letter-spacing: -.04em;
		text-transform: uppercase;

		@media #{$mobile} {
			line-height: 1.45;
		}

		@media #{$mobile-small} {
			line-height: 1.2;
		}
	}

	h1.product__title {
		@media #{$mobile} {
			font-size: 44px;
		}

		@media #{$mobile-small} {
			font-size: 25px;
		}
	}

	.product__title small {
		display: block;
		@include font-fix();
		font-weight: 900;
		font-size: 50%;
		letter-spacing: -.03em;
	}

	.product__entry {
		font-size: 20px;
		line-height: 1.2;
		letter-spacing: -.04em;
		color: inherit;

		@media #{$mobile} {
			font-size: 15px;
		}
	}

	.product__section {
		position: relative;

		&--main {
			position: relative;
			z-index: 15;
			padding: 74px 0 0;

			@media #{$mobile} {
				padding: 27px 0 0;
			}

			.product__inner {
				position: relative;
				bottom: -125px;

				@media #{$mobile} {
					bottom: -93px;
				}
			}
		}

		&--reviews {
			padding: 238px 0 164px;

			@media #{$mobile} {
				padding: 106px 0 80px;
			}
		}

		&--orange {
			background-color: $orange;
		}

		&--red {
			position: relative;
			z-index: 10;
			padding: 260px 0 53px;
			background-color: $red;

			@media #{$tablet-portrait} {
				padding: 200px 0 53px;
			}

			@media #{$mobile} {
				padding: 150px 0 61px;
			}

			&:after {
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				z-index: 10;
				width: 100%;
				height: 87px;
				margin-bottom: -1px;
				background-image: url(../images/product-red-bottom-wave@2x.png);
				background-position: 37% center;
				background-repeat: repeat-x;
				background-size: 1330px 87px;

				@media #{$mobile} {
					height: 40px;
					background-position: 41% center;
					background-size: 605px 40px;
				}
			}
		}

		&--light-red {
			padding: 89px 0 142px;
			background-color: $red2;
		}

		&--purple {
			position: relative;
			z-index: 5;
			padding: 148px 0 154px;
			background-color: $purple2;

			@media #{$mobile} {
				padding: 100px 33px 34px;
			}

			&:after {
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				z-index: 10;
				width: 100%;
				height: 250px;
				background-image: url(../images/alternate-shape_01.png);
				background-position: 24% center;
				background-repeat: repeat-x;
				background-size: 2262px 250px;

				@media #{$mobile} {
					height: 103px;
					background-position: 42% center;
					background-size: 900px 103px;
				}
			}
		}

		&--blue {
			position: relative;
			padding: 239px 0 55px;
			background-color: $blue2;

			@media #{$mobile} {
				padding: 112px 0 55px;
			}

			&:after {
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				z-index: 10;
				width: 100%;
				height: 151px;
				background-image: url(../images/product-blue-bottom-wave@2x.png);
				background-position: left center;
				background-repeat: repeat-x;
				background-size: 1565px 151px;

				@media #{$mobile} {
					height: 58px;
					background-size: 600px 58px;
				}
			}
		}
	}
}
