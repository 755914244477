/* ------------------------------------------------------------ *\
	Features
\* ------------------------------------------------------------ */

.features {
	.features__items {
		display: flex;
		flex-flow: row wrap;
		padding: 0 6px 0 10px;
		margin: 0 -10px;

		@media #{$mobile} {
			padding: 0;
		}
	}

	.features__item {
		flex: 0 0 25%;
		max-width: 25%;
		padding: 0 10px;

		@media #{$tablet-portrait} {
			flex: 0 0 50%;
			max-width: 50%;
			margin-bottom: 40px;
		}

		@media #{$mobile} {
			margin-bottom: 18px;
		}
	}

	img {
		max-width: 80px; 
	}
}
