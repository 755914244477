/* ------------------------------------------------------------ *\
	Template Index
\* ------------------------------------------------------------ */

.template-index {
	.section-features-alt,
	.subscribe,
	.footer {
		@media #{$mobile} {
			opacity: 1 !important;
		    transform: none !important;
		}
	}
}
