/* ------------------------------------------------------------ *\
	Collection
\* ------------------------------------------------------------ */

.collection {
	&--default {
		.collection__title {
			font-family: $font-coco-gothic-fat;
			font-weight: 900;
			color: $white;
			text-transform: uppercase;

			@media #{$mobile} {
				font-size: 35px;
			}
		}

		.collection__description {
			font-size: 20px;
			color: $white;
			line-height: 1.5;
		}

		.collection__sort {
			display: flex;
			align-items: center;
		}

		.select {
			position: relative;
			padding: 0;
			border: 0;
			min-width: 0;
			max-width: 310px;
			border: 2px solid $white;

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				right: 13px;
				width: 19px;
				height: 11px;
				background-image: url(../images/ico-arrow-down.svg);
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				transform: translateY(-50%);
				pointer-events: none;
			}
		}

		.select select {
			width: 100%;
			max-width: 100%;
			height: 59px;
			padding: 0 40px 0 12px;
			border: 0;
			background-color: $orange;
			@include font-fix();
			font-weight: 900;
			font-size: 23px;
			color: $white;
			text-transform: uppercase;
			appearance: none;

			&::-ms-expand {
				display: none;
			}
		}
	}
}
