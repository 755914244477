.paging--default {
	margin-bottom: 90px;
	padding: 30px 0;
	text-align: center;

	ul,
	li {
		display: inline-block;
		vertical-align: middle;
		margin: 10px;
	}

	li {
		a,
		&.current span {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 50px;
			height: 50px;
			font-size: 14px;
			line-height: 1;
			padding: 15px 0;
			text-align: center;
			border: 1px solid $black;
		}

		&.current span,
		&:hover a {
			border-color: $white;
			color: $white;
		}
	}

	a {
		text-decoration: none;
	}

	.paging-prev,
	.paging-next {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		font-size: 0;
		width: 50px;
		height: 50px;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 10px;
		}
	}

	& > span {
		color: $black;
		opacity: .7;
	}

	.paging-prev:after {
		content: '\25C0';
	}

	.paging-next:after {
		content: '\25B6';
	}

	@media #{$mobile-small} {
		ul,
		li {
			margin: 2px;
		}

		li {
			a,
			&.current span {
				width: 36px;
				height: 36px;
				font-size: 12px;
				padding: 9px 0;
			}
		}

		.paging-prev,
		.paging-next {
			width: 36px;
			height: 36px;
		}
	}
}
