/* ------------------------------------------------------------ *\
	Section Info
\* ------------------------------------------------------------ */

.section-info {
	position: relative;
	padding: 111px 0 168px;
	font-weight: 300;
	font-size: 20px;
	color: $white;
	line-height: 1.8;

	@media #{$mobile} {
		padding: 30px 0 122px;
		font-size: 15px;
		line-height: 1.5;
	}

	&:before {
		content: '';
		position: absolute;
		bottom: 100%;
		left: 0;
		z-index: 10;
		width: 100%;
		height: 190px;
		margin-bottom: -1px;
		background-image: url(../images/section-yellow-top-wave@2x.png);
		background-position: left center;
		background-repeat: repeat-x;
		background-size: 1380px 190px;
		pointer-events: none;

		@media #{$mobile} {
			height: 124px;
			background-position: 38% center;
			background-size: 900px 124px;
		}
	}

	.section-info__shell {
		@media #{$mobile} {
			padding: 0 50px;
		}
	}

	.section-info__inner {
		display: flex;
		flex-flow: row wrap;
		margin: 0 -25px;

		@media #{$mobile} {
			display: block;
			margin: 0;
		}
	}

	.section-info__aside {
		flex: 0 0 35.7%;
		max-width: 35.7%;
		padding: 13px 25px;
		text-align: right;

		@media #{$mobile} {
			display: flex;
			align-items: center;
			max-width: 100%;
			padding: 0;
			margin-bottom: 10px;
			text-align: center;
		}
	}

	.section-info__aside h2 {
		margin-bottom: 10px;
		font-family: $font-coco-gothic-fat;
		font-weight: 900;
		line-height: 1.2;
		letter-spacing: .04em;
		text-transform: uppercase;

		@media #{$mobile} {
			padding: 17px 9px 0;
			margin-bottom: 0;
			font-size: 28px;
		}
	}

	.section-info__aside img {
		margin-right: 9px;
		max-width: 147px;

		@media #{$mobile} {
			order: -5;
			max-width: 80px;
		}
	}

	.section-info__content {
		flex: 1 0;
		padding: 7px 25px;

		@media #{$mobile} {
			padding: 0;
		}
	}

	.section-info__image {
		position: absolute;
		bottom: -183px;
		left: 0;
		width: 1159px;
		height: 481px;
		background-size: cover;
		background-position: center;
		pointer-events: none;

		@media #{$small-desktop} {
			width: 900px;
			height: 374px;
		}

		@media #{$mobile} {
			bottom: -87px;
			left: -153px;
			width: 551px;
			height: 228px;
		}
	}
}
