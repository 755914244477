/* ------------------------------------------------------------ *\
	Manage Subscription Page
\* ------------------------------------------------------------ */

.manage-subscription-page {
	color: $white;

	> h3 {
		font-family: $font-coco-gothic-fat;
		font-weight: 900;
		text-align: center;
		text-transform: uppercase;
	}

	> p {
		margin-bottom: 40px;
		text-align: center;
	}

	.no-subscriptions {
		border: 2px solid $white;
		border-radius: 0;
		background-color: transparent;
	}
}
