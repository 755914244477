.collection--default {
  .collection__head {
    text-align: center;
    margin-bottom: 25px;

    &--image {
      min-height: 14%;
      padding: 3.5% 0;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      color: #fff;
    }
  }

  .collection__sort {
    margin-bottom: 90px;

    label {
      color: $white;
      margin-right: 30px;
    }

    select {
      display: inline-block;
      max-width: 310px;
    }
  }
}

.collections--default {
  .collection__head {
    text-align: center;
    margin-bottom: 80px;
  }

  .collection__items {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -2.5%;
    overflow: hidden;

    & > * {
      flex: 0 0 28.33%;
      max-width: 28.33%;
      margin: 0 2.5% 60px;
    }
  }

  .collection__item {
    text-align: center;

    a {
      text-decoration: none;
    }

    .collection__item {
      &-link {
        position: relative;
        display: block;
        padding-top: 100%;
        margin-bottom: 15px;
      }

      &-image {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}
