/* ------------------------------------------------------------ *\
	Section Card
\* ------------------------------------------------------------ */

.section-card {
	padding: 258px 0 72px;

	@media #{$mobile} {
		padding: 131px 0 30px;
	}

	.section-card__shell {
		max-width: 1099px;

		@media #{$mobile} {
			padding: 0 50px;
		}
	}

	.section-card__head {
		margin-bottom: 84px;
		text-align: center;
	}

	.section-card__head h1 {
		font-family: $font-coco-gothic-fat;
		font-weight: 900;
		line-height: 1.2;
		color: $white;
		text-transform: uppercase;

		@media #{$mobile} {
			@include font-fix();
			font-size: 18px;
		}
	}

	&--secondary {
		position: relative;
		padding: 96px 0;
		box-shadow: inset 0 -25px 25px -25px rgba($black, .48);

		@media #{$mobile} {
			padding: 74px 0 66px;
			box-shadow: none;
		}

		&:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			z-index: 10;
			display: none;
			width: 100%;
			height: 108px;
			margin-bottom: -1px;
			background-image: url(../images/section-red-bottom-wave@2x.png);
			background-position: left center;
			background-repeat: repeat-x;
			background-size: 618px 108px;
			pointer-events: none;

			@media #{$mobile} {
				display: block;
			}
		}

		.section-card__shell {
			max-width: 1090px;
		}
	}

	&--tertiary {
		padding: 179px 0 53px;

		@media #{$mobile} {
			padding: 86px 0 49px;
		}

		.section-card__shell {
			max-width: 1090px;
		}

		.section-card__head {
			@media #{$mobile} {
				margin-bottom: 44px;
			}
		}
	}
}
