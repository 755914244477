/* ------------------------------------------------------------ *\
	Page
\* ------------------------------------------------------------ */

.page {
	.page__section {
		&--pull {
			margin-bottom: -116px;

			.section-products {
				position: relative;
				top: -199px;
			}

			@media #{$mobile} {
				margin-bottom: -156px;
			}
		}

		&--yellow {
			background-color: $orange;
		}

		&--orange {
			background-color: $orange2;
		}

		&--red {
			background-color: $red;
		}

		&--light-red {
			background-color: $red2;
		}

		&--purple {
			background-color: $purple2;
		}

		&--blue {
			background-color: $blue2;
		}

		&--blue-wave {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				z-index: 10;
				width: 100%;
				height: 209px;
				background-image: url(../images/section-blue-bottom-wave@2x.png);
				background-position: top center;
				background-repeat: no-repeat;
				background-size: cover;

				@media #{$mobile} {
					height: 92px;
					background-size: 810px 92px;
				}
			}
		}

		&--purple-wave {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				z-index: 10;
				width: 100%;
				height: 140px;
				background-image: url(../images/section-purple-bottom-wave@2x.png);
				background-position: left center;
				background-repeat: repeat-x;
				background-size: 1515px 140px;

				@media #{$mobile} {
					height: 87px;
					background-position: 37% center;
					background-size: 940px 87px;
				}
			}
		}

		&--product-info {
			@media #{$mobile} {
				background-color: $purple2;
			}
		}
	}

	&--error {
		padding: 0 !important;
		color: $white;

		h1 {
			font-family: $font-coco-gothic-fat;
			font-weight: 900;
			text-transform: uppercase;

			@media #{$mobile} {
				font-size: 35px;
			}
		}
	}

	&--default {
		padding: 190px 0 100px;
		color: $white;

		@media #{$mobile} {
			padding: 160px 0 100px;
		}

		.page__head {
			margin-bottom: 40px;
		}

		.page__title {
			font-family: $font-coco-gothic-fat;
			font-weight: 900;
			text-transform: uppercase;

			@media #{$mobile} {
				font-size: 35px;
			}
		}

		.page__content {
			h1 {
				font-size: 50px;

				@media #{$mobile} {
					font-size: 35px;
				}
			}

			h2 {
				@media #{$mobile} {
					font-size: 30px;
				}
			}

			h3 {
				@media #{$mobile} {
					font-size: 25px;
				}
			}

			h4 {
				@media #{$mobile} {
					font-size: 20px;
				}
			}

			h5 {
				@media #{$mobile} {
					font-size: 15px;
				}
			}

			h6 {
				@media #{$mobile} {
					font-size: 12px;
				}
			}

			p {
				margin-bottom: 40px;

				@media #{$mobile} {
					font-size: 15px;
				}
			}
		}
	}
}
