/* ------------------------------------------------------------ *\
	Product Features
\* ------------------------------------------------------------ */

.product-features {
	.product-features__items {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		margin: 0 -12px -20px;

		@media #{$mobile} {
			display: block;
			padding: 0 34px;
			margin: 0;
		}
	}

	.product-features__item {
		flex: 0 0 31.5%;
		max-width: 31.5%;
		padding: 0 12px;
		margin-bottom: 6px;

		@media #{$tablet-portrait} {
			flex: 0 0 50%;
			max-width: 50%;
		}

		@media #{$mobile} {
			max-width: 100%;
			padding: 0;
			margin-bottom: 10px;
		}
	}

	.product-feature__image {
		margin-right: 2px;
	}

	img {
		max-width: 33px;
		max-height: 55px;
		margin-bottom: 0;

		/* Mobile */

		@media #{$mobile} {
			max-width: 100%;
			max-height: 40px;
		}
	}
}
