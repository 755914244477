/* ------------------------------------------------------------ *\
	Subscribe
\* ------------------------------------------------------------ */

.subscribe {
	.subscribe__shell {
		padding: 0;
	}

	.subscribe__inner {
		display: flex;
		flex-flow: row wrap;
	}

	.subscribe__image {
		flex: 1 0;
		background-position: center;
		background-size: cover;

		@media #{$mobile} {
			order: 5;
			flex: 0 0 100%;
			max-width: 100%;
			padding-top: 120%;
		}
	}

	.subscribe__content {
		flex: 0 0 50%;
		max-width: 50%;
		padding: 108px 109px 165px;
		background-color: $purple;

		@media #{$small-desktop} {
			padding-left: 80px;
			padding-right: 80px;
		}

		@media #{$tablet-portrait} {
			padding: 100px 40px 165px;
		}

		@media #{$mobile} {
			flex: 0 0 100%;
			max-width: 100%;
			padding: 68px 44px 93px;
		}
	}

	.subscribe__entry {
		margin-bottom: 70px;
		@include font-fix();
		font-weight: 900;
		font-size: 45px;
		line-height: 1.03;
		color: $white;
		text-transform: uppercase;

		@media #{$tablet-portrait} {
			font-size: 35px;
		}

		@media #{$mobile} {
			margin-bottom: 35px;
			font-size: 29px;
		}
	}

	.subscribe__entry p {
		margin: 0;
	}
}
