/* ------------------------------------------------------------ *\
  Quantity
\* ------------------------------------------------------------ */

.quantity {
  display: inline-block; 
  
  input {
    font-size: 14px; 
    text-align: center; 
    width: 53px; 
    border: 0; 
    border-radius: 0; 
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  a {
    text-decoration: none; 
    font-weight: 500; 
    display: inline-block; 
  }
}
