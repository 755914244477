.search--default {
	.results,
	.form-search,
	.page__head {
		margin-bottom: 10px;
		text-align: center;
	}

	.page__title {
		margin-bottom: 50px;
		font-family: $font-coco-gothic-fat;
		font-weight: 900;
		color: $white;
		text-transform: uppercase;
	}

	h1.page__title {
		@media #{$mobile} {
			font-size: 35px;
		}
	}

	.form-search form {
		display: flex;
		justify-content: center;
		align-items: center;
		color: $white;

		@media #{$mobile} {
			display: block;
		}

		> * {
			margin-left: 10px;

			@media #{$mobile} {
				margin: 0 0 10px;
			}
		}

		label {
			display: inline-block;
		}
	}

	.no-results {
		padding: 50px 0 0;
		color: $white;
		text-align: center;
	}

	.results {
		padding: 20px 0;
		color: $white;
	}

	.search__field {
		height: 58px;
		padding: 8px 17px 0;
		border: 2px solid $white;
		background-color: transparent;
		@include font-fix();
		font-weight: 900;
		font-size: 23px;
		letter-spacing: -.02em;
		color: $white;
		text-transform: uppercase;

		@media #{$mobile} {
			width: 100%;
			height: 43px;
			padding: 3px 15px 0;
			font-size: 16px;
		}

		&::placeholder {
			color: $white;
			opacity: 1 !important;
		}
	}

	.search__btn {
		@include default-button();

		margin-top: 1px;
		border: 2px solid $white;
		background: $purple;
		padding: 15px 40px 6px;
		@include font-fix();
		font-weight: 900;
		font-size: 24px;
		letter-spacing: .045em;
		text-transform: uppercase;
		transition: background .4s;

		@media #{$mobile} {
			display: block;
			width: 100%;
		}

		&:hover {
			border-color: $white;
			background-color: $purple2;
			color: $white;
		}
	}

	.paging {
		margin-bottom: 0;
	}
}
