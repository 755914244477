/* ------------------------------------------------------------ *\
	Collections
\* ------------------------------------------------------------ */

.collections {
	&--default {
		.collection__title {
			font-family: $font-coco-gothic-fat;
			font-weight: 900;
			color: $white;
			text-transform: uppercase;

			@media #{$mobile} {
				font-size: 35px;
			}
		}

		.collection__item-title {
			color: $white;
		}
	}
}
