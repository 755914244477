/* ------------------------------------------------------------ *\
	Packages Review
\* ------------------------------------------------------------ */

.packages-review {
	position: relative;
	z-index: 10;

	&:before {
		content: '';
		position: absolute;
		bottom: 100%;
		left: 0;
		z-index: 10;
		width: 100%;
		height: 142px;
		margin-bottom: -1px;
		background-image: url(../images/packages-review-top-wave.png);
		background-position: 100% center;
		background-repeat: repeat-x;
		background-size: 2286px 142px;

		@media #{$mobile} {
			height: 69px;
			background-position: 56% center;
			background-size: 900px 69px;
		}
	}

	.packages-review__shell {
		padding: 142px 0 0;

		@media #{$mobile} {
			padding-top: 69px;
		}
	}
}
