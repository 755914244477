/* ------------------------------------------------------------ *\
  Drawer
\* ------------------------------------------------------------ */

.drawer {

  background-color: #fff;
  width: 767px;
  max-width: 90%;
  padding-bottom: 30px;
  border-left: 1px solid #e6e6e6;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1500;
  transform: translateX(100%);
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s;

  &.is-active {
    transform: translateX(0);
  }

  .drawer__close {
    display: block;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 24px;
    right: 15px;
  }

  .drawer__close svg,
  .drawer__close path {
    display: block;
    width: 100%;
    height: 100%;
  }

  .drawer__head {
    text-align: center;
    padding: 0 30px;
    margin: 20px 0;
  }

  h3 {
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-top: 30px;
    margin-bottom: 15px;
  }

  .drawer__table {
    margin-bottom: 85px;
  }

  .drawer__note {
    padding: 0 30px;
    margin-bottom: 70px;
  }

  .drawer__note label {
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    display: block;
    margin-bottom: 21px;
  }

  .drawer__note textarea {
    font-size: 14px;
    line-height: 1.6;
    color: #333;
    width: 100%;
    min-height: 100px;
    display: block;
    padding: 8px 10px;
    border: 1px solid #e6e6e6;
    resize: vertical;
  }

  .drawer__foot {
    text-align: center;
  }

  .drawer__foot h6 {
    @include font-fix();
    font-size: 11px;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .drawer__foot h4 {
    @include font-fix();
    font-size: 26px;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    margin-bottom: 16px;
  }

  .drawer__foot p {
    @include font-fix();
    font-size: 17px;
    line-height: 1.6;
    letter-spacing: 0 !important;
    font-weight: 400 !important;
    margin-bottom: 16px;
  }

  .drawer__foot strong {
    font-weight: 700;
  }

  .drawer__foot img {
    margin-bottom: 0;
  }
}

.drawer-overlay {
  background-color: rgba(0, 0, 0, 0);
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1499;
  transition: visibility 0.3s, background-color 0.3s;
}

.drawer.is-active + .drawer-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  visibility: visible;
}
