/* ------------------------------------------------------------ *\
  Table products
\* ------------------------------------------------------------ */

.table-products {
  td {
    vertical-align: top; 
    padding: 15px 0; 
  }

  td:first-child {
    width: 25%; 
  }

  td:first-child,
  td:nth-child(2) {
    padding-left: 30px; 
  }

  td:first-child a {
    display: block; 
    padding-left: 30px; 
    margin: 20px 0; 

    /* Mobile */
    
    @media #{$mobile} {
      padding-left: 0; 
    }
  }

  td:first-child img {
    margin-bottom: 0; 
  }

  td:nth-child(2) {
    text-align: center; 
    width: 48%; 
  }

  td:last-child {
    text-align: center; 
    vertical-align: bottom; 
    padding-right: 30px; 
  }

  td:last-child a {
    display: inline-block; 
    width: 9px; 
    height: 9px; 
    margin-bottom: 9px; 
    margin-left: 27px; 
  }

  td:last-child a svg,
  td:last-child a path {
    display: block; 
    width: 100%; 
    height: 100%; 
  }

  ul {
    list-style-type: none; 
    font-size: 15px; 
    padding-top: 8px; 
    margin: 0; 
  }

  h5 {
    font-weight: 500; 
    font-size: 15px; 
    padding-top: 26px; 
    margin-bottom: 0; 
  }

  h5 a {
    color: #000; 
    text-decoration: none; 
    transition: color 0.15s; 
                                    
    &:hover {
      color: #9f9f9f; 
    }
  }

  .table-products__item p {
    font-size: 17px; 
    margin-bottom: 25px; 
    margin-top: 16px; 
  }

  @media #{$mobile-small} {
    table,
    tbody,
    tr,
    td {
      display: block; 
      width: 100%; 
    }

    td:first-child,
    td:nth-child(2), {
      text-align: center; 
      width: 100%; 
      padding-left: 0; 
    }
  }
}
