/* ------------------------------------------------------------ *\
	Blog
\* ------------------------------------------------------------ */

.blog {
	&--default {
		.page__title {
			font-family: $font-coco-gothic-fat;
			font-weight: 900;
			color: $white;
			text-transform: uppercase;
		}

		h1.page__title {
			@media #{$mobile} {
				font-size: 35px;
			}
		}
	}
}
