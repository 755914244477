.default--holder {
	.page__title {
		margin-bottom: 80px;
		text-align: center;
	}

	.collection__items {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -1.5%;
	}

	.product__item,
	.article-item--default {
		flex: 0 0 25%;
		width: 25%;
		padding: 0 1.5%;
		margin-bottom: 60px;
	}

	.article-item--default {
		position: relative;
		padding-bottom: 47px;
		border-bottom: 0;

		.article-item__image {
			padding-top: 100%;
			margin: 0 0 28px;
		}

		.article-item__content {
			padding: 0;
		}

		.article-item__body {
			display: none;
		}

		.article-item__meta {
			display: none;
		}

		h3.article-item__title {
			font-size: 25px;
			text-align: center;
		}

		.article-item__foot {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
		}

		.btn {
			min-width: 207px;
			padding: 12px 20px 6px;
			font-size: 17px;
			letter-spacing: .08em;

			@media #{$mobile-small}{
				min-width: 0;
			}
		}
	}

	&.page--error {
		/*  404  */

		.page__title {
			margin-bottom: 0;
		}

		.page__content {
			text-align: center;

			p {
				margin-bottom: 50px;
				font-size: 20px;
			}
		}
	}

	&.section-account {
		padding-bottom: 100px;
		overflow: hidden;

		@media #{$mobile} {
			padding-bottom: 50px;
		}

		/* Accounts */

		.section__inner {
			display: flex;
			justify-content: space-between;
		}

		.section__title {
			font-family: $font-coco-gothic-fat;
			font-weight: 900;
			font-size: 32px;
			margin-bottom: 50px;
			color: $white;
			text-transform: uppercase;
		}

		.section__aside {
			position: relative;
			width: 22.5%;
			min-width: 250px;
			padding-top: 90px;
			background: transparent;
			color: $white;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				right: 100%;
				width: 100vw;
				background: inherit;
			}

			ul {
				margin: 0;
				list-style: none;

				li {
					margin-bottom: 40px;
				}

				a {
					display: block;
					font-size: 16px;
					text-decoration: none;
				}

				.active {
					a {
						font-weight: 700;
						text-decoration: underline;
					}
				}
			}
		}

		.section__content {
			padding-top: 90px;
			padding-left: 90px;
			width: 77.5%;
		}
	}

	/*  No Orders  */

	.no-orders {
		padding-top: 50px;
		font-size: 16px;
		color: $white;
		border-top: 1px solid $white;
	}

	/* Order */
	.order {
		text-align: left;

		th {
			font-weight: 400;
			padding: 20px;
			color: $white;
		}

		td {
			padding: 25px 20px;
			text-align: left;

			&.order__number,
			&.order__total {
				font-weight: 700;
			}
		}

		th,
		td {
			border-bottom: 1px solid $white;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}
	}

	.order-head {
		margin-bottom: 15px;

		.order-head__date {
			color: $white;
		}
	}

	.table-order--products {
		margin-bottom: 50px;

		a {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		th,
		td {
			border: 1px solid $white;
			padding: 15px 30px !important;
		}
	}

	.table-order--info {
		th {
			text-align: left;
		}
	}

	.order-details {
		.order-details__value {
			font-weight: 700;
		}
	}

	.btn--new {
		border: 2px solid $white;
		background: $purple;
		min-width: 207px;
		padding: 12px 20px 6px;
		@include font-fix();
		font-weight: 900;
		font-size: 17px;
		letter-spacing: .08em;
		text-transform: uppercase;
		transition: background .4s;

		@media #{$mobile-small}{
			min-width: 0;
		}

		&:hover {
			border-color: $white;
			background-color: $purple2;
			color: $white;
		}
	}

	/* Address */
	.form--address {
		width: 100%;
		order: -1;
		margin-bottom: 30px;
		padding: 30px 0;
		border-top: 1px solid $tertiary;
		border-bottom: 1px solid $tertiary;

		.form__label {
			display: none;
		}
	}
	.addresses {
		display: flex;
		flex-wrap: wrap;

		.address {
			display: flex;
			flex-flow: row wrap;
			width: 49%;
			margin: 0 1% 30px 0;
			border: 1px solid $white;
			color: $white;
		}

		.address__title {
			width: 100%;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			margin-bottom: auto;
			padding: 20px 30px;
			border-bottom: 1px solid $white;

			span {
				margin-left: auto;
				font-size: 16px;

				&:after {
					content: '';
					display: inline-block;
					vertical-align: middle;
					height: 6px;
					width: 9px;
					border-left: 2px solid;
					border-bottom: 2px solid;
					transform: rotate(-45deg);
					margin-top: -4px;
					margin-left: 4px;
				}
			}
		}

		.address__details {
			width: 100%;
			padding: 20px 30px;
		}

		.address__actions {
			width: 100%;
			margin-top: auto;
			padding: 20px 30px;
			text-align: right;
			border-top: 1px solid $white;

			a {
				margin: 0 5px;

				&:first-child {
					&:after {
						content: '|';
						margin-left: 10px;
					}
				}
			}
		}
	}

	.addresses__actions {
		border-top: 1px solid $white;
		padding: 30px 0;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;

		@media #{$mobile} {
			display: block;
		}

		a {
			margin-left: auto;

			@media #{$mobile} {
				display: block;
				width: 100%;
				margin-top: 20px;
			}
		}
	}

	.addresses__empty {
		font-size: 16px;
		color: $white;
		margin-bottom: 0;
		flex: 1 1;

		@media #{$mobile} {
			text-align: center;
		}
	}

	@media #{$tablet-portrait} {
		.product__item,
		.article-item--default {
			flex: 0 0 33.3%;
			width: 33.3%;
		}

		&.section-account {
			.section__content {
				padding-left: 25px;
			}
		}

		.table-order--products {
			th,
			td {
				padding: 15px !important;
			}
		}
	}

	@media #{$mobile} {
		.product__item,
		.article-item--default {
			width: 50%;
			flex: 0 0 50%;
		}

		/* Accounts */
		&.section-account {
			.shell {
				padding: 0;
			}

			.section__inner {
				display: block;
				min-height: auto;
			}

			.section__aside {
				width: auto;
				padding: 30px $shell-gutter;
				text-align: center;

				ul {
					li {
						display: inline-block;
						margin-right: 10px;
						margin-bottom: 0;

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}

			.section__content {
				width: auto;
				padding: 45px $shell-gutter;
			}

			.section__head {
				text-align: center;
			}
		}

		table,
		tr,
		td,
		tbody,
		tfoot {
			display: block;
		}

		th {
			display: none;
		}

		td:before {
			content: attr(data-heading);
			display: block;
			margin-right: 5px;
			font-weight: 700;
		}

		tr + tr {
			border-top: 1px solid $base;
		}

		.addresses {
			.address {
				width: 100%;
			}
		}

		.table-order--products {
			margin-bottom: 0;

			td {
				padding: 5px 10px !important;
				border: 0;
			}

			.order-details__title {
				font-weight: 700;
			}

			tfoot {
				padding-top: 5px;
				border-top: 1px solid $base;
				border-bottom: 1px solid $base;
				tr,
				td {
					border: 0;
					padding: 0;
				}

				tr {
					margin-bottom: 5px;
				}
			}
		}
	}

	@media #{$mobile-small} {
		.collection__items {
			display: block;
			margin: 0;
		}

		.product__item,
		.article-item--default {
			width: auto;
			padding: 0;
			margin: 0 0 40px;
		}

		/* Accounts */
		.section__inner {
			display: block;
		}

		.section__aside {
			width: auto;
			margin-right: 0;

			ul {
				a {
					margin: 5px 0;
				}
			}
		}

		.section__content {
			width: auto;
		}

		.order-info {
			.cols {
				display: block;
			}
		}
	}
}
