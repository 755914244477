/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {
	&--product {
		.form__controls {
			height: 100%;
		}

		.form__cols {
			display: flex;
			flex-flow: row wrap;

			@media #{$mobile-small} {
				display: block;
			}
		}

		.form__col {
			flex: 1 0;
		}

		.form__col--size-1 {
			flex: 0 0 68%;
			max-width: 68%;

			@media #{$mobile} {
				flex: 0 0 57.6%;
				max-width: 57.6%;
			}

			@media #{$mobile-small} {
				max-width: 100%;
			}
		}

		.form__col--size-2 {
			flex: 0 0 32%;
			max-width: 32%;

			@media #{$mobile} {
				flex: 0 0 42.4%;
				max-width: 42.4%;
			}

			@media #{$mobile-small} {
				max-width: 100%;
			}
		}

		.form__row {
			padding: 0 !important;
			border: 2px solid $white;

			@media #{$mobile} {
				border-width: 3px;
			}

			&--quantity {
				border-left: 1px;

				@media #{$mobile-small} {
					border-left: 3px solid $white;
				}
			}
		}

		.form__quantity {
			padding: 0 16px 0 13px;
			height: 100%;
			display: flex;
			align-items: center;

			@media #{$mobile} {
				background-color: $orange;
				padding: 0 13px;
			}

			.form__quantity-inner {
				display: flex;
				align-items: center;
				padding: 0;
				margin: 0 -3px;

				@media #{$mobile} {
					height: 40px;
					padding-bottom: 6px;
					margin: 0 -6px;
				}
			}

			.form__quantity-aside {
				padding: 0 3px;

				@media #{$mobile} {
					padding: 0 6px;
				}
			}

			.form__quantity-content {
				line-height: 1;
				flex: 1 0;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 3px;

				@media #{$mobile} {
					justify-content: space-between;
					padding: 0 6px;
				}
			}

			.form__quantity-btn {
				margin-top: 7px;
				border: 0;
				border-radius: 0;
				background-color: transparent;
				@include font-fix();
				letter-spacing: 0 !important;
				font-weight: 900;
				font-size: 23px;
				appearance: none;

				@media #{$mobile} {
					font-size: 17px;
				}
			}

			.form__label {
				margin: 7px 0 0;
			}

			.form__quantity-entry {
				min-width: 19px;
				margin-top: 7px;
				@include font-fix();
				letter-spacing: 0 !important;
				font-weight: 900;
				font-size: 23px;
				text-transform: uppercase;
				text-align: center;

				@media #{$mobile} {
					font-size: 17px;
				}
			}
		}

		.form__label {
			@include font-fix();
			font-weight: 900;
			font-size: 23px;
			letter-spacing: 0 !important;
			text-transform: uppercase;
			line-height: 1;

			@media #{$mobile} {
				font-size: 17px;
			}
		}

		.form__row {
			height: 58px;
			margin-bottom: 12px;
		}

		.bold-ro__frequency-type,
		.bold-ro__frequency-num {
			margin-bottom: 20px !important;
			width: 100%;
			padding-left: 12px;
			padding-right: 12px;
			border: 1px solid #000;
			background-color: transparent;
			@include font-fix();
			font-weight: 900;
			font-size: 23px;
			height: 54px;
			text-transform: uppercase;
			letter-spacing: 0 !important;
			line-height: 1;
			appearance: none;

			@media #{$mobile} {
				height: 40px;
				padding-left: 7px;
				padding-right: 7px;
				font-size: 17px;
			}

			&:last-child {
				margin-bottom: 0 !important;
			}
		}

		.select {
			position: relative;
			padding: 0;
			border: 0;
			min-width: 0 !important;
			height: 100%;
			display: flex;
			align-items: center;
			background-color: $orange;

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				right: 13px;
				width: 19px;
				height: 11px;
				background-image: url(../images/ico-arrow-down.svg);
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				transform: translateY(-50%);
				pointer-events: none;
			}
		}

		.select select {
			width: 100%;
			padding: 4px 40px 0 12px;
			border: 0;
			background-color: $orange;
			@include font-fix();
			font-weight: 900;
			font-size: 23px;
			text-transform: uppercase;
			letter-spacing: 0 !important;
			line-height: 1;
			appearance: none;

			@media #{$mobile} {
				height: 40px;
				padding: 0 45px 0 7px;
				font-size: 17px;
			}

			&::-ms-expand {
				display: none;
			}
		}

		.field {
			height: 59px;
			border: 0;
			background-color: $orange;
			@include font-fix();
			font-weight: 900;
			font-size: 23px;
			text-transform: uppercase;
		}

		.form__subscribe {
			position: relative;
			margin-top: 13px;

			@media #{$mobile} {
				margin-top: 17px;
			}

			.bold-ro__recurring-title {
				display: none;
			}

			.bold-ro__bold-active {
				border: 0;
				background-color: transparent;
			}

			.bold-ro__one-time-div,
			.bold-ro__recurring-div {
				padding: 0;
				border: 0;
			}

			.bold-ro__frequency-label {
				padding: 0;
			}

			.bold-ro__order-interval-container {
				display: block !important;

				.bold-ro__order-interval-lbl,
				.bold-ro__frequency-num,
				.bold-ro__frequency-type {
					display: block;
					width: 100%;
					margin: 0;
				}

				.bold-ro__order-interval-lbl {
					margin-bottom: 10px;
					font-size: 24px;
					font-weight: 900;
					text-transform: uppercase;
					letter-spacing: 0.045em;

					@media #{$mobile} {
						font-size: 18px;
					}
				}
			}

			.bold-ro__frequency-div {
				margin-top: 20px;
			}

			.form__subscribe-inner {
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				padding: 40px 20px;
				margin-top: 10px;
				border: 1px solid #dbdbdb;
				background-color: #f5f5f5;
				color: $black;
				opacity: 0;
				pointer-events: none;
				transition: opacity 0.4s;
			}

			&.is-open {
				.form__subscribe-inner {
					opacity: 1;
					pointer-events: all;
				}
			}
		}

		.form__btn {
			/* Mobile */

			@media #{$mobile} {
				position: fixed;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
			}
		}
	}

	&--subscribe {
		.form__head {
			margin-bottom: 11px;

			@media #{$mobile} {
				margin-bottom: 16px;
			}
		}

		.form__head h3 {
			@include font-fix();
			font-weight: 900;
			color: $white;
			text-transform: uppercase;
		}

		.form__body {
			max-width: 417px;
			display: flex;
			border: 2px solid $white;

			@media #{$mobile-small} {
				display: block;
			}
		}

		.form__row {
			flex: 1 0;
			margin-bottom: 0;
			border-right: 2px solid $white;

			@media #{$mobile-small} {
				border-right: 0;
				border-bottom: 2px solid $white;
			}
		}

		.form__field {
			height: 58px;
			border: 0;
			padding: 8px 17px 0;
			background-color: transparent;
			@include font-fix();
			font-weight: 900;
			font-size: 23px;
			letter-spacing: -0.02em;
			color: $white;
			text-transform: uppercase;

			@media #{$mobile} {
				height: 43px;
				padding: 3px 15px 0;
				font-size: 16px;
			}

			&::placeholder {
				color: $white;
				opacity: 1 !important;
			}
		}

		.form__btn {
			min-width: 127px;
			padding: 15px 5px 12px;
			border: 0;
			border-radius: 0;
			background-color: $red;
			@include font-fix();
			font-weight: 900;
			font-size: 23px;
			letter-spacing: -0.02em !important;
			text-transform: uppercase;
			color: $white;

			@media #{$mobile} {
				min-width: 130px;
				padding: 10px 5px 8px;
				font-size: 17px;
			}

			@media #{$mobile-small} {
				width: 100%;
			}
		}

		.form__message {
			padding: 20px 0;

			.errors ul {
				padding: 0;
				margin: 0;
				list-style-type: none;
			}

			&--error {
				color: $red;
			}

			&--success {
				color: $green;
			}
		}
	}

	&--cart {
		color: $white;

		h1 {
			@media #{$mobile} {
				font-size: 35px;
			}
		}

		h2 {
			font-family: $font-coco-gothic-fat;
			font-weight: 900;
			text-transform: uppercase;

			@media #{$mobile} {
				font-size: 35px;
			}
		}

		th {
			color: inherit !important;
		}

		.form__title {
			font-family: $font-coco-gothic-fat;
			font-weight: 900;
			text-transform: uppercase;
		}

		.table__info {
			color: currentColor;
		}

		.table__total-label {
			color: inherit;
		}

		.form__empty p {
			color: currentColor;
		}

		.field--quantity {
			color: $black;
		}

		.form__additional-buttons {
			padding: 50px 0 0;
		}

		.remove span {
			&:before,
			&:after {
				background-color: currentColor;
			}
		}
	}

	&--login,
	&--recover,
	&--register,
	&--address,
	&--address-new {
		color: $white;

		h1 {
			@media #{$mobile} {
				font-size: 35px;
			}
		}

		.form__description {
			font-size: 20px;
			color: $white;

			@media #{$mobile} {
				font-size: 15px;
			}
		}

		.form__title {
			font-family: $font-coco-gothic-fat;
			font-weight: 900;
			text-transform: uppercase;
			line-height: 1.2;
		}

		.field {
			color: $black;
		}

		.form__errors,
		.form__error {
			margin-bottom: 40px;
			text-align: center;

			ul {
				list-style-type: none;
			}
		}

		.form__cols {
			@media #{$mobile} {
				display: block;
			}
		}

		.form__col {
			@media #{$mobile} {
				max-width: 100%;
			}
		}
	}

	&--contact {
		.form__btn {
			@media #{$mobile} {
				display: block;
				width: 100%;
				margin: 0 !important;
			}
		}

		.field {
			resize: none;
		}
	}
}
