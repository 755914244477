/* ------------------------------------------------------------ *\
	Template Collection
\* ------------------------------------------------------------ */

.template-collection {
	.main {
		padding: 190px 0 100px;
		background-color: $orange;

		@media #{$mobile} {
			padding: 160px 0 100px;
		}
	}
}
