/* ------------------------------------------------------------ *\
	Article - Default Styles
\* ------------------------------------------------------------ */

.article--default {
	margin-bottom: 100px;
	color: $white;

	.article__head {
		margin-bottom: 60px;
		text-align: center;

		h2 {
			@media #{$mobile} {
				font-size: 35px;
			}
		}
	}

	.article__title {
		margin-bottom: 10px;
		font-family: $font-coco-gothic-fat;
		font-weight: 900;
		color: $white;
		text-transform: uppercase;
	}

	.article__meta {
		margin-bottom: 45px;
	}

	.article__image {
		padding: 0 10%;
		margin-bottom: 0;

		@media #{$mobile} {
			padding: 0;
		}

		img {
			width: 100%;
			height: auto;
			margin-bottom: 0;
		}
	}

	.article__body {
		margin-bottom: 80px;
		padding: 0 10%;
		color: $white;

		@media #{$mobile} {
			padding: 0;
			font-size: 15px;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 20px;
		}

		h1 {
			@media #{$mobile} {
				font-size: 35px;
			}
		}

		h2 {
			@media #{$mobile} {
				font-size: 30px;
			}
		}

		h3 {
			@media #{$mobile} {
				font-size: 25px;
			}
		}

		h4 {
			@media #{$mobile} {
				font-size: 20px;
			}
		}

		h5 {
			@media #{$mobile} {
				font-size: 15px;
			}
		}

		h6 {
			@media #{$mobile} {
				font-size: 13px;
			}
		}

		p {
			margin-bottom: 40px;
		}

		ul,
		ol {
			margin-bottom: 40px;
		}
	}

	.article__comments {
		padding: 60px 10% 0;
		border-top: 1px solid $white;

		.comments {
			margin-bottom: 60px;
			padding-bottom: 60px;
			border-bottom: 1px solid $white;
		}

		.comment {
			margin-bottom: 30px;

			&:last-child {
				margin-bottom: 0;
			}

			.comment__text {
				font-size: 16px;
				color: $white;
				margin-bottom: 5px;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Article Item - Default Styles
\* ------------------------------------------------------------ */

.article-item--default {
	margin-bottom: 80px;
	padding-bottom: 80px;
	border-bottom: 1px solid $white;

	.article-item__image {
		display: block;
		height: 0;
		padding-top: 56.25%;
		margin: 0 10% 50px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}

	.article-item__content {
		padding: 0 10%;
	}

	.article-item__head {
		margin-bottom: 15px;
	}

	.article-item__title {
		margin-bottom: 5px;
		font-family: $font-coco-gothic-fat;
		font-weight: 900;
		color: $white;
		text-transform: uppercase;

		a {
			text-decoration: none;
		}
	}

	.article-item__meta {
		color: $white;
	}

	.article-item__body {
		margin-bottom: 30px;
		font-size: 16px;
		color: $white;
	}

	@media #{$mobile-small} {
		padding-bottom: 40px;
		margin-bottom: 40px;

		.article-item__content {
			padding: 0 10px;
		}
	}
}
