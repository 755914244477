/* ------------------------------------------------------------ *\
	Product About
\* ------------------------------------------------------------ */

.product-about {
	.product-about__image {
		position: absolute;
		top: 5px;
		left: 0;
		width: 1091px;
		height: 722px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top left;
		pointer-events: none;

		@media #{$tablet-portrait} {
			top: 270px;
			left: -45px;
			width: 700px;
			height: 463px;
		}

		@media #{$mobile} {
			top: 222px;
			left: -72px;
			width: 433px;
			height: 286px;
			transform: rotate(-10deg);
		}
	}

	.product-about__entries {
		position: relative;
	}

	.product-about__entry {
		font-weight: 300;
		line-height: 1.8;
		color: $white;

		@media #{$mobile} {
			font-size: 15px;
			line-height: 1.2;
		}

		&:nth-child(1) {
			margin-bottom: 360px;

			@media #{$mobile} {
				margin-bottom: 239px;
			}
		}

		h1 {
			padding: 0 2px;
			margin-bottom: 4px;
			font-family: $font-coco-gothic-fat;
			font-weight: 900;
			letter-spacing: .04em;
			line-height: 1.2;
			text-transform: uppercase;
			appearance: none;

			@media #{$mobile} {
				margin-bottom: 17px;
				font-size: 37px;
			}
		}

		p {
			@media #{$mobile} {
				padding: 0 6px;
			}
		}

		&.full-width {
			display: flex;
			align-items: flex-start;
			padding-left: 5px;
			margin: 0 -5px;

			@media #{$mobile} {
				display: block;
				padding-left: 0;
				margin: 0;
			}

			> * {
				flex: 0 0 50%;
				max-width: 50%;
				padding: 0 5px;

				@media #{$mobile} {
					max-width: 100%;
					padding: 0;
				}
			}

			h1 {
				letter-spacing: 0;

				@media #{$mobile} {
					margin-bottom: 23px;
					letter-spacing: .04em;
				}
			}

			p {
				margin-top: -3px;

				@media #{$mobile} {
					padding: 0 6px;
					margin: 0;
				}
			}
		}

		&.half-width-left {
			max-width: 50%;
			padding: 0 7px;

			@media #{$mobile} {
				max-width: 100%;
				padding: 0;
			}
		}

		&.half-width-right {
			max-width: 50%;
			padding: 0 7px;
			margin-left: auto;

			@media #{$mobile} {
				max-width: 100%;
				padding: 0;
			}
		}
	}

	.product-about__entry ~ .product-about__entry {
		margin-top: 46px;

		@media #{$mobile} {
			margin-top: 72px;
		}
	}
}
