/* ------------------------------------------------------------ *\
  Form Elements Reset
\* ------------------------------------------------------------ */

input::placeholder {
  color: inherit;
  opacity: 1;
}

textarea::placeholder {
  color: inherit;
  opacity: 1;
}

input:-webkit-autofill {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
}

input[type='checkbox'] {
  opacity: 0;
  position: absolute;

  & ~ label {
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;
      height: 20px;
      width: 20px;
      border: 1px solid $tertiary;
    }

    &:after {
      content: none;
      position: absolute;
      left: 5px;
      top: 5px;
      transform: rotate(-45deg);
      display: inline-block;
      height: 6px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
    }
  }

  &:checked ~ label:after {
    content: '';
  }
}

/* ------------------------------------------------------------ *\
  Field
\* ------------------------------------------------------------ */

.field {
  @include default-field;
}

/* ------------------------------------------------------------ *\
  Textarea
\* ------------------------------------------------------------ */

.textarea {
  @include default-field;
}

/* ------------------------------------------------------------ *\
  Select
\* ------------------------------------------------------------ */

.select {
  display: block;
  width: 100%;
  padding: 16px 20px 16px;
  border: 1px solid $tertiary;
}

/* ------------------------------------------------------------ *\
  Form Btn
\* ------------------------------------------------------------ */

.form__btn {
  @include default-button;
}

/* ------------------------------------------------------------ *\
  Form Row
\* ------------------------------------------------------------ */

.form__row {
  margin-bottom: 20px;
}

/* ------------------------------------------------------------ *\
  Form Label
\* ------------------------------------------------------------ */

.form__label {
  display: block;
  margin-bottom: 8px;
}

/* ------------------------------------------------------------ *\
  Form Cols
\* ------------------------------------------------------------ */

.form__cols {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.form__col {
  flex: 0 0 48%;
  max-width: 48%;
}
