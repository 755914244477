/* ------------------------------------------------------------ *\
	Product Simple
\* ------------------------------------------------------------ */

.product-simple {
	h4 {
		display: flex;
		justify-content: center;
		flex-flow: row wrap;
		margin-bottom: 23px;
		@include font-fix();
		font-weight: 900;
		color: $white;
		text-align: center;
		text-transform: uppercase;

		> * {
			flex: 0 0 100%;
			max-width: 100%;
		}

		small {
			order: -5;
			display: block;
			font-size: 60%;
		}
	}

	.btn {
		padding: 12px 20px 7px;
		font-size: 18px;
	}
}
