/* ------------------------------------------------------------ *\
	Template Blog
\* ------------------------------------------------------------ */

.template-blog {
	.main {
		padding: 190px 0 100px;
		background-color: $orange;

		@media #{$mobile} {
			padding: 160px 0 100px;
		}
	}
}
