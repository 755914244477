/* ------------------------------------------------------------ *\
	Product Info
\* ------------------------------------------------------------ */

.product-info {
	position: relative;
	padding: 121px 0 76px;

	@media #{$mobile} {
		padding: 95px 0 104px;
	}

	.product-info__image {
		position: absolute;
		top: 57px;
		left: 0;
		width: 1186px;
		height: 516px;
		background-size: cover;
		background-position: center;

		@media #{$small-desktop} {
			top: 90px;
			width: 1000px;
			height: 435px;
		}

		@media #{$tablet-portrait} {
			top: 160px;
			width: 700px;
			height: 305px;
		}

		@media #{$mobile} {
			top: 117px;
			width: 562px;
			height: 245px;
		}
	}

	.product-info__content {
		position: relative;
		z-index: 5;

		.shell {
			display: flex;
			justify-content: flex-end;
		}
	}

	.product-info__content-inner {
		width: 100%;
		min-height: 494px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0 6px;

		@media #{$mobile} {
			min-height: 464px;
		}
	}

	.product-info__head {
		text-align: center;
	}

	.product-info__head h3 {
		@include font-fix();
		font-weight: 900;
		font-size: 35px;
		color: $white;
		text-transform: uppercase;

		@media #{$mobile} {
			font-size: 27px;
		}
	}

	.product-simple {
		max-width: 207px;
		margin-left: auto;

		@media #{$mobile} {
			margin: 0 auto;
		}
	}
}
