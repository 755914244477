/* ------------------------------------------------------------ *\
  Accordion alt
\* ------------------------------------------------------------ */

.accordion-alt {
  .accordion__section:first-child {
    margin-bottom: 19px;

    /* Mobile */

    @media #{$mobile} {
      margin-bottom: 0;
    }
  }

  .accordion__section + .accordion__section {
    /* Mobile */

    @media #{$mobile} {
      margin-top: 24px;
    }
  }

  .accordion__section.is-current .accordion__content {
    color: $white !important;
  }

  .accordion__content {
    padding-left: 85px;
    transition: color 0.3s;

    /* Mobile */

    @media #{$mobile} {
      padding-left: 39px;
    }
  }

  img {
    max-width: 65px;
    margin-bottom: 0;
    position: absolute;
    top: 50%;
    left: -85px;
    opacity: 0;
    transform: translate(-20px, -50%);
    transition: opacity 0.3s, transform 0.3s;

    /* Mobile */

    @media #{$mobile} {
      max-width: 23px;
      left: -39px;
    }
  }

  .accordion__section.is-current img {
    opacity: 1;
    transform: translate(0, -50%);
  }

  .accordion__head {
    font-family: $font-coco-gothic-fat;
    font-size: 46px;
    line-height: 2.14;
    text-transform: uppercase;
    letter-spacing: -0.04em;
    position: relative;

    /* Tablet Portrait */

    @media #{$tablet-portrait} {
      font-size: 30px;
    }

    @media #{$mobile} {
      font-size: 20px;
      line-height: 1.2;
    }
  }

  .accordion__body {
    font-family: $font-gotham;
    font-weight: 300;
    font-size: 21px;
    letter-spacing: -0.04em;
    line-height: 1.2;
    padding-right: 178px;

    /* Tablet Portrait */

    @media #{$mobile} {
      font-size: 15px;
    }

    /* Mobile */

    @media #{$mobile} {
      padding-right: 0;
      padding-top: 14px;
    }
  }
}
