/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	@include font-fix();
	font-weight: 900;
	font-size: 16px;
	letter-spacing: .2em;
	color: $white;
	text-shadow: -5px 4px 11px rgba($brown, 0.6);
	text-transform: uppercase;

	@media #{$tablet-portrait} {
		font-size: 20px;
		line-height: 1.52;
		text-align: center;
		text-shadow: none;
	}

	> ul {
		display: flex;
		align-items: center;
		padding: 0;
		margin: 0 -14px;
		list-style-type: none;

		@media #{$tablet-portrait} {
			display: block;
			margin: 0;
		}
	}

	> ul > li {
		padding: 0 14px;

		@media #{$tablet-portrait} {
			padding: 0;
		}
	}

	> ul > li + li {
		@media #{$tablet-portrait} {
			margin-top: 31px;
		}
	}

	> ul > li > a {
		position: relative;
		text-decoration: none;

		&:hover {
			&:after {
				transform: scaleX(1);
			}
		}

		&:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 2px;
			margin-top: 5px;
			background-color: $white;
			transform: scaleX(0);
			transform-origin: center;
			transition: transform .4s;
		}
	}

	> ul > li > ul {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);

		@media #{$tablet-portrait} {
			position: relative;
			top: 0;
			left: 0;
			transform: none;
		}
	}

	> ul > li > ul ul {
		position: absolute;
		top: 0;
		left: 100%;

		@media #{$tablet-portrait} {
			position: relative;
			left: 0;
			font-size: 12px;
		}
	}

	li {
		position: relative;

		&:hover {
			> ul {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	ul ul {
		border-radius: 5px;
		background-color: $white;
		font-size: 14px;
		color: $blue;
		opacity: 0;
		pointer-events: none;
		transition: opacity .4s;

		@media #{$tablet-portrait} {
			display: none;
			background-color: transparent;
			color: $white;
			opacity: 1;
		}
	}

	ul ul a {
		display: inline-block;
		padding: 10px 15px;
		text-decoration: none;
	}
}
