/* ------------------------------------------------------------ *\
	Section Products
\* ------------------------------------------------------------ */

.section-products {
	.section-products__head {
		margin-bottom: 48px;
		text-align: center;
	}

	.section-products__head h3 {
		@include font-fix();
		font-weight: 900;
		font-size: 35px;
		color: $white;
		text-transform: uppercase;

		@media #{$mobile} {
			font-size: 27px;
		}
	}

	.section-products__body {
		padding: 0 26px;
	}

	.section-products__notice {
		color: $white;
		text-align: center;
	}

	.products {
		position: relative;
		z-index: 5;
	}

	&--secondary {
		padding: 95px 0 0;
		margin-bottom: -23px;

		@media #{$tablet-portrait} {
			margin-bottom: -43px;
		}

		@media #{$mobile} {
			margin-bottom: -19px;
		}
	}

	&--tertiary {
		padding: 156px 0 79px;

		@media #{$mobile} {
			padding: 122px 0 79px;
		}

		.section-products__body {
			@media #{$mobile} {
				padding: 0 52px;
			}
		}
	}

	&--tabbed {
		padding-top: 68px;
		margin-bottom: -24px;

		@media #{$mobile} {
			margin-bottom: -19px;
		}
	}

	&--small-slider {
		.slider {
			padding: 0 80px;

			@media #{$tablet-portrait} {
				padding: 0;
			}
		}
	}
}
