/* ------------------------------------------------------------ *\
	Feature Secondary
\* ------------------------------------------------------------ */

.feature-secondary {
	.feature-secondary__image {
		min-height: 85px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 43px;

		@media #{$mobile} {
			margin-bottom: 48px;
		}
	}

	.feature-secondary__image img {
		max-width: 65px;
		max-height: 87px;
		margin-bottom: 0;
	}

	.feature-secondary__content h4 {
		max-width: 140px;
		margin: 0 auto;
		font-family: $font-coco-gothic-fat;
		font-weight: 900;
		line-height: 1.2;
		color: $white;
		text-transform: uppercase;
		text-align: center;
		word-break: break-word;

		@media #{$mobile} {
			font-size: 15px;
		}
	}
}
