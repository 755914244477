/* ------------------------------------------------------------ *\
	Accordion
\* ------------------------------------------------------------ */

.accordion {
	.accordion__section {
		position: relative;

		.accordion__head {
			padding: 41px calc(100% - 79.1%);

			@media (max-width: 1094px) {
				padding: 30px $shell-gutter 84px;
			}

			@media #{$mobile} {
				padding: 23px 38px 23px;
			}
		}

		h1 {
			margin-bottom: 0;
			font-family: $font-coco-gothic-fat;
			font-weight: 900;
			font-size: 50px;
			color: $white;
			line-height: 1.2;
			text-transform: uppercase;

			@media #{$mobile} {
				font-size: 17px;
			}
		}

		h3 {
			margin-bottom: 12px;
			@include font-fix();
			font-weight: 700 !important;
			font-size: 35px;
			color: $white;
			text-transform: uppercase;

			@media #{$mobile} {
				margin-bottom: 4px;
				font-size: 11px;
			}
		}

		&.is-current {
			.accordion__image {
				opacity: 1;
				transition: opacity .4s .4s;
			}
		}
	}

	.accordion__head {
		cursor: pointer;
	}

	.accordion__body {
		max-width: 60%;
		padding: 51px 0 83px calc(100% - 79.1%);
		font-weight: 500;
		font-size: 26px;
		color: $white;
		line-height: 1.45;

		@media (max-width: 1094px) {
			padding: 131px 0 30px $shell-gutter;
		}

		@media #{$mobile} {
			max-width: 80%;
			padding: 15px 38px 37px;
			font-size: 15px;
			line-height: 1.2;
		}
	}

	.accordion__image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 43%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: bottom center;
		opacity: 0;
		transition: opacity .1s;

		@media #{$mobile} {
			width: 46%;
		}
	}

	.accordion__content {
		position: relative;
		z-index: 5;
	}
}
