/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	position: relative;
	padding: 2px 0 44px;
	background-color: $blue;

	@media #{$mobile} {
		padding: 2px 0 12px;
	}

	&:before {
		content: '';
		position: absolute;
		bottom: 100%;
		left: 0;
		width: 100%;
		height: 85px;
		margin-bottom: 12px;
		background-image: url(../images/footer-wave.png);
		background-position: left center;
		background-repeat: repeat-x;
		background-size: 1427px 85px;

		@media #{$mobile} {
			height: 55px;
			margin-bottom: 0;
			background-position: 40% center;
			background-size: 923px 55px;
		}
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 100%;
		left: 0;
		width: 100%;
		height: 13px;
		background-color: $blue;

		@media #{$mobile} {
			display: none;
		}
	}

	.footer__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-flow: row wrap;
	}

	.footer__body {
		padding: 20px 28px 66px;

		@media #{$mobile} {
			padding: 0 9px 22px;
		}
	}

	.footer__bar {
		padding: 9px 30px 0 20px;
	}

	.footer__bar-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-flow: row wrap;
		margin: 0 -10px;

		@media #{$tablet-portrait} {
			margin: 0;
		}
	}

	.footer__bar-aside {
		padding: 0 10px;

		@media #{$tablet-portrait} {
			order: 5;
			flex: 0 0 100%;
			max-width: 100%;
			padding: 0;
		}
	}

	.footer__bar-content {
		padding: 0 10px;

		@media #{$tablet-portrait} {
			flex: 0 0 100%;
			max-width: 100%;
			padding: 0;
		}
	}

	.footer__nav-secondary {
		font-family: $font-gotham;
		font-weight: 900;
		font-size: 11px;
		letter-spacing: .1em;
		color: $white;
		text-transform: uppercase;

		@media #{$tablet-portrait} {
			margin-bottom: 20px;
		}

		@media #{$mobile} {
			text-align: center;
		}
	}

	.footer__nav-secondary ul {
		display: flex;
		flex-flow: row wrap;
		padding: 0;
		margin: 0 -9px;
		list-style-type: none;

		@media #{$tablet-portrait} {
			justify-content: center;
		}

		@media #{$mobile} {
			display: block;
			margin: 0;
		}
	}

	.footer__nav-secondary li {
		padding: 0 9px;

		@media #{$mobile} {
			padding: 0;
		}
	}

	.footer__nav-secondary li + li {
		position: relative;

		@media #{$mobile} {
			margin-top: 20px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: -2px;
			display: inline-block;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: $white;
			transform: translateY(-50%);

			@media #{$mobile} {
				top: -12px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	.footer__nav-secondary a {
		text-decoration: none;
	}

	.footer__copyright {
		font-family: $font-gotham;
		font-weight: 300;
		font-size: 10px;
		color: $white;
		text-transform: uppercase;

		@media #{$tablet-portrait} {
			text-align: center;
		}
	}

	.footer__nav {
		margin: 5px 0 0 89px;
		@include font-fix();
		font-weight: 900;
		font-size: 16px;
		color: $white;
		text-transform: uppercase;
		letter-spacing: .15em;

		@media #{$small-desktop} {
			order: 5;
			flex: 0 0 100%;
			max-width: 100%;
			margin-top: 40px;
			margin-left: 0;
		}

		@media #{$mobile} {
			padding-left: 8px;
			margin-top: 20px;
			font-size: 10px;
			letter-spacing: .2em;
		}

		ul {
			display: flex;
			align-items: center;
			padding: 0;
			margin: 0 -16px;
			list-style-type: none;

			@media #{$small-desktop} {
				justify-content: center;
			}

			@media #{$mobile} {
				margin: 0 -10px;
			}
		}

		li {
			padding: 0 16px;

			@media #{$mobile} {
				padding: 0 10px;
			}
		}

		a {
			text-decoration: none;
			transition: color .4s;

			&:hover {
				color: $orange;
			}
		}
	}

	.footer__socials {
		display: flex;
		align-items: center;
		padding: 0 2px 0 0;
		margin: 10px -5px 0;
		color: $white;
		list-style-type: none;

		@media #{$mobile} {
			transform: scale(.65);
			transform-origin: right;
		}

		li {
			padding: 0 5px;
		}

		a {
			display: inline-block;
			transition: color .4s;

			&:hover {
				color: $orange;
			}
		}

		.facebook {
			margin-right: 11px;
		}

		.instagram {
			margin-right: 4px;
		}
	}

	.footer__logo {
		@media #{$mobile} {
			max-width: 94px;
			margin: 6px 0 0 9px;
		}
	}
}
