/* ------------------------------------------------------------ *\
	Process Step
\* ------------------------------------------------------------ */

.process-step {
	color: currentColor;

	.process-step__inner {
		display: flex;
		align-items: flex-end;
		margin: 0 -3px;
		padding-left: 7px;

		@media #{$mobile} {
			align-items: flex-start;
			padding-left: 0;
			margin: 0;
		}
	}

	.process-step__aside {
		flex: 0 0 38.8%;
		max-width: 38.8%;
		padding: 16px 3px 0;
		text-align: center;

		@media #{$small-desktop} {
			flex: 0 0 34%;
			max-width: 34%;
		}
		@media #{$mobile} {
			flex: 0 0 33.33%;
			max-width: 33.33%;
			padding: 12px 0 0;
		}
	}

	.process-step__aside svg,
	.process-step__aside img {
		display: inline-block;
		max-width: 100%;
		margin-bottom: 19px;

		@media #{$mobile} {
			margin-bottom: 10px;
		}
	}

	.process-step__aside h3 {
		margin-bottom: 0;
		@include font-fix();
		font-weight: 900;
		font-size: 35px;
		line-height: 1;
		text-transform: uppercase;

		@media #{$mobile} {
			font-size: 16px;
		}
	}

	.process-step__content {
		flex: 1 0;
		padding: 0 3px 9px;
		line-height: 1.2;

		@media #{$mobile} {
			padding: 0 0 0 16px;
			font-size: 15px;
		}
	}

	&.is-current {
		color: $white;
	}
}
