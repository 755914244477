/* ------------------------------------------------------------ *\
	Nav Access
\* ------------------------------------------------------------ */

.nav-access {
	color: $white;

	ul {
		display: flex;
		align-items: center;
		padding: 0;
		margin: 0 -9px;
		list-style-type: none;

		@media #{$tablet-portrait} {
			justify-content: center;
		}
	}

	li {
		display: inline-flex;
		padding: 0 9px;
	}

	li.nav-access__cart-btn {
		@media #{$tablet-portrait} {
			display: none;
		}
	}

	a {
		position: relative;
		z-index: 5;
		display: inline-flex;
		text-align: center;

		&:hover {
			&:after {
				transform: scaleX(1);
			}
		}

		&:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 2px;
			margin-top: 5px;
			background-color: $white;
			transform: scaleX(0);
			transform-origin: center;
			transition: transform 0.4s;
		}

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: -5;
			display: inline-block;
			width: 1px;
			height: 1px;
			transform: translate(-50%, -50%);
			box-shadow: -5px 3px 15px 7px rgba($brown, 0.45);
		}
	}

	a:before,
	a:after {
		/* Mobile */

		@media #{$tablet-portrait} {
			content: none;
		}
	}
}
