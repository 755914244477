/* ------------------------------------------------------------ *\
	Process
\* ------------------------------------------------------------ */

.process {
	.process__item {
		&:nth-child(1) {
			color: $red2;
		}

		&:nth-child(2) {
			color: $red;
		}

		&:nth-child(3) {
			color: $blue2;
		}
	}

	.process__item ~ .process__item {
		margin-top: 46px;

		@media #{$mobile} {
			margin-top: 52px;
		}
	}
}
