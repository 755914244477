/* ------------------------------------------------------------ *\
  Section features alt
\* ------------------------------------------------------------ */

.section-features-alt {
  top: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: calc(var(--vh, 1vh) * 100) !important;
  background-position: calc(50% + 39px) -110px;
  background-repeat: no-repeat;
  background-size: 410px 844px;
  // padding-top: 565px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 87px;
  position: relative;
  transition: background-color 0.3s;

  /* Mobile */

  @media #{$mobile} {
    background-position: calc(50% + 11px) -18px;
    background-size: 152px 312px;
    padding-top: 236px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 56px;
  }

  .section__aside-text {
    @include font-fix();
    font-weight: 300 !important;
    font-size: 20px;
    line-height: 1;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    white-space: nowrap;
    color: $white;
    transform: rotate(-90deg);
    position: absolute;
    top: 365px;
    left: -106px;

    /* Small Desktop */

    @media #{$small-desktop} {
      top: 325px;
    }

    @media #{$tablet-portrait} {
      top: 325px;
      left: -160px;
    }

    /* Mobile */

    @media #{$mobile} {
      display: none;
    }
  }

  .section__content {
    margin: 0 auto;
    max-width: 927px;

    /* Tablet Portrait */

    @media #{$tablet-portrait} {
      padding: 0 30px;
    }

    /* Mobile */

    @media #{$mobile} {
      padding: 0;
      max-width: 281px;
    }
  }
}
