/* ------------------------------------------------------------ *\
	Features Secondary
\* ------------------------------------------------------------ */

.features-secondary {
	padding: 0 13px;

	@media #{$mobile} {
		padding: 0;
	}

	.features-secondary__items {
		display: flex;
		flex-flow: row wrap; 
		justify-content: center;
		margin: 0 -10px -20px;

		@media #{$tablet-portrait} {
			justify-content: center;
			flex-wrap: wrap;
		}

		@media #{$mobile} {
			margin: 0 -5px;
		}
	}

	.features-secondary__item {
		padding: 0 20px;
		flex: 0 0 16.66%;
		max-width: 16.66%;
		margin-bottom: 20px;

		@media #{$small-desktop} {
			flex: 0 0 25%;
			max-width: 25%;
			padding: 0 10px; 
		}

		@media #{$mobile} {
			flex: 0 0 50%;
			max-width: 50%;
			padding: 0 5px;
		}
	}
}
