.form--small {
	max-width: 580px;
	margin: 0 auto 40px;

	&:last-child {
		margin-bottom: 0;
	}
}

.form--default {
	.form__title {
		text-align: center;
	}

	.form__head {
		margin-bottom: 80px;
	}

	.form__description {
		padding: 40px 10% 0;
		font-size: 16px;
		color: $primary;
		text-align: center;
	}

	.form__actions {
		text-align: right;
	}

	.form__btn {
		margin: 5px;
	}

	.form__empty {
		text-align: center;

		h2 {
			padding-bottom: 50px;
			border-bottom: 1px solid $tertiary;
			margin-bottom: 50px;
		}

		p {
			color: $primary;
			margin-bottom: 35px;
		}
	}

	/* Modifiers */
	&.form--login {
		.form__btn {
			width: 100%;
			margin: 0 0 40px;
		}

		.form__label {
			display: none;
		}
	}

	&.form--guest {
		padding: 30px 0;
	}

	&.form--recover,
	&.form--register {
		.form__actions {
			text-align: center;
		}

		.form__btn {
			width: 100%;
			margin: 0 0 40px;
		}

		.form__label {
			display: none;
		}
	}

	&.form--recover {
		display: none;
	}

	&.form--contact {
		.form__head {
			padding-bottom: 0;
			border-bottom: 1px solid $tertiary;
		}

		.form__description {
			text-align: left;
		}

		.form__label {
			display: none;
		}

		.form__body,
		.form__actions {
			padding: 0 10%;
		}
	}

	@media #{$mobile} {
		.form__head {
			margin-bottom: 40px;
		}

		.form__description {
			padding-left: 0;
			padding-right: 0;
		}

		.form__actions {
			text-align: center;
		}

		&.form--contact {
			.form__head {
				padding-bottom: 40px;
			}

			.form__body,
			.form__actions {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}
