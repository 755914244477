/* ------------------------------------------------------------ *\
  Button Cart
\* ------------------------------------------------------------ */

.btn-cart {
	position: relative;
	z-index: 5;
	display: inline-flex;
	text-align: center;
	color: #fff;

	.btn-cart__counter {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 10px;
		background: #e94442;
		font-weight: 700;
		color: #fff;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		top: -7px;
		left: -6px;
	}

	&:hover {
		&:after {
			transform: scaleX(1);
		}
	}

	&:after {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 2px;
		margin-top: 5px;
		background-color: $white;
		transform: scaleX(0);
		transform-origin: center;
		transition: transform 0.4s;

		@media #{$tablet-portrait} {
			content: none;
		}
	}

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: -5;
		display: inline-block;
		width: 1px;
		height: 1px;
		transform: translate(-50%, -50%);
		box-shadow: -5px 3px 15px 7px rgba($brown, 0.45);

		@media #{$tablet-portrait} {
			content: none;
		}
	}
}
