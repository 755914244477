/* ------------------------------------------------------------ *\
	Breadcrumbs
\* ------------------------------------------------------------ */

.breadcrumbs {
	margin-bottom: 60px;
	font-size: 12px;
	color: $primary;

	a {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.current {
		font-weight: 700;
		color: $base;
	}

	.breadcrumbs__divider {
		padding: 0 5px;
	}

	@media #{$mobile} {
		margin-bottom: 30px;
	}
}
