/* ------------------------------------------------------------ *\
  Wrapper
\* ------------------------------------------------------------ */

.wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow: hidden;
  min-height: -webkit-fill-available;

  .footer {
    margin-top: auto;
  }

  /*IE11 fix*/
  > * {
    flex: 0 0 auto;
  }
}

/*IE11 fix*/
body {
  display: flex;
  flex-direction: column;
}
