/* ------------------------------------------------------------ *\
	Feature
\* ------------------------------------------------------------ */

.feature {
	font-weight: 300;
	line-height: 1.2;
	color: $white;
	text-align: center;
	text-transform: uppercase;

	@media #{$mobile} {
		font-size: 15px;
	}

	.feature__image {
		min-height: 69px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 19px;

		@media #{$mobile} {
			margin-bottom: 0;
		}
	}

	.feature__image img {
		margin-bottom: 0;

		@media #{$mobile} {
			transform: scale(.8);
			transform-origin: center;
		}
	}
}
