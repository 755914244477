/* ------------------------------------------------------------ *\
	Product Faq
\* ------------------------------------------------------------ */

.product-faq {
	.product-faq__cols {
		display: flex;
		height: 718px;

		@media #{$tablet-portrait} {
			display: block;
			height: auto;
		}

		&:hover {
			@media (min-width: $responsive-sm + 1) {
				.product-faq__col {
					&:nth-child(3) {
						max-width: 25%;
					}

					&:nth-child(3) + .product-faq__col {
						max-width: 25%;
					}
				}
			}
		}
	}

	.product-faq__col {
		flex: 1 0;
		display: flex;
		flex-direction: column;
		transition: max-width .4s;

		@media #{$tablet-portrait} {
			display: block;
		}

		&:nth-child(1) {
			&:hover {
				@media (min-width: $responsive-sm + 1) {
					max-width: 25%;

					& ~ .product-faq__col--2of12 {
						max-width: 16.667% !important;
					}

					& ~ .product-faq__col--4of12 {
						max-width: 33.33% !important;
					}

					& + .product-faq__col {
						max-width: 25% !important;
					}
				}
			}
		}

		&:nth-child(2) {
			> * {
				max-height: 50%;

				@media #{$tablet-portrait} {
					max-height: 100%;
				}
			}

			&:hover {
				@media (min-width: $responsive-sm + 1) {
					> *:first-child {
						&:hover {
							max-height: 80% !important;

							& + * {
								max-height: 20% !important;
							}
						}
					}

					& ~ .product-faq__col--2of12 {
						max-width: 16.667% !important;
					}

					& ~ .product-faq__col--4of12 {
						max-width: 33.33% !important;
					}
				}
			}
		}

		&:nth-child(3) {
			> * {
				max-height: 50%;

				@media #{$tablet-portrait} {
					max-height: 100%;
				}
			}

			&:hover {
				@media (min-width: $responsive-sm + 1) {
					max-width: 33.33% !important;

					& ~ .product-faq__col--2of12 {
						max-width: 16.667% !important;
					}

					> *:first-child {
						max-height: 24%;

						&:hover {
							max-height: 79% !important;

							& + * {
								max-height: 21% !important;
							}
						}
					}

					> *:last-child {
						max-height: 76%;
					}
				}
			}
		}

		> * {
			flex: 1 0;
			height: 100%;
			transition: max-height .4s;

			@media #{$tablet-portrait} {
				height: auto;
			}
		}

		&--2of12 {
			max-width: 16.667%;

			@media #{$tablet-portrait} {
				max-width: 100%;
			}
		}

		&--4of12 {
			max-width: 33.33%;

			@media #{$tablet-portrait} {
				max-width: 100%;
			}
		}
	}

	.product-faq__item {
		position: relative;
		overflow: hidden;

		&:hover {
			@media (min-width: $responsive-sm + 1) {
				.product-faq__item-head {
					bottom: 100%;
					transform: translateY(92%);
				}

				.product-faq__item-body {
					bottom: 47.2%;
					transform: none;
					opacity: 1;
					pointer-events: all;
				}

				.product-faq__item-image {
					opacity: 1;
				}
			}
		}

		&--second {
			&:hover {
				@media (min-width: $responsive-sm + 1) {
					.product-faq__item-head {
						bottom: 98%;
						transform: translateY(100%);
					}

					.product-faq__item-body {
						bottom: 3px;
					}

					& + .product-faq__item .product-faq__item-head {
						top: 15px;
						max-width: 100%;
					}
				}
			}

			.product-faq__item-head {
				max-width: 220px;
				padding: 0 13px;

				@media #{$tablet-portrait} {
					max-width: 100%;
					padding: 23px 15px 72px;
				}
			}

			.product-faq__item-head h6 {
				margin-bottom: 8px;

				@media #{$tablet-portrait} {
					margin-bottom: 2px;
				}
			}

			.product-faq__item-body {
				padding: 17px;

				@media #{$tablet-portrait} {
					padding: 69px 11px 27px;
				}
			}

			.product-faq__item-image {
				width: 203px;
				height: 525px;
				margin-left: 59px;

				@media #{$tablet-portrait} {
					width: 126px;
					height: 325px;
				}
			}
		}

		&--third {
			&:hover {
				@media (min-width: $responsive-sm + 1) {
					.product-faq__item-head {
						top: 14px;
						bottom: auto;
						max-width: 100%;
						transform: none;
					}

					.product-faq__item-body {
						bottom: 15px;
					}
				}
			}

			.product-faq__item-head {
				bottom: auto;
				top: 56px;
				max-width: 260px;
				padding: 0 13px;

				@media #{$tablet-portrait} {
					top: 0;
					padding: 17px 11px 72px;
				}
			}

			.product-faq__item-head h3 {
				letter-spacing: -.04em;
			}

			.product-faq__item-head h6 {
				margin-bottom: 5px;

				@media #{$tablet-portrait} {
					margin-bottom: 2px;
				}
			}

			.product-faq__item-image {
				width: 276px;
				height: 285px;

				@media #{$tablet-portrait} {
					margin: 0 0 -4px 11px;
				}
			}
		}

		&--fourth {
			&:hover {
				@media (min-width: $responsive-sm + 1) {
					.product-faq__item-head {
						bottom: 96%;
						transform: translateY(100%);
					}

					.product-faq__item-body {
						bottom: 1.8%;
					}

					+ .product-faq__item .product-faq__item-head {
						top: 14px;
					}
				}
			}

			.product-faq__item-head {
				max-width: 345px;
				padding: 12px 13px;

				@media #{$tablet-portrait} {
					max-width: 100%;
					padding: 25px 15px 36px;
				}
			}

			.product-faq__item-head h6 {
				margin-bottom: 1px;
			}

			.product-faq__item-body {
				padding: 18px;

				@media #{$tablet-portrait} {
					padding: 69px 11px 27px;
				}
			}

			.product-faq__item-image {
				top: -4px;
				bottom: auto;
				width: 229px;
				height: 460px;
				margin-left: 28px;

				@media #{$tablet-portrait} {
					width: 134px;
					height: 270px;
				}
			}
		}

		&--fifth {
			&:hover {
				@media (min-width: $responsive-sm + 1) {
					.product-faq__item-head {
						bottom: auto;
						transform: none;
					}

					.product-faq__item-body {
						bottom: 7px;
					}
				}
			}

			.product-faq__item-head {
				bottom: auto;
				top: 2px;
				max-width: 345px;
				padding: 12px 13px;

				@media #{$tablet-portrait} {
					top: 0;
					padding: 26px 17px 34px;
				}
			}

			.product-faq__item-head h6 {
				margin-bottom: 5px;

				@media #{$tablet-portrait} {
					margin-bottom: 2px;
				}
			}

			.product-faq__item-body {
				padding: 20px;

				@media #{$tablet-portrait} {
					padding: 69px 11px 27px;
				}
			}

			.product-faq__item-image {
				left: auto;
				right: 0;
				width: 405px;
				height: 369px;
				transform: none;

				@media #{$tablet-portrait} {
					width: 263px;
					height: 240px;
				}
			}
		}

		&--sixth {
			&:hover {
				@media (min-width: $responsive-sm + 1) {
					.product-faq__item-head {
						transform: translateY(102%);
					}

					.product-faq__item-body {
						bottom: 4px;
					}
				}
			}

			.product-faq__item-head {
				left: auto;
				right: 0;
				padding: 27px 13px;
				text-align: right;

				@media #{$tablet-portrait} {
					left: 0;
					text-align: left;
					padding: 26px 15px 36px;
				}
			}

			.product-faq__item-head h6 {
				margin-bottom: 12px;

				@media #{$tablet-portrait} {
					margin-bottom: 2px;
				}
			}

			.product-faq__item-body {
				padding: 28px 20px 28px 28px;
				line-height: 1.5;

				@media #{$tablet-portrait} {
					padding: 69px 11px 27px;
				}
			}

			.product-faq__item-image {
				bottom: auto;
				top: 50%;
				left: auto;
				right: 0;
				width: 290px;
				height: 187px;
				margin-top: -22px;
				transform: translateY(-50%);
			}
		}

		&.is-current {
			.product-faq__item-image {
				@media #{$tablet-portrait} {
					opacity: 1;
				}
			}
		}
	}

	.product-faq__item-inner {
		display: block;
		width: 100%;
		height: 100%;
		text-decoration: none;
	}

	.product-faq__item-image {
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 420px;
		height: 309px;
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0;
		transition: opacity .4s;
		transform: translateX(-50%);
	}

	.product-faq__item-head {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 29px 13px;
		transition: top .4s, bottom .4s, transform .4s;

		@media #{$tablet-portrait} {
			position: relative;
			padding: 31px 13px 64px;
		}
	}

	.product-faq__item-head h3 {
		font-family: $font-coco-gothic-fat;
		font-weight: 900;
		line-height: 1.2;
		color: $white;
		text-transform: uppercase;

		@media #{$mobile} {
			font-size: 30px;
		}
	}

	.product-faq__item-head h6 {
		margin-bottom: 4px;
		@include font-fix();
		font-weight: 700 !important;
		color: $white;
		text-transform: uppercase;
	}

	.product-faq__item-body {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 13px;
		font-size: 15px;
		color: $white;
		line-height: 1.4;
		opacity: 0;
		pointer-events: none;
		transform: translateY(100%);
		transition: bottom .4s, transform .4s, opacity .4s;

		@media #{$tablet-portrait} {
			position: relative;
			padding: 69px 11px 27px;
			opacity: 1;
			pointer-events: all;
			transform: none;
		}
	}
}
