/*  Vendor  */

@import '~slick-carousel/slick/slick.css';

/* Generic */
@import '1-abstracts/variables';
@import '1-abstracts/base';
@import '1-abstracts/mixins';
@import '1-abstracts/fonts';
@import '1-abstracts/helpers';
@import '1-abstracts/reset';
@import '1-abstracts/_sprite.css';

/* Default */
@import "2-defaults/_article.scss";
@import "2-defaults/_blog.scss";
@import "2-defaults/_cart.scss";
@import "2-defaults/_collection.scss";
@import "2-defaults/_form.scss";
@import "2-defaults/_holder.scss";
@import "2-defaults/_paging.scss";
@import "2-defaults/_product.scss";
@import "2-defaults/_search.scss";

/*  Base  */
@import "3-base/_form-elements.scss";
@import "3-base/_form.scss";
@import "3-base/_table.scss";

/* Regions */
@import "4-layout/_container.scss";
@import "4-layout/_content.scss";
@import "4-layout/_footer.scss";
@import "4-layout/_header.scss";
@import "4-layout/_shell.scss";
@import "4-layout/_sidebar.scss";
@import "4-layout/_wrapper.scss";

/* Components */
@import "5-components/_accordion-alt.scss";
@import "5-components/_accordion.scss";
@import "5-components/_animate.scss";
@import "5-components/_bold-ro__vanilla-modal.scss";
@import "5-components/_breadcrumbs.scss";
@import "5-components/_btn-cart.scss";
@import "5-components/_btn.scss";
@import "5-components/_card.scss";
@import "5-components/_collection.scss";
@import "5-components/_collections.scss";
@import "5-components/_company-features.scss";
@import "5-components/_drawer.scss";
@import "5-components/_feature-secondary.scss";
@import "5-components/_feature.scss";
@import "5-components/_features-secondary.scss";
@import "5-components/_features.scss";
@import "5-components/_form.scss";
@import "5-components/_hero.scss";
@import "5-components/_is-busy.scss";
@import "5-components/_list.scss";
@import "5-components/_logo.scss";
@import "5-components/_main.scss";
@import "5-components/_manage-subscription-page.scss";
@import "5-components/_nav-access.scss";
@import "5-components/_nav-trigger.scss";
@import "5-components/_nav.scss";
@import "5-components/_no-scroll.scss";
@import "5-components/_package.scss";
@import "5-components/_packages-review.scss";
@import "5-components/_page.scss";
@import "5-components/_pagination.scss";
@import "5-components/_process-step.scss";
@import "5-components/_process.scss";
@import "5-components/_product-about.scss";
@import "5-components/_product-details.scss";
@import "5-components/_product-faq.scss";
@import "5-components/_product-feature.scss";
@import "5-components/_product-features.scss";
@import "5-components/_product-info.scss";
@import "5-components/_product-item.scss";
@import "5-components/_product-reviews.scss";
@import "5-components/_product-simple.scss";
@import "5-components/_product.scss";
@import "5-components/_products.scss";
@import "5-components/_quantity.scss";
@import "5-components/_related-products.scss";
@import "5-components/_scrollmagic-container.scss";
@import "5-components/_section-animate.scss";
@import "5-components/_section-card.scss";
@import "5-components/_section-features-alt.scss";
@import "5-components/_section-features.scss";
@import "5-components/_section-image.scss";
@import "5-components/_section-info.scss";
@import "5-components/_section-made.scss";
@import "5-components/_section-products.scss";
@import "5-components/_section.scss";
@import "5-components/_shopify-challenge.scss";
@import "5-components/_slick-slider.scss";
@import "5-components/_slider.scss";
@import "5-components/_stamped-container.scss";
@import "5-components/_store-locator.scss";
@import "5-components/_subscribe.scss";
@import "5-components/_table-products.scss";
@import "5-components/_table.scss";
@import "5-components/_tabs.scss";
@import "5-components/_template-404.scss";
@import "5-components/_template-article.scss";
@import "5-components/_template-blog.scss";
@import "5-components/_template-cart.scss";
@import "5-components/_template-collection.scss";
@import "5-components/_template-customer.scss";
@import "5-components/_template-index.scss";
@import "5-components/_template-list-collections.scss";
@import "5-components/_template-page.scss";
@import "5-components/_template-search.scss";

/*  Pages  */
