/* ------------------------------------------------------------ *\
	Product Item
\* ------------------------------------------------------------ */

.product__item {
	position: relative;
	padding-bottom: 47px !important;

	.product__item-image {
		display: block;
		padding-bottom: 114.06%;
		margin-bottom: 0;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	}

	.product__item-title {
		margin-bottom: 23px;
		@include font-fix();
		font-weight: 900;
		letter-spacing: 0 !important;
		color: $white;
		text-align: center;
		text-transform: uppercase;

		a {
			display: flex;
			justify-content: center;
			flex-flow: row wrap;
			text-decoration: none;

			> * {
				flex: 0 0 100%;
				max-width: 100%;
			}

			small {
				order: -5;
				display: block;
				font-size: 60%;

				@media #{$mobile} {
					font-size: 50%;
				}
			}
		}
	}

	.product__item-price {
		font-weight: 700;
		font-size: 16px;
		line-height: 1.75;
		text-transform: uppercase;

		del {
			display: inline-block;
			font-weight: 400;
			color: $primary;
			margin-right: 5px;
		}

		ins {
			text-decoration: none;
		}
	}

	.product__item-info {
		color: $primary;
	}

	.product__item-actions {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
	}
}
