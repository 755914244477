/* ------------------------------------------------------------ *\
	Section Made
\* ------------------------------------------------------------ */

.section-made {
	.section-made__shell {
		padding: 0;
	}

	.section-made__inner {
		display: flex;
	}

	.section-made__content {
		flex: 1 0;
		padding: 163px 104px 170px;
		background-color: $red2;

		@media #{$small-desktop} {
			padding: 163px 30px 170px;
		}

		@media #{$mobile} {
			padding: 114px 50px 170px;
		}

		> h1 {
			margin-bottom: 34px;
			font-family: $font-coco-gothic-fat;
			font-weight: 900;
			color: $white;
			text-transform: uppercase;

			@media #{$tablet-portrait} {
				text-align: center;
			}

			@media #{$mobile} {
				margin-bottom: 22px;
				font-size: 35px;
				line-height: 1.2;
			}
		}
	}

	.section-made__image {
		flex: 0 0 41.6%;
		max-width: 41.6%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		@media #{$tablet-portrait} {
			display: none;
		}
	}
}
