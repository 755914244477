/* ------------------------------------------------------------ *\
	Related Products
\* ------------------------------------------------------------ */

.related-products {
	@media #{$tablet-portrait} {
		padding: 0 26px;
	}

	.related-products__head {
		margin-bottom: 47px;
		text-align: center;
	}

	.related-products__head h3 {
		@include font-fix();
		font-weight: 900;
		font-size: 35px;
		color: $white;
		letter-spacing: .02em;
		text-transform: uppercase;
	}
}
