/* ------------------------------------------------------------ *\
	Tabs
\* ------------------------------------------------------------ */

.tabs {
	.tabs__nav {
		margin-bottom: 63px;
		@include font-fix();
		font-weight: 900;
		font-size: 18px;
		color: $white;
		text-transform: uppercase;
		text-align: center;

		@media #{$mobile} {
			padding: 0 33px;
			margin-bottom: 51px;
			font-size: 12px;
		}

		ul {
			display: inline-flex;
			justify-content: center;
			flex-flow: row wrap;
			border: solid $white;
			border-width: 2px 2px 0 0;
		}

		li {
			flex: 1 0 auto;
			border: solid $white;
			border-width: 0 0 2px 2px;
		}

		a {
			display: inline-block;
			width: 100%;
			padding: 13px 26px 8px;
			text-decoration: none;
			transition: background .4s, color .4s;

			@media #{$mobile} {
				padding: 9px 15px 5px;
			}

			&:hover {
				background-color: $white;
				color: $orange2;
			}
		}
	}

	.tabs__body {
		position: relative;
		z-index: 1;
	}

	.tab {
		position: absolute;
		top: 0;
		left: 26px;
		right: 26px;
		z-index: -5;
		opacity: 0;
		pointer-events: none;
		transition: opacity .4s;

		&.is-current {
			position: relative;
			left: 0;
			right: 0;
			z-index: 0;
			opacity: 1;
			pointer-events: all;
			transition: opacity .4s .4s;
		}
	}
}
