/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
	display: inline-block;
	border-radius: 0;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	box-shadow: none;
	appearance: none;
	@include default-button();

	&--block {
		display: block;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	&--primary {
		border: 2px solid $white;
		background: $purple;
		padding: 13px 40px 8px;
		@include font-fix();
		font-weight: 900;
		font-size: 24px;
		letter-spacing: .045em;
		text-transform: uppercase;
		transition: background .4s;

		@media #{$mobile} {
			padding: 12px 40px;
			font-size: 18px;
		}

		&:hover {
			border-color: $white;
			background-color: $purple2;
			color: $white;
		}
	}

	&--secondary {
		padding: 13px 40px 8px;
		border: 2px solid $white;
		background-color: transparent;
		@include font-fix();
		font-weight: 900;
		font-size: 24px;
		letter-spacing: .04em;
		color: $white;
		text-transform: uppercase;
		transition: border .4s, background .4s, color .4s;

		@media #{$mobile} {
			padding: 12px 20px 4px;
			font-size: 18px;
		}

		@media #{$mobile-small} {
			padding: 4px 10px;
		}

		&:hover {
			border-color: $white;
			background-color: $white;
			color: $orange;
		}
	}

	&--sm {
		min-width: 207px;
		padding: 11px 20px 7px;
		font-size: 17px;
		letter-spacing: .08em;

		@media #{$mobile-small}{
			min-width: 0;
		}
	}
}
