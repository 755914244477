/* ------------------------------------------------------------ *\
	Company Features
\* ------------------------------------------------------------ */

.company-features {
	.company-features__head {
		margin-bottom: 50px;
		color: $white;
		text-align: center;

		@media #{$mobile} {
			padding: 0 40px;
			margin-bottom: 26px;
		}
	}

	.company-features__head h1,
	.company-features__head h3 {
		font-weight: 900;
		line-height: 1.2;
		text-transform: uppercase;
	}

	.company-features__head h1 {
		margin-bottom: 9px;
		font-family: $font-coco-gothic-fat;

		@media #{$mobile} {
			margin-bottom: 10px;
			font-size: 37px;
		}
	}

	.company-features__head h3 {
		@include font-fix();
		letter-spacing: .1em;

		@media #{$mobile} {
			line-height: 1.8;
		}
	}
}
