/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider {
	&--products {
		position: relative;

		@media #{$mobile} {
			margin: 0 28px;
		}

		@media #{$mobile-small} {
			// margin: 0;
		}

		.slick-track {
			display: flex;
		}

		.slick-list {
			margin: 0 -10px;
		}

		.slick-slide {
			float: none;
			min-height: 0;
			height: auto;
			padding: 0 10px;

			> div,
			.slider__slide,
			.product__item {
				height: 100%;

				@media #{$mobile} {
					height: auto;
				}
			}
		}

		.slider__nav {
			position: absolute;
			top: 50%;
			left: -40px;
			right: -40px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 71px;
			pointer-events: none;
			transform: translateY(-50%);

			@media #{$tablet-portrait} {
				left: -34px;
				right: -34px;
			}
		}

		.slider__prev,
		.slider__next {
			color: $white;
			cursor: pointer;
			pointer-events: all;
			transition: color .4s;

			&:hover {
				color: $blue;
			}
		}
	}
}
